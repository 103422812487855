import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Formik } from "formik";
import { toast } from "../../../core/lib/toastAlert";
import Config from "../../../core/config";
import { makeRequest } from "../../../core/services/v1/request";
import { useContextData } from "../../../core/context/index";
import {  pageAllowCheck } from "../../../core/helper/common";
import './PaymentMethod.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Form } from 'react-bootstrap';
import NavbarOne from '../../../Pages/separate/NavbarOne';

const PaymentMethod = (props) => {
    const navigate = useNavigate();

    const { myProfile, setUserProfile } = useContextData();
    const [updatedStatus, setupdatedStatus] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [enableAddStatus, setenableAddStatus] = useState(false);
    const [bankpaymentsList, setbankpaymentsList] = useState([]);

    const validationSchema = yup.object({
        accountname: yup
            .string("Please enter the ifsc code")
            .required('Account holder name is required'),
        // accountType: yup
        // .string('Please enter the account type'),
        //   .required('Account type is required'),
        bankAccount: yup
            .number("Please enter the bank account number")
            .required("Account number is required"),
        // bankName: yup
        //   .string()
        //   .matches(/[A-Z]/, "Please enter alphabets only."),
        confirmbankAccount: yup
            .number("Please enter the confirm bank account number")
            .required("Confirm Account number is required"),
        ifscCode: yup
            .string("Please enter the ifsc code")
            .matches(/^[A-Za-z0-9]*$/, "Please enter alphanumeric only")
            .required("IFSC code is required"),
        bankName: yup
            .string("Please enter the Bank name")
            .required("Bank Name is required"),
    });
    useEffect(() => {
        getPayment();
    }, []);
    const initialAddbank = () => {
        return {
            accountname: "",
            accountType: "",
            bankAccount: "",
            confirmbankAccount: "",
            bankName: "",
            ifscCode: "",
        };
    };




    const initialValues = () => {
        return {
            accountname: myProfile?.bankdetails?.["Account Name"],
            // accountName: myProfile?.bankdetails?.['Beneficiary Name'],
            // accountType: myProfile?.bankdetails?.['Account Type'],
            bankAccount: myProfile?.bankdetails?.["Account Number"],
            confirmbankAccount: myProfile?.bankdetails?.["Confirm Account Number"],
            // bankName: myProfile?.bankdetails?.['Bank Name'],
            ifscCode: myProfile?.bankdetails?.["IFSC Code"],
        };
    };
    async function getPayment() {
        try {
            const params = {
                url: `${Config.V2_API_URL}customer/getBankPayments`,
                method: "GET",
            };
            const response = await makeRequest(params);
            if (response && response.status && response.data) {
                if (response.data.length > 0) {
                    let newEnableAddStatus = true;
                    response.data.map((data, i) => {
                        if (data.status == 0) {
                            newEnableAddStatus = false;
                        } else if (newEnableAddStatus !== false) {
                            newEnableAddStatus = true;
                        }
                    });
                    setenableAddStatus(newEnableAddStatus);
                    setbankpaymentsList(response.data);
                } else {
                    setenableAddStatus(true);
                }
            } else {
                setenableAddStatus(true);
            }
        } catch (err) { }
    }
    const onSubmit = async (values) => {
        setisLoading(true);
        setupdatedStatus(true);
        const data = {
            accountname: values.accountname,
            // accountType: values.accountType,
            accountNumber: values.bankAccount,
            confirmaccountNumber: values.confirmbankAccount,
            // bankName: values.bankName,
            ifscCode: values.ifscCode,
        };

        const bankDetail = [
            {
                name: 'Beneficiary Name',
                type: 'accountname'
            },
            {
                name: 'Confirm Account Number',
                type: 'confirmaccountNumber'
            },
            {
                name: "Account Number",
                type: "accountNumber",
            },
            {
                name: "IFSC Code",
                type: "ifscCode",
            },
            // {
            //   name: 'Account Type',
            //   type: 'accountType'
            // }
        ];
        let bankdetailss = typeof data == "object" ? data : {};

        let objValue = {};
        bankDetail.forEach((detail) => {
            if (
                typeof bankdetailss[detail.type] == "string" ||
                typeof bankdetailss[detail.type] == "number"
            ) {
                objValue[detail.name] = bankdetailss[detail.type];
            }
        });

        const params = {
            url: `${Config.V1_API_URL}user/updateMyBank`,
            method: "POST",
            body: { bankdetails: objValue },
        };
        
        const response = await makeRequest(params);
        setisLoading(false);
        let type = "error";
        if (response.status) {
            type = "success";
            setUserProfile();
            getPayment();
            setupdatedStatus(false);
            // setaddbankformOpen(false);
            navigate("/my/payment");
            // navigate = "/my/payment";
        }
        toast({ type, message: response.message });
        // navigate = "/my/payment";
    };

    async function enableDisableStatus(data) {
        try {
            const params = {
                url: `${Config.V1_API_URL}p2p/enableDisablP2PPayment`,
                method: "POST",
                body: data,
            };
            const result = await makeRequest(params);
            let type = "error";
            if (result.status) {
                type = "success";
                setTimeout(() => {
                    getPayment();
                    setUserProfile();
                }, 300);
            }
            toast({ type, message: result.message });
        } catch (err) {
            console.log("err:", err);
        }
    }

    async function selectpaymentType(methods) {
        if (methods.name == 'UPI') {
            toast({ type: "error", message: "This facility is currently un-available." });
            return false;
        }
        if (myProfile) {
            if (methods.name == 'Bank') {
                const kycUserType = myProfile.country == "IND" ? myProfile.country : "International";
                if (kycUserType == "International") {
                    toast({ type: "error", message: "This facility is currently unavailable for your country." });
                    return false;
                }
            }
            const pageAllowCheckResp = pageAllowCheck(myProfile, "final");
            if (pageAllowCheckResp.type == "error") {
                toast({ type: pageAllowCheckResp.type, message: pageAllowCheckResp.message });
                navigate(pageAllowCheckResp.navigate);
            }
            else {
                // setaddbankformOpen(true);
                navigate("/my/add-payment-method");
            }
        }
    }
    return (
        <section className='bg-default min-vh-100 text-white bg-particular'>
            <NavbarOne setTheme={props.setTheme} theme={props.theme} />
            <div className='container pb-5 pt-5'>
                <div>
                    <h4><strong><ArrowBackIcon onClick={() => navigate(-1)} style={{ cursor: "pointer" }} /> Payment Method</strong></h4>
                    <p className='pt-2' style={{ color: '#C8C8C8' }}>When you withdraw your fiat, the added payment method will be shown to the buyer during the transaction. To accept cash transfer, please make sure the information is correct</p>
                </div>
                <div className='mx-auto mt-4'>
                    <div className='d-flex justify-content-center align-items-center flex-column flex-lg-row mt-5 pt-4'>
                        <div className='w-100'>
                            <img src="/assets/payment.png" alt="" className='img-fluid' />
                        </div>
                        <div className='w-100'>
                            <Formik
                                initialValues={initialAddbank()}
                                validationSchema={validationSchema}
                                onSubmit={(values) => {
                                    onSubmit(values);
                                }}
                            >
                                {(formikProps) => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                    } = formikProps;
                                    return (
                                        <>
                                            <Form className='widthIssue  ms-auto'>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='payment-title'>Account Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter your Account Name" className='payment-form' value={values.accountname}
                                                        id="accountname"
                                                        name="accountname"
                                                        autoComplete="off"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={touched.accountname && Boolean(errors.accountname)}
                                                        helperText={touched.accountname && errors.accountname} />
                                                </Form.Group>
                                                {errors.accountname ? (
                                                    <small className="invalid-Account name error">
                                                        {errors.accountname}
                                                    </small>
                                                ) : null}
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='payment-title'>Account Number</Form.Label>
                                                    <Form.Control type="number" placeholder="Enter your Account number" className='payment-form' value={values.bankAccount}
                                                        id="bankAccount"
                                                        name="bankAccount"
                                                        autoComplete="off"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={touched.bankAccount && Boolean(errors.bankAccount)}
                                                        helperText={touched.bankAccount && errors.bankAccount} />
                                                </Form.Group>
                                                {errors.bankAccount ? (
                                                    <small className="invalid-bankAccount error">
                                                        {errors.bankAccount}
                                                    </small>
                                                ) : null}
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='payment-title'>Confirm Account Number</Form.Label>
                                                    <Form.Control type="number" placeholder="Confirm Account Number" className='payment-form' value={values.confirmbankAccount}
                                                        id="confirmbankAccount"
                                                        name="confirmbankAccount"
                                                        autoComplete="off"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={touched.confirmbankAccount && Boolean(errors.confirmbankAccount)}
                                                        helperText={touched.bankAccount && errors.confirmbankAccount} />
                                                </Form.Group>
                                                {errors.confirmbankAccount ? (
                                                    <small className="invalid-confirmbankAccount error">
                                                        {errors.confirmbankAccount}
                                                    </small>
                                                ) : null}
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='payment-title'>IFSC Code</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter your IFSC Code" className='payment-form' value={values.ifscCode}
                                                        id="ifscCode"
                                                        name="ifscCode"
                                                        autoComplete="off"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={touched.ifscCode && Boolean(errors.ifscCode)}
                                                        helperText={touched.ifscCode && errors.ifscCode} />
                                                </Form.Group>
                                                {errors.ifscCode ? (
                                                    <small className="invalid-ifscCode error">
                                                        {errors.ifscCode}
                                                    </small>
                                                ) : null}
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='payment-title'>Bank Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter your Bank Name" className='payment-form' value={values.bankName}
                                                        id="bankName"
                                                        name='bankName'
                                                        autoComplete='off'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={touched.bankName && Boolean(errors.bankName)}
                                                        helperText={touched.bankName && errors.bankName} />
                                                </Form.Group>
                                                {errors.bankName ? <small className="invalid-bankName error">{errors.bankName}</small> : null}
                                                <div className='text-center pt-2'>
                                                    <button className='btn-profile shadow px-4' style={{ width: 'auto' }} disabled={isLoading}
                                                        onClick={() => handleSubmit(values)}>Submit</button>
                                                </div>
                                            </Form>
                                        </>
                                    );
                                }}
                            </Formik>
                        </div >
                    </div>
                </div>
            </div>
        </ section>
    );
};

export default PaymentMethod;