import React, { useEffect, useState } from 'react';

const FuturesBelowInfo = ({ tradePreliminary, quantity, priceStatic, pairDetails, tabMenu, trades, getBaseAndTargetCurrency, xMenu }) => {
    const [mxLng, setMxLng] = useState(0.00);
    const [mxShrt, setMxShrt] = useState(0.00);

    const setValues = (isLongPos, minSize, rootCal, longShort) => {
        const amount = isLongPos * minSize
        if (quantity > 0) {
            setMxLng(() => (+rootCal))
            setMxShrt(() => (+rootCal))

            if (longShort !== 'Long') {
                setMxLng(() => (+rootCal) + 0)
            }
            if (longShort === 'Short') {
                setMxLng(() => (+rootCal) + amount)
            }
            if (longShort !== 'Short') {
                setMxShrt(() => (+rootCal) + 0)
            }
            if (longShort === 'Long') {
                setMxShrt(() => (+rootCal) + amount)
            }
        }
    }
    useEffect(() => {
        if (tabMenu === 'Market') {
            const rootCal = (+quantity) / (+pairDetails.data?.lastTradePrice)
            let isLongPos = trades?.positions?.length > 0 ? (trades?.positions)?.find(data => data?.symbol === pairDetails?.data?.symbol)?.currentQty : 0
            const longShort = isLongPos > 0 ? 'Long' : 'Short'
            const minSize = pairDetails?.data?.multiplier * pairDetails?.data?.lotSize
            if (isLongPos < 0) {
                isLongPos *= -1
            }
            setValues(isLongPos, minSize, rootCal, longShort)

        }
        else if (tabMenu === 'Limit') {
            const rootCal = (+quantity) / (+priceStatic)
            let isLongPos = trades?.positions?.length > 0 ? (trades?.positions)?.find(data => data?.symbol === pairDetails?.data?.symbol)?.currentQty : 0
            const longShort = isLongPos > 0 ? 'Long' : 'Short'
            const minSize = pairDetails?.data?.multiplier * pairDetails?.data?.lotSize
            if (isLongPos < 0) {
                isLongPos *= -1
            }
            setValues(isLongPos, minSize, rootCal, longShort)

        }

    }, [pairDetails.data?.lastTradePrice, pairDetails.data?.lotSize, pairDetails.data?.multiplier, pairDetails.data?.symbol, quantity, tabMenu, trades?.positions, priceStatic])

    return (
        <div className='d-flex justify-content-between align-items-center overflow-hidden'>
            <div>
                <button onClick={() => tradePreliminary('buy', 'long')}>LONG</button>
                <div className='mt-2 pt-1 handleCostMaxText w-100'>
                    <p className='mb-1'>COST {(((+quantity) / (+xMenu)) + (0.06 / 100) || 0).toFixed(2)} USDT</p>
                    <p>MAX {(+mxLng || 0)?.toFixed(2)} USDT</p>
                </div>
            </div>
            <div>
                <button onClick={() => tradePreliminary('sell', 'short')}>SHORT</button>
                <div className='mt-2 pt-1 handleCostMaxText w-100 text-end'>
                    <p className='mb-1'>COST {(((+quantity) / (+xMenu)) + (0.06 / 100) || 0).toFixed(2)} USDT</p>
                    <p>MAX {(+mxShrt || 0)?.toFixed(2)} USDT</p>
                </div>
            </div>
        </div>
    );
};

export default FuturesBelowInfo;