import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'

const ImageCarousel = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length)
    }, 1500)

    return () => clearInterval(interval)
  }, [images])

  return (
    <Box
      sx={{
        overflow: 'hidden',
        backgroundImage: 'url("/assets/banner/bg.png")',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: { xs: '100%', md: '50%' },
        position: 'relative',
        height: '90vh',
        marginTop: {
          xs: 5,
          md: 0,
        },
      }}
      className='image-carousel '
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <img
          data-aos='fade-left'
          data-aos-duration='1500'
          className='img-fluid'
          src={images[currentImageIndex]}
          alt='carousel'
        />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {/* <Typography
          sx={{
            color: 'white',
            fontWeight: 'bold',
            fontSize: { xs: 20, md: 25 },
            mb: 2,
            marginLeft: { xs: 0, md: -3 },
          }}
        >
          FUTURES TRADING
        </Typography> */}
        <h2>FUTURES TRADING</h2>
        <img
          data-aos='fade-up'
          data-aos-duration='1500'
          height={450}
          src='/assets/banner/phone.png'
          alt=''
        />
      </Box>
    </Box>
  )
}

export default ImageCarousel
