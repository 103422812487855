import React, { useEffect, useState } from "react";
import './searchbar.css';
import SearchIcon from '@mui/icons-material/Search';
import { AiOutlineSearch } from 'react-icons/ai';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { getCookie, setCookie } from "../../core/helper/cookie";
import { toast } from "../../core/lib/toastAlert";
import { showPairName } from '../../core/helper/date-format';
import '../../assets/style.css';
import { makeRequest } from "../../core/services/v1/request";
import Config from "../../core/config";
import socketIOClient from "socket.io-client";

const SearchBar = (props) => {
    const currentUrl = window.location.href;
    const dynamicClass = (currentUrl === "http://localhost:3000/" || currentUrl === "https://uniticexchange.com/") ? "Home" : ""
    const [isActive, setActive] = useState(false);
    const ToggleClass = () => {
        setActive(!isActive);
    };
    const [markets, setMarkets] = useState({});
    const [topMarkets, settopMarkets] = useState({});
    const [socketConnection, setSocketConnection] = useState(null);
    const [foc, setFoc] = useState(false);

    useEffect(() => {
        let socket = socketIOClient(Config.SOCKET_URL, {
            // transports: ["polling"],
            transports: ['websocket'],
            // rejectUnauthorized: false
        });
        let socketUnsubscribe;
        getMarkets();
        getTopMarkets();
        if (socket) {
            socket.on('connect', function () {
                setSocketConnection(socket);
                socketUnsubscribe = socket;
            });
            socket.on('connect_error', (err) => {
                console.log('socket connect_error', err)
            })
            socket.on('disconnect', function () {
                // console.log('socket disconnected')
            });
        }
        return () => {
            if (socketUnsubscribe) {
                socketUnsubscribe.disconnect();
            }
        };
    }, []);

    useEffect(() => {
        if (socketConnection != null) {
            socketConnection.on("pairResponse", data => {
                // getMarkets();
                markets.length > 0 && markets.map((market) => {
                    if (data.pair == market.pair) {
                        let newPrice = data.price.toFixed(data.decimalValue);
                        let oldData = markets.filter((market) => market.pair == data.pair)[0];
                        let oldIndex = markets.findIndex((market) => market.pair == data.pair);
                        let oldPrice = oldData.price.toFixed(oldData.decimalValue);
                        if (newPrice != oldPrice) {
                            markets[oldIndex].price = data.price;
                            markets[oldIndex].lastPrice = data.lastPrice;
                            markets[oldIndex].change = data.change;
                            markets[oldIndex].oldPrice = oldData.price;
                        }
                    }
                })
            });
            setMarkets(markets);
        }
    }, [socketConnection]);

    useEffect(() => {
        if (socketConnection != null) {
            getTopMarkets();
            socketConnection.on("pairResponse", data => {
                topMarkets.length > 0 && topMarkets.map((topmarket) => {
                    if (data.pair == topmarket.pair) {
                        let newPrice = data.price.toFixed(data.decimalValue);
                        let oldData = topMarkets.filter((topmarket) => topmarket.pair == data.pair)[0];
                        let oldIndex = topMarkets.findIndex((topmarket) => topmarket.pair == data.pair);
                        let oldPrice = oldData.price.toFixed(oldData.decimalValue);
                        if (newPrice != oldPrice) {
                            topMarkets[oldIndex].price = data.price;
                            topMarkets[oldIndex].lastPrice = data.lastPrice;
                            topMarkets[oldIndex].change = data.change;
                            topMarkets[oldIndex].oldPrice = oldData.price;
                        }
                    }
                })
                settopMarkets(topMarkets);
            })
        }
    }, [socketConnection])

    async function getMarkets() {
        try {
            const params = {
                url: `${Config.V1_API_URL}trade/getMarkets`,
                method: 'GET',
            }
            const response = (await makeRequest(params));
            if (response.status) {
                const filterData = (response?.data).filter(data => (data?.pair).includes(searchVal));
                // console.log('markets response : ', filterData);
                setMarkets(() => response.data);
                // markets = response.data;;
                // if(socketConnection != null) {
                //   socketConnection && socketConnection.on("pairResponse", data => {
                //     markets.map((market)=>{
                //       if (data.pair == market.pair){
                //         let newPrice = data.price.toFixed(data.decimalValue);
                //         let oldData = markets.filter((market) => market.pair == data.pair)[0];
                //         let oldIndex = markets.findIndex((market) => market.pair == data.pair);
                //         let oldPrice = oldData.price.toFixed(oldData.decimalValue);
                //         if(newPrice != oldPrice) {
                //           markets[oldIndex].price = data.price;
                //           markets[oldIndex].lastPrice = data.lastPrice;
                //           markets[oldIndex].change = data.change;
                //           markets[oldIndex].oldPrice = oldData.price;
                //         }
                //       }
                //     })
                //     setMarkets(markets);
                //   });
                // }
            }
        } catch (err) { }
    }

    async function getTopMarkets() {
        try {
            const params = {
                url: `${Config.V1_API_URL}trade/getHomeMarkets`,
                method: 'GET'
            }
            const response = (await makeRequest(params));
            if (response.status) {
                const topGainers = response.data.topGainers;
                topMarkets = topGainers;
                socketConnection.on("pairResponse", data => {
                    topGainers.map((market) => {
                        if (data.pair == market.pair) {
                            let newPrice = data.price.toFixed(data.decimalValue);
                            let oldData = topMarkets.filter((market) => market.pair == data.pair)[0];
                            let oldIndex = topMarkets.findIndex((market) => market.pair == data.pair);
                            let oldPrice = oldData.price.toFixed(oldData.decimalValue);
                            if (newPrice != oldPrice) {
                                topMarkets[oldIndex].price = data.price;
                                topMarkets[oldIndex].lastPrice = data.lastPrice;
                                topMarkets[oldIndex].change = data.change;
                                topMarkets[oldIndex].oldPrice = oldData.price;
                            }
                        }
                    })
                    settopMarkets(topMarkets);
                })
            }
        } catch (err) { }
    }

    const navigate = useNavigate();
    // const [toCurrencySymbolFinal, settoCurrencySymbolFinal] = useState("");
    const [searchVal, setsearchVal] = useState("UTI");
    const handleNavigation = (pair) => {
        navigate(`/spot/${pair}`);
    };
    return (
        <div style={{ position: 'relative' }}>
            <div className={`d-flex justify-content-start align-items-center ${(currentUrl === "http://localhost:3000/" || currentUrl === "https://uniticexchange.com/")? 'borderPart': ''}`}>
                <button className={`searchBtn${dynamicClass}`}><SearchIcon /></button>
                <input type="text" className={`${foc ? `text-default${dynamicClass}` : `text-gray${dynamicClass}`} searchGlass${dynamicClass}`} placeholder="UTI" onFocus={() => { setFoc(() => true); }} onBlur={() => {
                    setTimeout(() => {
                        setFoc(() => false);
                    }, 200);
                }} value={searchVal} onChange={(e) => {
                    // console.log("val", e.target.value);
                    setsearchVal(e.target.value);
                }} />
            </div>
            <div className={`searchDropdown${dynamicClass} ${foc ? `onFocMode${dynamicClass}` : `onBlurMode${dynamicClass}`}`}>
                <div className="row">
                    {
                        markets && markets.length > 0 && markets.map((market, i) => {
                            const searchValue = (searchVal && searchVal != "") ? searchVal.toUpperCase() : "";
                            if (market && market.pair) {
                                const pairNameShow = showPairName(market.pair);
                                if ((searchValue !== "" && pairNameShow.indexOf(searchValue) > -1) || searchValue === "") {
                                    return (
                                        <div className={`col-12 hoverMode${dynamicClass}`} key={i} onClick={(e) => { e.preventDefault(); handleNavigation(market.pair); }}>
                                            <p className="curPointer" >
                                                {pairNameShow}{" "}
                                            </p>
                                        </div>
                                    )
                                }
                            }
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default SearchBar;