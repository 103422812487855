import React from 'react'
import Box from '@mui/material/Box'
import UTIStrengthen from './UTIStrengthen'
import FeaturesOfUnitic from './FeaturesOfUnitic'
import FuturesTrading from './FuturesTrading'
import FindNextTrading from './FindNextTrading '
import FindYourNextTrading from './FindYourNextTrading '
import Banner from './Banner'
import 'aos/dist/aos.css'
import AOS from 'aos'
import { Helmet } from 'react-helmet-async';

function HomeV3() {
  AOS.init()

  return (
      <Box component='main'>
          <Helmet>
          <link rel="canonical" href="https://uniticexchange.com/" />
          <link rel="canonical" href="https://www.uniticexchange.com/" />
          <meta name="description" content='The India s First exchange providing spot and future signals' />  
          <meta name="description" content='Buy USDT'  />  
          <meta name="description" content='BUY OTC USDT Withdrawal' />
          <meta name="description" content='USDT Withdrawal, OTC USDT' />
          <meta name="description" content='btc price' />
          <meta name="description" content='ethereum price' />
          <meta name="description" content='market capitalization in cryptocurrency' />
          <meta name="description" content='Future Trading' />
          <meta name="description" content='btc price prediction' />
          <meta name="description" content='FIU Registered Crypto Exchange' />
          <meta name="description" content='Indian best crypto exchange' />
          <meta name="description" content='Indian crypto exchange' />
          <meta name="description" content='Best crypto exchange in India for beginners' />
          <meta name="description" content='Top Indian cryptocurrency exchanges' />
      </Helmet>
        <Box>
          <Banner/>
          <Box className='container'>
            <FindYourNextTrading />
            <FindNextTrading />
            <FuturesTrading />
            <FeaturesOfUnitic />
            <UTIStrengthen />
            {/* <Signals /> */}
          </Box>
        </Box>
      </Box>
  )
}

export default HomeV3;