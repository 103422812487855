import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddIcon from '@mui/icons-material/Add';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        color: '#FFF',
        fontFamily: "Open Sans",
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        borderTop: '1px solid rgba(200, 200, 200, 0.32)',
        borderBottom: '1px solid rgba(200, 200, 200, 0.32)'
    },
    '& .MuiDialogTitle-root': {
        color: '#FFF',
        fontFamily: "Open Sans",
        fontSize: '0.975rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialog-paper': {
        borderRadius: '8px',
        background: '#2E2C2C',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        color: '#FFF',
        fontFamily: "Open Sans",
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    }
}));

export default function AddTpSl({ addTpSl, modifyTpSl, type, elem, cancelOrder, currentOrderV2, currentOrder, getBaseAndTargetCurrency
}) {
    const { symbol = '',
        realLeverage = 0,
        currentQty = 0,
        currentCost = 0,
        avgEntryPrice = 0,
        markPrice = 0,
        liquidationPrice = 0,
        posInit = 0,
        unrealisedPnl = 0,
        realisedPnl = 0,
        amount = 0
    } = elem
    const [open, setOpen] = React.useState(false);
    const [tpPx, setTpPx,] = React.useState();
    const [slPx, setSlPx] = React.useState();
    const [getCurr, setGetCurr] = React.useState(null);
    const [refetch, setRefetch] = React.useState(false);
    const [effected, setEffected] = React.useState({ isTp: false, isSl: false, isTpIn: false, isSlIn: false })
    const [values, setValues] = React.useState({
        tpPnl: 0.00,
        tpRoi: 0.00,
        slPnl: 0.00,
        slRoi: 0.00
    })
    React.useEffect(() => {
        if (currentOrderV2?.price) {
            setEffected((data) => { return { ...data, isTp: true } })
            setTpPx(() => parseFloat(currentOrderV2?.price))
        }
        if (currentOrder?.stopPrice) {
            setEffected((data) => { return { ...data, isSl: true } })
            setSlPx(() => parseFloat(currentOrder?.stopPrice))
        }

    }, [refetch, currentOrder, currentOrderV2])
    React.useEffect(() => {
        if (symbol) {
            const getCurr = getBaseAndTargetCurrency(symbol)
            setGetCurr(() => getCurr)
        }
    }, [symbol])

    const handleClickOpen = () => {
        setOpen(true);
        setRefetch(!refetch)
    };
    const handleClose = () => {
        setOpen(false);
    };
    const finalModifyTpSl = async () => {

        if (slPx && !effected?.isSl) {
            let modifiedQty = currentQty
            if (modifiedQty < 0) {
                modifiedQty *= -1
            }
            let tradeParams = {
                side: currentQty > 0 ? 'sell' : 'buy', //buy sell
                symbol: symbol,
                stop: currentQty > 0 ? 'down' : 'up', //if short then pass up
                closeOrder: true,
                stopPriceType: 'MP',
                stopPrice: slPx.toString(),
                price: slPx.toString(),
            }
            // type: "market",
            // size: modifiedQty,
            // price: tpPx ? tpPx.toString() : slPx.toString(),
            // leverage: realLeverage, 
            await addTpSl(tradeParams);
        }
        if (tpPx && !effected?.isTp) {
            let modifiedQty = currentQty
            if (modifiedQty < 0) {
                modifiedQty *= -1
            }
            let tradeParams = {
                side: currentQty > 0 ? 'sell' : 'buy', //buy sell
                symbol: symbol,
                type: "limit",
                size: modifiedQty,
                price: tpPx.toString(),
                leverage: realLeverage,
            }

            await addTpSl(tradeParams);
        }
        handleClose();
    }
    const cancelOrderAndRefetch = async (id, type) => {
        const cancel = await cancelOrder(id)
        // setRefetch(!refetch)
        if (cancel && type === 'TP') {
            setEffected((data) => { return { ...data, isTp: false, } })
            setTpPx(() => parseFloat(0))
        }
        else if (cancel && type === 'SL') {
            setEffected((data) => { return { ...data, isSl: false } })
            setSlPx(() => parseFloat(0))
        }
    }
    const onChangeForTP = (e) => {
        let exit = parseFloat(e.target.value)
        setTpPx(() => exit)
        setEffected((data) => { return { ...data, isTpIn: false } })
        if (!exit) { return null }
        let pnl = 0;
        let roi = 0;

        const getPair = getCurr?.pair
        const minSize = getPair?.multiplier * getPair?.lotSize
        const amount = currentQty * minSize

        let imr = 1 / realLeverage;
        let margin = amount * avgEntryPrice * imr
        if (currentQty > 0) {
            if (exit < avgEntryPrice) {
                setEffected((data) => { return { ...data, isTpIn: true } })
                return setValues(el => { return { ...el, tpPnl: 0.00, tpRoi: 0.00 } })
            }
            else {
                setEffected((data) => { return { ...data, isTpIn: false } })
            }

            pnl = (exit - avgEntryPrice) * amount
            setValues(el => { return { ...el, tpPnl: +pnl?.toFixed(2) } })

            roi = ((pnl / margin) * 1) * 100
            setValues(el => { return { ...el, tpRoi: +roi?.toFixed(2) } })
        }
        else {
            if (exit > avgEntryPrice) {
                setEffected((data) => { return { ...data, isTpIn: true } })
                return setValues(el => { return { ...el, tpPnl: 0.00, tpRoi: 0.00 } })
            }
            else {
                setEffected((data) => { return { ...data, isTpIn: false } })
            }

            pnl = (exit - avgEntryPrice) * amount
            setValues(el => { return { ...el, tpPnl: +pnl?.toFixed(2) } })

            roi = ((pnl / margin) * -1) * 100
            setValues(el => { return { ...el, tpRoi: +roi?.toFixed(2) } })
        }
    }
    const onChangeForSL = (e) => {
        let exit = parseFloat(e.target.value)
        setSlPx(() => exit)
        setEffected((data) => { return { ...data, isSlIn: false } })
        if (!exit) { return null }
        let pnl = 0;
        let roi = 0;
        let limit = -100

        const getPair = getCurr?.pair
        const minSize = getPair?.multiplier * getPair?.lotSize
        const amount = currentQty * minSize

        let imr = 1 / realLeverage;
        let margin = amount * avgEntryPrice * imr

        if (currentQty > 0) {
            if (exit > getCurr?.price) {
                setEffected((data) => { return { ...data, isSlIn: true } })
                return setValues(el => { return { ...el, slPnl: 0.00, slRoi: 0.00 } })
            }
            else {
                setEffected((data) => { return { ...data, isSlIn: false } })
            }

            pnl = (exit - avgEntryPrice) * amount
            setValues(el => { return { ...el, slPnl: +pnl?.toFixed(2) } })

            roi = ((pnl / margin) * 1) * 100
            if (roi < limit) {
                setEffected((data) => { return { ...data, isSlIn: true } })
            }
            setValues(el => { return { ...el, slRoi: +roi?.toFixed(2) } })
        }
        else {
            if (exit < getCurr?.price) {
                setEffected((data) => { return { ...data, isSlIn: true } })
                return setValues(el => { return { ...el, slPnl: 0.00, slRoi: 0.00 } })
            }
            else {
                setEffected((data) => { return { ...data, isSlIn: false } })
            }

            pnl = (exit - avgEntryPrice) * amount
            setValues(el => { return { ...el, slPnl: +pnl?.toFixed(2) } })

            roi = ((pnl / margin) * -1) * 100
            if (roi < limit) {
                setEffected((data) => { return { ...data, isSlIn: true } })
            }
            setValues(el => { return { ...el, slRoi: +roi?.toFixed(2) } })
        }
    }
    return (
        <React.Fragment>
            {type === "add" ? <span onClick={handleClickOpen} className="cursor-pointer" style={{ position: 'relative', left: '-2px' }}>
                <AddIcon sx={{ color: 'white', fontSize: '1.1rem', position: 'relative', top: '-2px' }} /> <span>Add</span>
            </span> : type === "modify" ? <IconButton aria-label="Example" sx={{ p: 0, pt: 0.095 }} onClick={handleClickOpen}>
                <EditNoteIcon sx={{ color: 'white' }} fontSize="small" />
            </IconButton> : null}

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    TP | SL
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <div>
                        <Form.Label htmlFor="basic-url" style={{
                            color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
                        }}>Take Profit: </Form.Label>
                        <InputGroup className="mb-2 selectOfTradeTpSl">
                            <Form.Control aria-label="Text input with text" placeholder='Trigger Price' type='number' className={`inputOfTrade ${effected?.isTpIn ? 'invalidValue' : ''}`} disabled={effected?.isTp} value={tpPx} onChange={(e) => onChangeForTP(e)} />
                            <Button variant="danger" id="button-addon2" disabled={!effected?.isTp} onClick={() => { cancelOrderAndRefetch(currentOrderV2?.id || currentOrderV2?.orderId, 'TP') }}>
                                Cancel
                            </Button>
                        </InputGroup>
                        <p style={{
                            color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
                        }} className='mb-2'>Est. PNL: <span className='futures-success'>&#36;{values?.tpPnl >= 0 ? '+' : null}{(values?.tpPnl).toFixed(2)}</span> <span className='px-2'>|</span> {values?.tpRoi >= 0 ? '+' : null}{(values?.tpRoi || 0.00).toFixed(2)}%</p>
                        <Form.Label htmlFor="basic-url" style={{
                            color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
                        }} >Stop Loss: </Form.Label>
                        <InputGroup className="selectOfTradeTpSl mb-2">
                            <Form.Control aria-label="Text input with text" placeholder='Trigger Price' type='number' className={`inputOfTrade ${effected?.isSlIn ? 'invalidValue' : ''}`} disabled={effected?.isSl} value={slPx} onChange={(e) => onChangeForSL(e)} />
                            <Button variant="danger" id="button-addon3" disabled={!effected?.isSl} onClick={() => { cancelOrderAndRefetch(currentOrder?.id || currentOrder?.orderId, 'SL') }}>
                                Cancel
                            </Button>
                        </InputGroup>
                        <p style={{
                            color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
                        }} className='mb-3'>Est. PNL: <span className='futures-danger'>&#36;{values?.slPnl >= 0 ? '+' : null}{(values?.slPnl || 0.00).toFixed(2)}</span> <span className='px-2'>|</span> {values?.slRoi >= 0 ? '+' : null}{(values?.slRoi || 0.00).toFixed(2)}%</p>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className='btn-cancel-v2' onClick={handleClose}>
                        Cancel
                    </button>
                    <button className='btn-confirm' disabled={(effected?.isTp && effected?.isSl) || (effected?.isTpIn || effected?.isSlIn)} onClick={() => { handleClose(); finalModifyTpSl() }}>
                        Confirm
                    </button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}