import React, { useEffect, useState } from "react";
// import { TfiReload } from 'react-icons/tfi';
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import { dateFormatOKX, dateFormatOKXMilliseconds } from '../../../core/helper/date-format';
import { Link, useNavigate } from "react-router-dom";
import '../../../pagination.css';
import '../../../assets/style.css';
import TpSlDetails from "./dialogs.js/TpSlDetails";
import AddTpSl from "./dialogs.js/AddTpSl";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditLeverage from "./dialogs.js/EditLeverage";
import AutoMarginSwitch from "./AutoMarginSwitch";
import AlertDialog from "./dialogs.js/CloseConfirmation";
import LoaderSpin from "../../../Component/Loader/LoaderSpin";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: 'transparent', color: 'rgb(243, 243, 243)', width: '100%'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  padding: "10px 4px"
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    background: 'transparent', color: 'rgb(243, 243, 243)'
  },
  background: 'transparent', color: 'rgb(243, 243, 243)',
  // hide last border
  'td,th': {
    border: 0,
  },
}));

export default function FuturesMyOrders(props) {
  const {
    myProfile = {},
    trades = {
      openOrders: [], stopOrders: [], orders: [], positions: [], pnl: [], fills: []
    },
    getBaseAndTargetCurrency,
    cancelOrder,
    addTpSl,
    closePosition,
    pair,
    pairsLoader
  } = props;
  const navigate = useNavigate();
  const [userHistoryTab, setUserHistoryTab] = useState("Positions");
  useEffect(() => {
    $(".status_change .dropdown-item").click(function () {
      var getStatusText = $(this).text();
      $(this).closest(".status_dropdown").find(".status__btn").text(getStatusText);
      var generateStatusClass = `${$(this).attr('data-class')}-status`
      $(this).closest(".status_dropdown").attr("data-color", `${generateStatusClass}`);
    })
  }, []);
  // const copyText = (data = {}) => {
  //   if (data.text) {
  //     var input = document.createElement("input");
  //     document.body.appendChild(input);
  //     input.value = data.text;
  //     input.select();
  //     document.execCommand("Copy");
  //     input.remove();
  //     toast({ type: "success", message: data.message });
  //   }
  // }
  return (
    <div>

      <div className='d-flex flex-row trading-page-order-details-section '>
        <div className='col-12'>
          <div className="d-flex justify-content-start paired-trade-table-section align-items-center py-3 py-sm-3 py-md-3 py-lg-0">
            <div className="">
              <ul className="nav nav-pills" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation" onClick={() => setUserHistoryTab("Positions")}>
                  <button
                    className="nav-link active"
                    id="pills-positions-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-positions"
                    type="button" role="tab"
                    aria-controls="pills-positions"
                    aria-selected="false"
                  >
                    Positions ({trades?.positions?.length})
                  </button>
                </li>
                <li className="nav-item" role="presentation" onClick={() => setUserHistoryTab("Open orders")}>
                  <button
                    className="nav-link"
                    id="pills-openorders-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-openorders"
                    type="button" role="tab"
                    aria-controls="pills-openorders"
                    aria-selected="false"
                  >
                    Open Order ({trades?.openOrders?.length})
                  </button>
                </li>

                <li className="nav-item" role="presentation" onClick={() => setUserHistoryTab("Stop order")}>
                  <button
                    className="nav-link"
                    id="pills-positionhistory-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-positionhistory"
                    type="button"
                    role="tab"
                    aria-controls="pills-positionhistory"
                    aria-selected="false"
                  >
                    Stop Order ({trades?.stopOrders?.length})
                  </button>
                </li>
                <li className="nav-item" role="presentation" onClick={() => setUserHistoryTab("Realized pnl")}>
                  <button
                    className="nav-link"
                    id="pills-positionhistory-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-positionhistory"
                    type="button"
                    role="tab"
                    aria-controls="pills-positionhistory"
                    aria-selected="false"
                  >
                    Realized PNL
                  </button>
                </li>
                <li className="nav-item" role="presentation" onClick={() => setUserHistoryTab("Fills")}>
                  <button
                    className="nav-link"
                    id="pills-positionhistory-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-positionhistory"
                    type="button"
                    role="tab"
                    aria-controls="pills-positionhistory"
                    aria-selected="false"
                  >
                    Fills
                  </button>
                </li>
                <li className="nav-item" role="presentation" onClick={() => setUserHistoryTab("Order history")}>
                  <button
                    className="nav-link"
                    id="pills-orderhistory-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-orderhistory"
                    type="button"
                    role="tab"
                    aria-controls="pills-orderhistory"
                    aria-selected="false"
                  >
                    Order History
                  </button>
                </li>
              </ul>
            </div>

          </div>

          {(!myProfile && !myProfile?._id)
            ?
            <div className='trading-page-order-tabs-section pt-5'>
              <span className='trade-bottom-login-text-1'>
                <span className='fc-g cursor-pointer' onClick={() => navigate('/login')}>Login </span>(or)
                <span className='fc-g cursor-pointer' onClick={() => navigate('/register')}> Register Now</span> to trade
              </span>
            </div>
            : pairsLoader ? <div className='trading-page-order-tabs-section pt-3'><LoaderSpin /></div> :
              <>
                <div className="trading-page-order-table-details-section" style={{ height: '30vh', borderRight: '1px solid rgb(238, 240, 242)' }}>
                  {userHistoryTab == "Open orders" &&
                    <TableContainer>
                      <Table sx={{ minWidth: window.innerWidth < 700 ? 900 : 850, justifyContent: 'center' }} aria-label="customized table" stickyHeader >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Contract</StyledTableCell>
                            <StyledTableCell>Type</StyledTableCell>
                            <StyledTableCell>Order Price</StyledTableCell>
                            <StyledTableCell>Amount</StyledTableCell>
                            <StyledTableCell>Filled</StyledTableCell>
                            <StyledTableCell>Order Value</StyledTableCell>
                            <StyledTableCell>Take Profit & Stop Loss</StyledTableCell>
                            <StyledTableCell>Time</StyledTableCell>
                            <StyledTableCell>Cancel All</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {trades?.openOrders?.length > 0 && trades?.openOrders.map((elem, i) => {
                            // if (elem.type !== 'limit' || elem.type !== 'market') return null;
                            const {
                              orderId = null,
                              id = null,
                              symbol = '',
                              type = '',
                              price = '0',
                              size = 0,
                              side = '',
                              filledValue = 0,
                              value = '0',
                              stopPrice = null,
                              createdAt = null,
                              orderTime = null,
                              settleCurrency = ''
                            } = elem
                            const clrClassName = side == 'buy' ? 'color-green-futures' : 'color-red-futures';
                            // <TpSlDetails tpTriggerPx={tpTriggerPx} slTriggerPx={slTriggerPx} tpTriggerPxType={tpTriggerPxType} tpOrdPx={tpOrdPx} slOrdPx={slOrdPx} slTriggerPxType={slTriggerPxType}
                            // />
                            const getCurr = getBaseAndTargetCurrency(symbol)
                            const getPair = getCurr?.pair
                            const minSize = getPair?.multiplier * getPair?.lotSize
                            const amount = size * minSize
                            return <StyledTableRow key={i}>
                              <StyledTableCell>{symbol ?
                                <Link to={"/futures/trade/" + symbol} className="text-white">{getBaseAndTargetCurrency(symbol)?.currency}</Link> : ""
                              }</StyledTableCell>
                              <StyledTableCell className={"capitalizeText " + (clrClassName)}>{`${side == 'buy' ? 'Long' : 'Short'}/${type}`}</StyledTableCell>
                              <StyledTableCell>{price}</StyledTableCell>
                              <StyledTableCell>{amount}</StyledTableCell>
                              <StyledTableCell>{filledValue}</StyledTableCell>
                              <StyledTableCell>{value} {settleCurrency === "XBT" ? "BTC" : settleCurrency}</StyledTableCell>
                              <StyledTableCell>{price}<br />{stopPrice}</StyledTableCell>
                              <StyledTableCell>{dateFormatOKX(createdAt || (orderTime / 1000000))}</StyledTableCell>
                              <StyledTableCell><div className="d-flex justify-content-start"><button type="button" className="market-trade-button px-1" onClick={async () => await cancelOrder(id || orderId)}>Cancel</button></div></StyledTableCell>
                            </StyledTableRow>
                          })}
                          {trades?.openOrders?.length == 0 &&
                            <StyledTableRow>
                              <StyledTableCell className='text-white'>No Active Orders Found!</StyledTableCell>
                            </StyledTableRow>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                  {userHistoryTab == "Positions" &&
                    <TableContainer>
                      <Table sx={{ minWidth: window.innerWidth < 700 ? 900 : 1000, justifyContent: 'center' }} aria-label="customized table" stickyHeader >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell onClick={() => window.open('https://utistaking.com/', '_blank', 'noopener,noreferrer')}>Contract</StyledTableCell>
                            <StyledTableCell>Amount</StyledTableCell>
                            <StyledTableCell>Value</StyledTableCell>
                            <StyledTableCell>Entry Price</StyledTableCell>
                            <StyledTableCell>Mark Price</StyledTableCell>
                            <StyledTableCell>Liquidation Price</StyledTableCell>
                            <StyledTableCell>Margin</StyledTableCell>
                            <StyledTableCell>PNL (ROE)</StyledTableCell>
                            {/* <StyledTableCell>Realized PNL</StyledTableCell> */}
                            <StyledTableCell>TP/SL</StyledTableCell>
                            {/* <StyledTableCell>Auto-Deposit Margin</StyledTableCell> */}
                            <StyledTableCell>Close</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {trades?.positions.length > 0 && trades?.positions.map((elem, i) => {

                            const {
                              symbol = '',
                              realLeverage = 0,
                              currentQty = 0,
                              currentCost = 0,
                              avgEntryPrice = 0,
                              markPrice = 0,
                              liquidationPrice = 0,
                              posInit = 0,
                              unrealisedPnl = 0,
                              realisedPnl = 0,
                              unrealisedPnlPcnt = 0,
                              unrealisedRoePcnt = 0
                            } = elem;

                            const clrClassName = currentQty >= 0 ? 'color-green-futures' : 'color-red-futures';
                            const getCurr = getBaseAndTargetCurrency(symbol)
                            const getPair = getCurr?.pair
                            const minSize = getPair?.multiplier * getPair?.lotSize
                            const amount = currentQty * minSize
                            const modifiedCurrentCost = currentCost < 0 ? currentCost * (-1) : currentCost
                            const currentOrder = trades?.stopOrders?.length > 0 ? (trades?.stopOrders).find(data => data?.symbol === symbol) : null
                            const currentOrderV2 = trades?.openOrders?.length > 0 ? (trades?.openOrders).find(data => data?.symbol === symbol) : null
                            return <StyledTableRow key={i}>
                              <StyledTableCell data-label="Symbol">{symbol ?
                                <Link to={"/futures/trade/" + symbol} className="text-white">{getCurr?.currency}</Link> : ""
                              }
                                <br />
                                <span className="d-flex justify-content-start align-items-center" style={{ gap: '5px' }}>
                                  <span>{realLeverage}x

                                  </span>
                                  <span className={clrClassName} style={{ fontSize: 'small' }}>{currentQty > 0 ? 'Long' : 'Short'} {/* <EditLeverage elem={elem} getBaseAndTargetCurrency={getBaseAndTargetCurrency}/> */}</span>
                                </span>
                              </StyledTableCell>
                              <StyledTableCell className={clrClassName}>{amount} {getCurr?.baseCurrency}</StyledTableCell>
                              <StyledTableCell className="capitalizeText">{modifiedCurrentCost} {getCurr?.quoteCurrency}</StyledTableCell>
                              <StyledTableCell>{avgEntryPrice}</StyledTableCell>
                              <StyledTableCell>{Number(markPrice)?.toFixed(2)}</StyledTableCell>
                              <StyledTableCell>{Number(liquidationPrice).toFixed(2)}</StyledTableCell>
                              <StyledTableCell>{Number(posInit)?.toFixed(2)}</StyledTableCell>
                              <StyledTableCell ><span className={`${unrealisedPnl >= 0 ? 'color-green-futures' : 'color-red-futures'}`}>{Number(unrealisedPnl)?.toFixed(2)} {getCurr?.quoteCurrency}</span> <span className={`${unrealisedRoePcnt * 100 >= 0 ? 'color-green-futures' : 'color-red-futures'}`}>({(Number(unrealisedRoePcnt) * 100).toFixed(2)}%)</span></StyledTableCell>
                              {/* <StyledTableCell className={`${realisedPnl >= 0 ? 'color-green-futures' : 'color-red-futures'}`}>{Number(realisedPnl)?.toFixed(2)}</StyledTableCell> */}
                              <StyledTableCell data-label="TP | SL" className="d-flex justify-content-start align-items-center" style={{ gap: (currentOrderV2?.price && currentOrder?.stopPrice) ? '5px' : '0px' }}><span>
                                {currentOrderV2?.price ? <><span className={'color-green-futures'}>{currentOrderV2?.price}</span><br /></> : null}{currentOrder?.stopPrice ? <span className={'color-red-futures'}>{currentOrder?.stopPrice}</span> : null}</span> <AddTpSl elem={elem} getBaseAndTargetCurrency={getBaseAndTargetCurrency} addTpSl={addTpSl} type={(currentOrder?.price || currentOrderV2?.price) ? "modify" : "add"} cancelOrder={cancelOrder} currentOrder={currentOrder} currentOrderV2={currentOrderV2} trades={trades} /></StyledTableCell>
                              {/* <StyledTableCell ><AutoMarginSwitch /></StyledTableCell> */}
                              <StyledTableCell className='text-white'><AlertDialog closePosition={closePosition} elem={elem} getCurr={getCurr} size={amount} /></StyledTableCell>
                            </StyledTableRow>
                          })}
                          {trades?.positions.length == 0 &&
                            <StyledTableRow>
                              <StyledTableCell className='text-white'>No Active Position Found!</StyledTableCell>

                            </StyledTableRow>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                  {userHistoryTab == "Order history" &&
                    <TableContainer>
                      <Table sx={{ minWidth: window.innerWidth < 700 ? 900 : 1000, justifyContent: 'center' }} aria-label="customized table" stickyHeader size='small'>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Contract</StyledTableCell>
                            <StyledTableCell>Amount</StyledTableCell>
                            <StyledTableCell>Order Price</StyledTableCell>
                            <StyledTableCell>Filled</StyledTableCell>
                            <StyledTableCell>Unfilled</StyledTableCell>
                            <StyledTableCell>Stop Price</StyledTableCell>
                            <StyledTableCell>Fill Price</StyledTableCell>
                            <StyledTableCell>Type</StyledTableCell>
                            <StyledTableCell>Time</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            {/* <StyledTableCell>Action</StyledTableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {trades?.orders?.length > 0 && trades?.orders.map((elem, i) => {
                            if (elem?.status === 'active' || elem?.status === 'open') { return null }

                            const {
                              side = '',
                              symbol = '',
                              endAt = null,
                              createdAt = null,
                              size = 0,
                              settleCurrency = '',
                              price = '',
                              filledValue = 0,
                              stopPrice = null,
                              type = '',
                              status = '',
                              dealValue = '0',
                              dealSize = 0,
                              ts = ''
                            } = elem;

                            const clrClassName = size >= 0 ? 'color-green-futures' : 'color-red-futures';
                            const getCurr = getBaseAndTargetCurrency(symbol)
                            const multi = (+getCurr?.pair?.multiplier) || 0
                            const fillPrice = (+dealValue) / (+dealSize) / multi;
                            return <StyledTableRow key={i}>
                              <StyledTableCell data-label="Symbol">{symbol ?
                                <Link to={"/futures/trade/" + symbol} className={clrClassName}>{getCurr?.currency}</Link> : ""
                              }
                              </StyledTableCell>

                              <StyledTableCell className={clrClassName}>{size} {settleCurrency === "XBT" ? "BTC" : settleCurrency}</StyledTableCell>
                              <StyledTableCell data-label="Fill | Order price" className="capitalizeText ">{price}</StyledTableCell>
                              <StyledTableCell data-label="Filled | Total">{filledValue} {settleCurrency === "XBT" ? "BTC" : settleCurrency}</StyledTableCell>
                              <StyledTableCell data-label="UnFilled">{size - filledValue} {settleCurrency === "XBT" ? "BTC" : settleCurrency}</StyledTableCell>
                              <StyledTableCell data-label="Stop">{stopPrice || 0}</StyledTableCell>
                              <StyledTableCell data-label="Fill Price">{fillPrice || 0}</StyledTableCell>
                              <StyledTableCell data-label="Type" className="capitalizeText">{type}</StyledTableCell>
                              <StyledTableCell data-label="Time">{dateFormatOKX(endAt || ts)}</StyledTableCell>
                              <StyledTableCell data-label="Status">{status}</StyledTableCell>
                              {/* <StyledTableCell data-label="Action" className="cursor-pointer" onClick={()=> handleClickOpenOrderDetails()}>CLick</StyledTableCell> */}
                            </StyledTableRow>
                          })}
                          {trades?.orders?.length == 0 &&
                            <StyledTableRow>
                              <StyledTableCell className='text-white'>No History Found!</StyledTableCell>
                            </StyledTableRow>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                  {userHistoryTab == "Stop order" &&
                    <TableContainer>
                      <Table sx={{ minWidth: window.innerWidth < 700 ? 900 : 1000, justifyContent: 'center' }} aria-label="customized table" stickyHeader size='small'>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Contract</StyledTableCell>
                            <StyledTableCell>Type</StyledTableCell>
                            <StyledTableCell>Stop Price</StyledTableCell>
                            <StyledTableCell>Order Price</StyledTableCell>
                            <StyledTableCell>Amount</StyledTableCell>
                            <StyledTableCell>Order value</StyledTableCell>
                            <StyledTableCell>Time</StyledTableCell>
                            <StyledTableCell>Cancel All</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {trades?.stopOrders?.length > 0 && trades?.stopOrders.map((elem, i) => {
                            // if (elem.type !== 'limit_stop' || elem.type !== 'market_stop') return null;
                            const { orderId = null, id = null, symbol = '', type = '', price = '0', size = 0, side = '', filledValue = 0, value = '0', stopPrice = '0', createdAt = null,
                              orderTime = null, settleCurrency = ''
                            } = elem;
                            const getCurr = getBaseAndTargetCurrency(symbol)
                            const getPair = getCurr?.pair
                            const minSize = getPair?.multiplier * getPair?.lotSize
                            const amount = size * minSize
                            const clrClassName = side == 'buy' ? 'color-green-futures' : 'color-red-futures';
                            return <StyledTableRow key={i}>
                              <StyledTableCell data-label="Symbol">{symbol ?
                                <Link to={"/futures/trade/" + symbol} className="text-white">{getBaseAndTargetCurrency(symbol)?.currency}</Link> : ""
                              }
                              </StyledTableCell>
                              <StyledTableCell data-label="Side" className={"capitalizeText " + (clrClassName)}>{`${side == 'buy' ? 'Long' : 'Short'}/${type}`}</StyledTableCell>
                              <StyledTableCell data-label="Stop Price" className="capitalizeText ">{stopPrice}</StyledTableCell>
                              <StyledTableCell data-label="Order pricer">{price}</StyledTableCell>
                              <StyledTableCell data-label="Amount">{amount}</StyledTableCell>
                              <StyledTableCell data-label="Order value">{value} {settleCurrency === "XBT" ? "BTC" : settleCurrency}</StyledTableCell>
                              <StyledTableCell data-label="Order time">{dateFormatOKX(createdAt || (orderTime / 1000000))}</StyledTableCell>
                              <StyledTableCell><div className="d-flex justify-content-start"><button type="button" className="market-trade-button px-1" onClick={async () => await cancelOrder(id || orderId)}>Cancel</button></div></StyledTableCell>
                            </StyledTableRow>
                          })}
                          {trades?.stopOrders?.length == 0 &&
                            <StyledTableRow>
                              <StyledTableCell className='text-white'>No Stop Orders Found!</StyledTableCell>
                            </StyledTableRow>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                  {userHistoryTab == "Realized pnl" &&
                    <TableContainer>
                      <Table sx={{ minWidth: window.innerWidth < 700 ? 900 : 1000, justifyContent: 'center' }} aria-label="customized table" stickyHeader size='small'>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Contract</StyledTableCell>
                            <StyledTableCell>Type</StyledTableCell>
                            <StyledTableCell>Realized PNL</StyledTableCell>
                            <StyledTableCell>Close Time</StyledTableCell>
                            {/* <StyledTableCell>PNL Details</StyledTableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {trades?.pnl?.length > 0 && trades?.pnl.map((elem, i) => {
                            const {
                              symbol = '',
                              settleCurrency = '',
                              type = '',
                              pnl = '0',
                              closeTime
                            } = elem
                            const clrClassName = (+pnl) >= 0 ? 'color-green-futures' : 'color-red-futures';
                            return <StyledTableRow key={i}>
                              <StyledTableCell data-label="Symbol">{symbol ?
                                <Link to={"/futures/trade/" + symbol} className="text-white">{getBaseAndTargetCurrency(symbol)?.currency}</Link> : ""
                              }
                              </StyledTableCell>
                              <StyledTableCell data-la bel="Type" className="capitalizeText">{type}</StyledTableCell>
                              <StyledTableCell data-label="PnL" className={"capitalizeText " + (clrClassName)}>{pnl} USDT</StyledTableCell>
                              <StyledTableCell data-label="Order time">{dateFormatOKX(closeTime)}</StyledTableCell>

                            </StyledTableRow>
                          })}
                          {trades?.pnl?.length == 0 &&
                            <StyledTableRow>
                              <StyledTableCell className='text-white'>No PnL Found!</StyledTableCell>
                            </StyledTableRow>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                  {userHistoryTab == "Fills" &&
                    <TableContainer>
                      <Table sx={{ minWidth: window.innerWidth < 700 ? 900 : 1000, justifyContent: 'center' }} aria-label="customized table" stickyHeader size='small'>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Contract</StyledTableCell>
                            <StyledTableCell>Amount</StyledTableCell>
                            <StyledTableCell>Order Price</StyledTableCell>
                            <StyledTableCell>Filled</StyledTableCell>
                            <StyledTableCell>Unfilled</StyledTableCell>
                            <StyledTableCell>Stop Price</StyledTableCell>
                            <StyledTableCell>Fill Price</StyledTableCell>
                            <StyledTableCell>Type</StyledTableCell>
                            <StyledTableCell>Time</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {trades?.orders?.length > 0 && trades?.orders.map((elem, i) => {
                            if (elem?.status !== 'done') { return null }

                            const {
                              side = '',
                              symbol = '',
                              endAt = null,
                              size = 0,
                              settleCurrency = '',
                              price = '',
                              filledValue = 0,
                              stopPrice = null,
                              type = '',
                              status = '',
                              dealValue = '0',
                              dealSize = 0
                            } = elem;
                            const getCurr = getBaseAndTargetCurrency(symbol)
                            const getPair = getCurr?.pair
                            const fillPrice = (+dealValue) / (+dealSize) / (+getPair?.multiplier)
                            const clrClassName = size >= 0 ? 'color-green-futures' : 'color-red-futures';
                            return <StyledTableRow key={i}>
                              <StyledTableCell data-label="Symbol">{symbol ?
                                <Link to={"/futures/trade/" + symbol} className={clrClassName}>{getBaseAndTargetCurrency(symbol)?.currency}</Link> : ""
                              }
                              </StyledTableCell>

                              <StyledTableCell className={clrClassName}>{size} {settleCurrency === "XBT" ? "BTC" : settleCurrency}</StyledTableCell>
                              <StyledTableCell data-label="Fill | Order price" className="capitalizeText ">{price}</StyledTableCell>
                              <StyledTableCell data-label="Filled | Total">{filledValue} {settleCurrency === "XBT" ? "BTC" : settleCurrency}</StyledTableCell>
                              <StyledTableCell data-label="UnFilled">{size - filledValue} {settleCurrency === "XBT" ? "BTC" : settleCurrency}</StyledTableCell>
                              <StyledTableCell data-label="Stop">{stopPrice || 0}</StyledTableCell>
                              <StyledTableCell data-label="Fill Price">{fillPrice || 0}</StyledTableCell>
                              <StyledTableCell data-label="Type" className="capitalizeText">{type}</StyledTableCell>
                              <StyledTableCell data-label="Order time">{dateFormatOKX(endAt)}</StyledTableCell>
                              <StyledTableCell data-label="Status">{status}</StyledTableCell>
                              {/* <StyledTableCell data-label="Action" className="cursor-pointer" onClick={()=> handleClickOpenOrderDetails()}>CLick</StyledTableCell> */}
                            </StyledTableRow>
                          })}
                          {trades?.orders?.length == 0 &&
                            <StyledTableRow>
                              <StyledTableCell className='text-white'>No Fills Found!</StyledTableCell>
                            </StyledTableRow>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                </div>
              </>

          }
        </div>
      </div>

    </div>
  );
}
