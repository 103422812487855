import React from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
const FuturesTPSL = ({tpPx, setTpPx, slPx, setSlPx, mode=null}) => {
    return (
        <div>
            {/* <Form.Label htmlFor="basic-url" style={{
                color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
            }}>Take Profit: </Form.Label>
            <InputGroup className="mb-2 selectOfTrade">
                <Form.Control aria-label="Text input with text" placeholder='Trigger Price' type='number' className='inputOfTrade' value={tpPx} onChange={(e) => setTpPx(() => e.target.value)} disabled={slPx}/>
            </InputGroup>
            <p style={{
                color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
            }} className='mb-2'>Est. PNL: <span className='futures-success'>&#8377;0.00</span> <span className='px-2'>|</span> +0.00%</p> */}
            <Form.Label htmlFor="basic-url" style={{
                color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
            }} >Stop Loss: </Form.Label>
            <InputGroup className="selectOfTrade mb-2">
                <Form.Control aria-label="Text input with text" placeholder='Trigger Price' type='number' className='inputOfTrade'value={slPx} onChange={(e) => setSlPx(() => e.target.value)} disabled={tpPx}/>
            </InputGroup>
            <p style={{
                color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
            }} className='mb-3'>Est. PNL: <span className='futures-danger'>&#8377;0.00</span> <span className='px-2'>|</span> +0.00%</p>
        </div>
    );
};

export default FuturesTPSL;