import React, { useState, useEffect, useRef } from "react";
import '../assets/style.css';
import Config from "../core/config/";
import { makeRequest, makeRequestWithErrorMessage } from "../core/services/v1/request";
import $, { isEmptyObject } from "jquery";
import NavbarOne from "./separate/NavbarOne";
import FuturesMenu from "./separate/futuresTrading/FuturesMenu";
import FuturesOrderBook from "./separate/futuresTrading/FuturesOrderBook";
import FuturesRecent from "./separate/futuresTrading/FuturesRecent";
import { showNumber } from "../core/helper/date-format";
import { useContextData } from "../core/context";
import { toast } from "../core/lib/toastAlert";
import FuturesMyOrders from "./separate/futuresTrading/FuturesMyOrders";
import { getCookie } from "../core/helper/cookie";
import socketIOClient from "socket.io-client";
import FuturesTradeForm from "./separate/futuresTrading/FuturesTradeForm";
import TradingViewFutures from "./separate/futuresTrading/tradeChartFutures/trading-view-futures";
import Info from "./separate/futuresTrading/info/Info";
import TradingData from "./separate/futuresTrading/TradingData";
import OrderConfirmation from "./separate/futuresTrading/dialogs.js/OrderConfirmation";
import ModifyOrder from "./separate/futuresTrading/dialogs.js/ModifyOrder";
import AdjustLeverage from "./separate/futuresTrading/dialogs.js/AdjustLeverage";
import MarginMode from "./separate/futuresTrading/dialogs.js/MarginMode";
import axios from "axios";
import ModifyMargin from "./separate/futuresTrading/dialogs.js/ModifyMargin";
import { Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import LoaderSpin from "../Component/Loader/LoaderSpin";
import LoaderUnitic from "../Component/Loader/LoaderUnitic";

function removeUSDTM(inputString) {
    const regex = /USDTM/g;
    const result = inputString.replace(regex, '');
    return result;
}
function formatNumber(number = 0) {
    if (number == 0) return 0;
    const absNumber = Math.abs(number);
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    const suffixNum = Math.floor(('' + absNumber).length / 3);
    const shortNumber = parseFloat((suffixNum !== 0 ? (absNumber / Math.pow(1000, suffixNum)) : absNumber).toPrecision(3));
    const result = shortNumber + suffixes[suffixNum];
    return (number < 0 ? '-' : '') + result;
}
function sortNum(n) {
    return !isNaN(parseFloat(n)) && isFinite(n) ? parseFloat(n) : n;
}
function decimalValue(value, decimal = 0) {
    if (decimal === 0) {
        return showNumber(parseFloat(value));
    }
    return showNumber(parseFloat(value).toFixed(decimal));
}
// function formatNumber(number) {
//     if (number < 1e3) return number.toString();
//     if (number >= 1e3 && number < 1e6) return (number / 1e3).toFixed(2) + 'K';
//     if (number >= 1e6 && number < 1e9) return (number / 1e6).toFixed(2) + 'M';
//     if (number >= 1e9 && number < 1e12) return (number / 1e9).toFixed(2) + 'B';
//     if (number >= 1e12) return (number / 1e12).toFixed(2) + 'T';
// }
function generateId() {
    return '_futures_' + Date.now() + (Math.random() * 365).toString(16).slice(4, 14);
}

function getPrefixTopic(topic) {
    if (!topic) return '';
    return topic.split(':')[0];
}
const initialState = {
    bids: [],
    asks: [],
    ts: 0,
};
const FuturesTrade = (props) => {
    const [orderListType, setOrderListType] = useState("all");
    const containerRef = useRef(null);
    const containerSecondRef = useRef(null);
    const [pairDetails, setPairDetails] = useState({ prevPrice: 0, data: [] });
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [isOverflowingSecond, setIsOverflowingSecond] = useState(false);
    const [currentPage_tradeHis, setCurrentPage_tradeHis] = useState(1);
    const [socketConnection, setSocketConnection] = useState(null);
    const [socketConnectionKucoin, setSocketConnectionKucoin] = useState(null);
    const [socketConnectionKucoinV2, setSocketConnectionKucoinV2] = useState(null);
    const [tradesShow, setTradesShow] = useState(0);
    const [firstOptionPart, setFirstOptionPart] = useState(0);
    const [pairsData, setPairsData] = useState([]);
    const [pairsLoader, setPairsLoader] = useState(true)
    const [priceStatic, setPriceStatic] = useState()
    const [orderBookData, setOrderBookData] = useState(initialState);
    const [recentTradesData, setRecentTradesData] = useState({});
    const [walletData, setWalletData] = useState([]);
    const [trades, setTrades] = useState({ openOrders: [], stopOrders: [], orders: [], positions: [], pnl: [], fills: [] });
    const [crossMenu, setCrossMenu] = useState('isolated');
    const [xMenu, setXMenu] = useState(1);
    const [tpPx, setTpPx] = useState();
    const [slPx, setSlPx] = useState();
    const [time, setTime] = useState(20011860); // Time in milliseconds
    const [formattedTime, setFormattedTime] = useState('');
    const [amount, setAmount] = useState(null);
    const [openOrderConfirmation, setOpenOrderConfirmation] = React.useState(false);
    const [tradeParams, setTradeParams] = React.useState({});
    const [favs, setFavs] = useState(null);

    // const { pairName } = useParams();
    const { pairName, handleNavigation } = props;
    const handleClickOpenOrderConfirmation = (tradeParams) => {
        setTradeParams(() => tradeParams)
        setOpenOrderConfirmation(true);
    };
    const handleCloseOrderConfirmation = () => {
        setOpenOrderConfirmation(false);
    };
    const [openModifyOrder, setOpenModifyOrder] = React.useState(false);

    const handleClickOpenModifyOrder = () => {
        setOpenModifyOrder(true);
    };
    const handleCloseModifyOrder = () => {
        setOpenModifyOrder(false);
    };
    const [openModifyMargin, setOpenModifyMargin] = React.useState(false);

    const handleClickOpenModifyMargin = () => {
        setOpenModifyMargin(true);
    };
    const handleCloseModifyMargin = () => {
        setOpenModifyMargin(false);
    };
    const [openAdjust, setOpenAdjust] = React.useState(false);

    const handleClickOpenAdjust = () => {
        setOpenAdjust(true);
    };
    const handleCloseAdjust = () => {
        setOpenAdjust(false);
    };
    const [openMarginMode, setOpenMarginMode] = React.useState(false);

    const handleClickOpenMarginMode = () => {
        setOpenMarginMode(true);
    };
    const handleCloseMarginMode = () => {
        setOpenMarginMode(false);
    };
    const { myProfile, setUserProfile, p2pSettings, futuresBalance, setFuturesBalance, dispatch } = useContextData();
    const formatSymbolName = (baseCurrency = '', quoteCurrency = '') => {
        if (baseCurrency === 'XBT') {
            baseCurrency = 'BTC'
        }
        const quoteCurrencyEdition = <span style={{ color: '#C8C8C8' }}>{quoteCurrency}</span>
        const symbolEdition = <span className='fw-bold text-capitalize'>{baseCurrency + ' Perpetual/'}{quoteCurrencyEdition}</span>
        return symbolEdition;
    }
    const getBaseAndTargetCurrency = (symbol = '') => {
        const getOne = pairsData.find(data => data.symbol === symbol);
        const getForm = formatSymbolName(getOne?.baseCurrency, getOne?.quoteCurrency)
        return { baseCurrency: getOne?.baseCurrency || "", quoteCurrency: getOne?.quoteCurrency || "", currency: getForm, pair: getOne, price: getOne?.lastTradePrice }
    }
    const asksModify = async (a1, a2) => {
        const depthMap = new Map();
        const pricesSet = new Set();
        let totalQuantity = 0;

        // Populate depthMap with a1
        a1.reverse().forEach((depth, index, array) => {
            // if (depth.length === 4) {
            const price = depth[0];
            const quantity = parseFloat(depth[1]) * 0.01; // Multiply by 0.01
            totalQuantity += quantity;
            const orders = totalQuantity.toFixed(2); // Calculate orders based on cumulative sum
            depthMap.set(price, [price, quantity.toFixed(2), orders.toString()]);
            pricesSet.add(price);
            // }
        });

        // Iterate over a2
        totalQuantity = 0; // Reset totalQuantity
        a2.reverse().forEach((depth, index, array) => {
            // if (depth.length === 4) {
            const price = depth[0];
            const size = parseFloat(depth[1]) * 0.01; // Multiply by 0.01
            totalQuantity += size;
            const orders = totalQuantity.toFixed(2); // Calculate orders based on cumulative sum

            if (size === 0) {
                // If size is 0, delete the depth from depthMap and pricesSet
                depthMap.delete(price);
                pricesSet.delete(price);
            } else {
                // If size is not 0
                if (pricesSet.has(price)) {
                    // If price exists in a1, update the depthMap
                    depthMap.set(price, [price, size.toFixed(2), orders]);
                } else {
                    // If price does not exist in a1, add it to depthMap and pricesSet
                    depthMap.set(price, [price, size.toFixed(2), orders]);
                    pricesSet.add(price);
                }
            }
            // }
        });

        // Convert depthMap back to array and sort
        const result = Array.from(depthMap.values()).sort((a, b) => parseFloat(a[0]) - parseFloat(b[0]));

        return result;
    };
    const bidsModify = async (a1, a2) => {
        const depthMap = new Map();
        const pricesSet = new Set();
        let totalQuantity = 0;

        // Populate depthMap with a1
        a1.forEach((depth, index) => {
            // if (depth.length === 4) {
            const price = depth[0];
            const quantity = parseFloat(depth[1]) * 0.01; // Multiply by 0.01
            totalQuantity += quantity;
            const orders = totalQuantity.toFixed(2); // Calculate orders based on cumulative sum
            depthMap.set(price, [price, quantity.toFixed(2), orders]); // Round to 2 decimal places
            pricesSet.add(price);
            // }
        });

        // Iterate over a2
        totalQuantity = 0; // Reset totalQuantity
        a2.forEach((depth, index) => {
            // if (depth.length === 4) {
            const price = depth[0];
            const size = parseFloat(depth[1]) * 0.01; // Multiply by 0.01
            totalQuantity += size;
            const orders = totalQuantity.toFixed(2); // Calculate orders based on cumulative sum

            if (size === 0) {
                // If size is 0, delete the depth from depthMap and pricesSet
                depthMap.delete(price);
                pricesSet.delete(price);
            } else {
                // If size is not 0
                if (pricesSet.has(price)) {
                    // If price exists in a1, update the depthMap
                    depthMap.set(price, [price, size.toFixed(2), orders]); // Round to 2 decimal places
                } else {
                    // If price does not exist in a1, add it to depthMap and pricesSet
                    depthMap.set(price, [price, size.toFixed(2), orders]); // Round to 2 decimal places
                    pricesSet.add(price);
                }
            }
            // }
        });

        // Convert depthMap back to array and sort
        const result = Array.from(depthMap.values()).sort((a, b) => parseFloat(b[0]) - parseFloat(a[0]));

        return result;
    };
    const orderBookDataCalculation = async (oldBids, oldAsks, newBids, newAsks) => {
        try {
            const modifiedBids = await bidsModify(oldBids, newBids);
            const modifiedAsks = await asksModify(oldAsks, newAsks);
            setOrderBookData(() => {
                return {
                    bids: modifiedBids,
                    asks: modifiedAsks,
                }
            })
        } catch (error) {
            console.error('Error modifying data:', error);
            setOrderBookData(() => {
                return {
                    bids: [],
                    asks: [],
                }
            })
        }
    }
    const pairDataFetch = async () => {
        setPairsLoader(true);
        await axios.get(`${Config?.V2_API_URL}trade-futures/get-pair?pair=${pairName}`)
            .then(res => {
                if (res.data?.data?.orderBook.code != '200000') {
                    toast({ type: "error", message: res.data?.data?.orderBook?.msg || 'An error ocurred!' });
                    return handleNavigation('XBTUSDTM')
                }
                const newAsks = res.data?.data?.orderBook?.data?.asks || [];
                const newBids = res.data?.data?.orderBook?.data?.bids || [];
                const oldBids = orderBookData?.bids || [];
                const oldAsks = orderBookData?.asks || [];
                orderBookDataCalculation(oldBids, oldAsks, newBids, newAsks)
                setRecentTradesData(() => res.data?.data?.recentTrade?.data)
                setTime(() => res.data?.data?.pair?.data?.nextFundingRateTime)
                setPriceStatic(() => res.data?.data?.pair?.data?.lastTradePrice)
                setPairDetails((data) => { return { prevPrice: data?.prevPrice, data: res.data?.data?.pair?.data } })
                setPairsData(() => (res.data?.data?.pairs.data).filter(data => data?.quoteCurrency === "USDT"))
                const favAdd = (res.data?.data?.pairs?.data).filter(data => myProfile?.futuresFav?.some(fav => fav === data?.symbol))
                setFavs(() => favAdd)
            })
            .catch(err => console.log(err))
            .finally(() => {
                setPairsLoader(() => false)
            })
    }
    const ordersDataFetch = async () => {
        const params = {
            url: `${Config.V2_API_URL}trade-futures/get-orders`,
            method: 'GET'
        }
        const response = (await makeRequest(params));
        if (response?.status) {
            // console.log(response?.data)
            setTrades(() => response?.data)
        }
        else {

        }
    }
    useEffect(() => {
        const favAdd = (pairsData).filter(data => myProfile?.futuresFav?.some(fav => fav === data?.symbol))
        setFavs(() => favAdd)
    }, [myProfile, pairsData])

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(prevTime => prevTime - 1000);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((time / (1000 * 60)) % 60);
        const seconds = Math.floor((time / 1000) % 60);

        // Format time as hh:mm:ss
        const formattedTimeString = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

        setFormattedTime(() => formattedTimeString);
    }, [time]);
    const checkOverflow = () => {
        if (containerRef.current && containerSecondRef.current) {
            setIsOverflowing(containerRef.current.scrollWidth > containerRef.current.clientWidth);
            setIsOverflowingSecond(containerSecondRef.current.scrollWidth > containerSecondRef.current.clientWidth);
        }
    };

    useEffect(() => {
        checkOverflow();

        window.addEventListener('resize', checkOverflow);
        return () => {
            window.removeEventListener('resize', checkOverflow);
        };
    }, [pairsData]);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        pairDataFetch()
        ordersDataFetch()
        setFuturesBalance();
        // getWalletCurrency();
        $(document).ready(function () {
            $(".futures-trading-table-total-height li").mouseenter(function () {
                $(this).addClass("divider");
            });
            $(".futures-trading-table-total-height li").mouseleave(function () {
                $(this).removeClass("divider");
            });
        });


    }, []);

    useEffect(() => {
        let socket = socketIOClient(Config.SOCKET_URL, {
            // transports: ["polling"],
            transports: ["websocket"],
            // rejectUnauthorized: false
        });
        let socketUnsubscribe;
        if (socket) {
            socket.on("connect", function () {
                // console.log("socket connected");
                setSocketConnection(() => socket);
                socketUnsubscribe = socket;
            });
            socket.on("connect_error", (err) => {
                // console.log("socket connect_error", err);
            });
            socket.on("disconnect", function () {
                // console.log("socket disconnected");
            });
        }

        return () => {
            if (socketUnsubscribe) {
                socketUnsubscribe.disconnect();
            }
        };
    }, []);

    let socket = null;
    let heartbeatInterval = null;
    let alive = false;
    let pingTimeout = null;
    const resetPingTimeout = () => {
        if (pingTimeout) {
            clearTimeout(pingTimeout);
        }
        pingTimeout = setTimeout(() => {
            // console.log('No pong received within 10 seconds, disconnecting...');
            if (socket) {
                socket.close();
            }
        }, 10000);
    }
    const startHeartbeat = () => {
        if (heartbeatInterval) {
            clearInterval(heartbeatInterval);
        }
        heartbeatInterval = setInterval(() => {
            resetPingTimeout();
            if (socket) {

                socket.send(JSON.stringify({ id: generateId(), type: 'ping' }));
            }
        }, 59000);
    };

    const startPing = () => {
        resetPingTimeout();
        if (socket) {

            socket.send(JSON.stringify({ id: generateId(), type: 'ping' }));
        }
    };

    const stopHeartbeat = () => {
        clearInterval(heartbeatInterval);
        heartbeatInterval = null;
    };

    const stopPing = () => {
        if (pingTimeout) {
            clearTimeout(pingTimeout);

            pingTimeout = null;
        }
    };
    useEffect(() => {

        const setupWebSocket = async () => {
            try {
                if (socket) {
                    socket.close();
                    socket = null;
                }
                if (socketConnectionKucoin) {
                    socketConnectionKucoin.close();
                    setSocketConnectionKucoin(() => null)
                }
                const getTokenResponse = await axios.post(`${Config.V2_API_URL}trade-futures/get-socket-futures`);
                const token = getTokenResponse?.data?.data?.data?.token;
                const instanceServerEndpoint = getTokenResponse?.data?.data?.data?.instanceServers[0].endpoint;

                const wssUri = `${instanceServerEndpoint}?token=${token}&acceptUserMessage=true&connectId=${generateId()}`;

                socket = new WebSocket(wssUri);


                socket.onopen = () => {
                    // Start heartbeat and ping
                    alive = true;
                    startHeartbeat();
                    startPing();
                    setSocketConnectionKucoin(() => socket)
                };

                socket.onmessage = async (event) => {
                    const message = JSON.parse(event?.data);
                    // console.log(message);
                    if (message) {
                        switch (message.type) {
                            case 'welcome':
                            case 'ack':
                                break;
                            case 'pong':
                                // Stop ping timeout
                                stopPing(pingTimeout);
                                break;
                            default:
                                await handleMessage(message)
                        }
                    }
                };

                socket.onclose = () => {
                    // console.log('WebSocket connection closed');
                    // Cleanup heartbeat and ping
                    alive = false;
                    stopHeartbeat();
                    stopPing();
                    if (socket) {
                        socket.close();
                        socket = null;
                    }
                    if (socketConnectionKucoin) {
                        socketConnectionKucoin.close();
                        setSocketConnectionKucoin(() => null)
                    }
                    setTimeout(setupWebSocket, 5000); // Retry after 5 seconds
                };

                socket.onerror = (error) => {
                    alive = false;
                    console.error('WebSocket error:', error);
                };
            } catch (error) {
                console.error('Error fetching token:', error);
            }
        };
        setupWebSocket();
        return () => {
            if (socket) {
                socket.close();
                socket = null;
            }
            if (socketConnectionKucoin) {
                socketConnectionKucoin.close();
                setSocketConnectionKucoin(() => null)
            }
        };
    }, []);

    let socketV2 = null;
    let heartbeatIntervalV2 = null;
    let aliveV2 = false;
    let pingTimeoutV2 = null;
    const resetPingTimeoutV2 = () => {
        if (pingTimeoutV2) {
            clearTimeout(pingTimeoutV2);
        }
        pingTimeoutV2 = setTimeout(() => {
            // console.log('No pong received within 10 seconds, disconnecting...');
            if (socketV2) {
                socketV2.close();
            }
        }, 10000);
    }
    const startHeartbeatV2 = () => {
        if (heartbeatIntervalV2) {
            clearInterval(heartbeatIntervalV2);
        }
        heartbeatIntervalV2 = setInterval(() => {
            resetPingTimeoutV2();
            if (socketV2) {

                socketV2.send(JSON.stringify({ id: generateId(), type: 'ping' }));
            }
        }, 59000);
    };

    const startPingV2 = () => {
        resetPingTimeoutV2();
        if (socketV2) {

            socketV2.send(JSON.stringify({ id: generateId(), type: 'ping' }));
        }
    };

    const stopHeartbeatV2 = () => {
        clearInterval(heartbeatIntervalV2);
        heartbeatIntervalV2 = null;
    };

    const stopPingV2 = () => {
        if (pingTimeoutV2) {
            clearTimeout(pingTimeoutV2);

            pingTimeoutV2 = null;
        }
    };
    useEffect(() => {

        const setupWebSocketV2 = async () => {
            try {
                if (socketV2) {
                    socketV2.close();
                    socketV2 = null;
                }
                if (socketConnectionKucoinV2) {
                    socketConnectionKucoinV2.close();
                    setSocketConnectionKucoinV2(() => null)
                }
                const params = {
                    url: `${Config.V2_API_URL}trade-futures/get-socket-futures-private`,
                    method: "POST",
                };
                const getTokenResponse = await makeRequestWithErrorMessage(params);
                if (!getTokenResponse.status) {
                    return;
                }
                const token = getTokenResponse?.data?.data?.token;
                const instanceServerEndpoint = getTokenResponse?.data?.data?.instanceServers[0].endpoint;

                const wssUri = `${instanceServerEndpoint}?token=${token}&acceptUserMessage=true&connectId=${generateId()}`;

                socketV2 = new WebSocket(wssUri);


                socketV2.onopen = () => {
                    // Start heartbeat and ping
                    aliveV2 = true;
                    startHeartbeatV2();
                    startPingV2();
                    setSocketConnectionKucoinV2(() => socketV2)
                };

                socketV2.onmessage = async (event) => {
                    const message = JSON.parse(event?.data);
                    // console.log(message);
                    if (message) {
                        switch (message.type) {
                            case 'welcome':
                            case 'ack':
                                break;
                            case 'pong':
                                // Stop ping timeout
                                stopPingV2(pingTimeout);
                                break;
                            default:
                                await handleMessageV2(message)
                        }
                    }
                };

                socketV2.onclose = () => {
                    // console.log('WebSocket connection closed');
                    // Cleanup heartbeat and ping
                    aliveV2 = false;
                    stopHeartbeatV2();
                    stopPingV2();
                    if (socketV2) {
                        socketV2.close();
                        socketV2 = null;
                    }
                    if (socketConnectionKucoinV2) {
                        socketConnectionKucoinV2.close();
                        setSocketConnectionKucoinV2(() => null)
                    }
                    setTimeout(setupWebSocketV2, 2000); // Retry after 5 seconds
                };

                socketV2.onerror = (error) => {
                    aliveV2 = false;
                    console.error('WebSocket error:', error);
                };
            } catch (error) {
                console.error('Error fetching token:', error);
            }
        };
        setupWebSocketV2();
        return () => {
            if (socketV2) {
                socketV2.close();
                socketV2 = null;
            }
            if (socketConnectionKucoinV2) {
                socketConnectionKucoinV2.close();
                setSocketConnectionKucoinV2(() => null)
            }
        };
    }, []);

    useEffect(() => {
        if (socketConnectionKucoin != null) {
            loadSocket();
        }
    }, [socketConnectionKucoin]);
    useEffect(() => {
        if (socketConnectionKucoinV2 != null) {
            loadSocketV2();
        }
    }, [socketConnectionKucoinV2]);
    // useEffect(() => {
    //     if (socketConnection != null) {
    //         loadSocketV2();
    //     }
    // }, [socketConnection, myProfile]);
    function decimalValueNum(value, decimal) {
        return parseFloat(value).toFixed(decimal);
    }
    function getTopic(marketString) {
        const parts = marketString.split("/");
        const level2 = parts[2];
        const level2Parts = level2.split(":");
        const extractedValue = level2Parts[0];
        return extractedValue;
    }
    function loadSocketV3() {
        const userJWT = getCookie("userToken");
        socketConnection.emit("userFuturesDetails", { userId: userJWT, symbol: pairName });
        socketConnection.on("getFuturesBalance", function (data) {

            if (data?.data && data?.data?.currency === "USDT" && data?.data?.availableBalance) {
                dispatch({
                    type: 'SET_FUTURES_BALANCE',
                    payload: data?.data
                });
            }
        })
        socketConnection.on("orders", function (data) {
            // console.log('orders', data)
            setTrades((prev) => {
                const newOrder = data?.data;

                if (!newOrder) return prev;

                const clientOid = newOrder.clientOid;

                if (newOrder.status === 'active' || newOrder.status === 'open') {
                    const updatedOpenOrders = prev.openOrders.length === 0
                        ? [newOrder]
                        : prev.openOrders.map(order => order.clientOid === clientOid ? newOrder : order);
                    const updatedOrders = prev.orders.filter(order => order.clientOid !== clientOid);
                    const final = {
                        ...prev,
                        // orders: updatedOrders,
                        // fills: updatedOrders,
                        openOrders: updatedOpenOrders,
                        stopOrders: updatedOpenOrders
                    }
                    // console.log('final 0', final)
                    return final;
                } else {
                    const updatedOrders = prev.orders.map(order => order.clientOid === clientOid ? newOrder : order);
                    const updatedOpenOrders = prev.openOrders.filter(order => order.clientOid !== clientOid);
                    const final = {
                        ...prev,
                        // orders: newOrder.status === 'done' && prev.openOrders.some(order => order.clientOid === clientOid) ? updatedOrders : [newOrder, ...updatedOrders],
                        // fills: newOrder.status === 'done' && prev.openOrders.some(order => order.clientOid === clientOid) ? updatedOrders : [newOrder, ...updatedOrders],
                        openOrders: updatedOpenOrders,
                        stopOrders: updatedOpenOrders
                    }
                    // console.log('final ', final)
                    return final;
                }
            });

            // orders: resultOrders,
            // fills: resultOrders,
            // openOrders: resultActiveOrders,
            // stopOrders: resultActiveOrders
            // openOrders: [], stopOrders: [], orders: [], positions: [], pnl: [], fills: []

        })
        socketConnection.on("stopOrders", function (data) {
            // console.log('stopOrders', data)
            // setTrades((prev) => {
            //     const newOrder = data?.data; // Assuming data?.data is an array with one element
            //     const orderMap = new Map(prev.stopOrders.map(order => [order.orderId, order]));
            // if (!newOrder) return prev;
            //     // Handle the new order
            //     if (newOrder) {
            //         if (newOrder.type === 'open') {
            //             orderMap.set(newOrder.orderId, newOrder);
            //         } else if (orderMap.has(newOrder.orderId)) {
            //             orderMap.delete(newOrder.orderId);
            //         }
            //     }

            //     const updatedOrders = [...orderMap.values()];

            //     // Move the new order to the front if it's of type 'open'
            //     const resultOrders = newOrder && newOrder.type === 'open' ? [newOrder, ...updatedOrders.filter(order => order.orderId !== newOrder.orderId)] : updatedOrders;

            //     return {
            //         ...prev,
            //         stopOrders: resultOrders
            //     };
            // });
        })
        socketConnection.on("positions", function (data) {
            setTrades((prev) => {
                const newOrder = data?.data;
                if (!newOrder) return prev;
                const orderMap = new Map(prev.positions.map(order => [order.symbol, order]));

                // Handle the new order
                if (newOrder) {
                    if (newOrder.isOpen === true) {
                        orderMap.set(newOrder.symbol, newOrder);
                    } else if (orderMap.has(newOrder.symbol)) {
                        orderMap.delete(newOrder.symbol);
                    }
                }

                const updatedOrders = [...orderMap.values()];

                const resultOrders = newOrder && newOrder.isOpen === true ? [newOrder, ...updatedOrders.filter(order => order.symbol !== newOrder.symbol)] : updatedOrders;

                return {
                    ...prev,
                    positions: resultOrders
                };
            });
        })
    }
    function loadSocket() {
        const sendDataJSON = [
            {
                "id": generateId(),
                "type": "subscribe",
                "topic": `/contractMarket/level2Depth50:${pairName}`,
                "privateChannel": false,
                "response": true
            },
            {
                "id": generateId(),
                "type": "subscribe",
                "topic": `/contractMarket/execution:${pairName}`,
                "privateChannel": false,
                "response": true
            },
            {
                "id": generateId(),
                "type": "subscribe",
                "topic": `/contractMarket/ticker:${pairName}`,
                "response": true
            },
            {
                "id": generateId(),
                "type": "subscribe",
                "topic": `/contract/instrument:${pairName}`,
                "privateChannel": false,
                "response": true
            },
            {
                "id": generateId(),
                "type": "subscribe",
                "topic": `/contractMarket/snapshot:${pairName}`,
                "privateChannel": false,
                "response": true
            },
            {
                "id": generateId(),
                "type": "subscribe",
                "topic": "/contract/announcement",
                "privateChannel": false,
                "response": true
            },
        ]

        if (socketConnectionKucoin) {
            sendDataJSON.forEach(data => socketConnectionKucoin.send(JSON.stringify(data)))

        }

    }
    async function handleMessage(data) {
        // if(data.subject==='funding.begin'|| data.subject==='funding.end'){
        // }
        // console.log(data)
        // if((data?.topic).split('/')[1].split('/')[1]==='announcement'){

        // }

        let dataPairName = data?.topic ? (data?.topic).split(':')[1] : '';
        // dataPairName=dataPairName.split('_')[0]
        if (data?.data && dataPairName === pairName) {
            const getWhose = getTopic(data?.topic);
            if (getWhose) {
                if (getWhose === 'level2Depth50') {
                    const newAsks = data?.data?.asks || [];
                    const newBids = data?.data?.bids || [];
                    const oldBids = orderBookData?.bids || [];
                    const oldAsks = orderBookData?.asks || [];
                    orderBookDataCalculation(oldBids, oldAsks, newBids, newAsks)
                }
                if (getWhose === 'execution') {

                    setRecentTradesData((oldData) => {

                        if (!Array.isArray(oldData)) {
                            oldData = [];
                        }

                        const newData = [
                            data?.data,
                            ...oldData
                        ];

                        const limitedData = newData.slice(0, 18);

                        if (JSON.stringify(limitedData) !== JSON.stringify(oldData)) {
                            return [...limitedData];
                        }

                        return oldData;
                    });
                }
                if (getWhose === 'instrument') {
                    // setPairDetails] = useState({ prevPrice: 0, data: [] });
                    // data?.data?.markPrice

                    setPairDetails((prevPairDetails) => ({
                        ...prevPairDetails,
                        data: {
                            ...prevPairDetails.data,
                            markPrice: data?.data?.markPrice
                        }
                    }));

                }
                if (getWhose === 'ticker') {
                    setPairDetails((prevPairDetails) => ({
                        ...prevPairDetails,
                        data: {
                            ...prevPairDetails.data,
                            lastTradePrice: data?.data?.price
                        }
                    }));
                }
                if (getWhose === 'snapshot') {
                    setPairDetails((prevPairDetails) => ({
                        ...prevPairDetails,
                        data: {
                            ...prevPairDetails.data,
                            volumeOf24h: data?.data?.volume
                        }
                    }));
                }


            }
        }
    }
    function loadSocketV2() {
        const sendDataJSON = [
            {
                "id": generateId(),
                "type": "subscribe",
                "topic": "/contractMarket/tradeOrders",
                "privateChannel": true,
                "response": true
            },
            {
                "id": generateId(),
                "type": "subscribe",
                "topic": "/contractMarket/advancedOrders",
                "privateChannel": true,
                "response": true
            },
            {
                "id": generateId(),
                "type": "subscribe",
                "topic": "/contractAccount/wallet",
                "privateChannel": true,
                "response": true
            },
            {
                "id": generateId(),
                "type": "subscribe",
                "topic": "/contract/positionAll",
                "privateChannel": true,
                "response": true
            },
        ]

        if (socketConnectionKucoinV2) {
            sendDataJSON.forEach(data => socketConnectionKucoinV2.send(JSON.stringify(data)))

        }

    }
    async function handleMessageV2(data) {
        // console.log(data)
        // dataPairName=dataPairName.split('_')[0]
        if (data?.subject === 'availableBalance.change') {
            if (data && data?.data?.currency === "USDT" && data?.data?.availableBalance) {
                dispatch({
                    type: 'SET_FUTURES_BALANCE',
                    payload: data?.data
                });
            }
        }
        if (data?.subject === 'position.change') {
            setTrades((prev) => {
                const newOrder = data?.data;
                if (!newOrder) return prev;
                const orderMap = new Map(prev.positions.map(order => [order.symbol, order]));

                // Handle the new order
                if (newOrder) {
                    if (newOrder.isOpen === true) {
                        orderMap.set(newOrder.symbol, newOrder);
                    } else if (orderMap.has(newOrder.symbol)) {
                        orderMap.delete(newOrder.symbol);
                    }
                }

                const updatedOrders = [...orderMap.values()];

                const resultOrders = newOrder && newOrder.isOpen === true ? [newOrder, ...updatedOrders.filter(order => order.symbol !== newOrder.symbol)] : updatedOrders;

                return {
                    ...prev,
                    positions: resultOrders
                };
            });
        }
        if (data?.subject === 'stopOrder') {
            setTrades((prev) => {
                const newOrder = data?.data; // Assuming data?.data is an array with one element
                const orderMap = new Map(prev.stopOrders.map(order => [order.orderId, order]));
                if (!newOrder) return prev;
                // Handle the new order
                if (newOrder) {
                    if (newOrder.type === 'open') {
                        orderMap.set(newOrder.orderId, newOrder);
                    } else if (orderMap.has(newOrder.orderId)) {
                        orderMap.delete(newOrder.orderId);
                    }
                }

                const updatedOrders = [...orderMap.values()];

                // Move the new order to the front if it's of type 'open'
                const resultOrders = newOrder && newOrder.type === 'open' ? [newOrder, ...updatedOrders.filter(order => order.orderId !== newOrder.orderId)] : updatedOrders;

                return {
                    ...prev,
                    stopOrders: resultOrders
                };
            });
        }

        if (data?.subject === 'orderChange') {
            setTrades((prev) => {
                const newOrder = data?.data;

                if (!newOrder) return prev;

                const clientOid = newOrder.clientOid;

                if (newOrder.status === 'active' || newOrder.status === 'open') {
                    const updatedOpenOrders = prev.openOrders.length === 0
                        ? [newOrder]
                        : prev.openOrders.map(order => order.clientOid === clientOid ? newOrder : order);
                    const final = {
                        ...prev,
                        openOrders: updatedOpenOrders,
                    }
                    // console.log('final 0', final)
                    return final;
                }
                else if (newOrder.status === 'done') {
                    const updatedOrders = prev.orders ? prev.orders.map(order => order.clientOid === clientOid ? newOrder : order) : newOrder;
                    const updatedOpenOrders = prev.openOrders.filter(order => order.clientOid !== clientOid);
                    const updatedStopOrders = prev.stopOrders.filter(order => (order.orderId || order.id) !== (clientOid || newOrder?.orderId || newOrder?.id));
                    // const updatedPositions = prev.positions.filter(order => order.symbol !== newOrder?.symbol);
                    const final = {
                        ...prev,
                        orders: newOrder.status === 'done' && prev.openOrders.some(order => order.clientOid === clientOid) ? updatedOrders : [newOrder, ...updatedOrders],
                        openOrders: updatedOpenOrders,
                        // positions: updatedPositions,
                        stopOrders: updatedStopOrders
                    }
                    return final;
                }
                else {
                    return prev;
                }
            });
        }

    }
    async function cancelOrder(ordId) {

        const params = {
            url: `${Config.V2_API_URL}trade-futures/cancel-order/${ordId}`,
            method: "DELETE",
        };
        const response = await makeRequest(params);
        if (response.status) {
            toast({ type: "success", message: response.message });
            ordersDataFetch()
            return true;

        } else {
            toast({ type: "error", message: response.message });
            return false;
        }
    }
    const submitTradeWithAPI = async (tradeParam) => {
        // const data = JSON.stringify(tradeParam);
        const params = {
            url: `${Config.V2_API_URL}trade-futures/submit-futures-trade`,
            method: "POST",
            body: { data: tradeParam }
        };
        const response = await makeRequestWithErrorMessage(params);
        if (response.status) {
            toast({ type: "success", message: response.message });
            setUserProfile();
            ordersDataFetch()
            // getWalletCurrency();
            return true;
        }
        else {
            toast({ type: "error", message: response.message });
            return false;
        }
    }
    const updateFavPairs = async (name, dataSingle) => {
        const params = {
            url: `${Config.V1_API_URL}user/updateFuturesFav`,
            method: "POST",
            body: { data: name }
        };
        const response = await makeRequest(params);

        if (response.status) {
            if (response?.isAdd === "1") {

                setFavs((data) => [...data, dataSingle])
            }
            else if (response?.isAdd === "0") {
                setFavs((data) => {
                    const cutData = data.filter(data => data?.symbol !== dataSingle?.symbol)
                    // [...data,dataSingle]
                    return cutData
                })
            }
            // toast({ type: "success", message: response.message });
        }
        else {
            // toast({ type: "error", message: response.message });
        }
    }
    const addTpSl = (tradeParams) => {
        submitTradeWithAPI(tradeParams)
    }
    const closePosition = (tradeParams) => {
        submitTradeWithAPI(tradeParams)
    }

    const fromSymbolName = (baseCurrency) => {
        if (!baseCurrency || baseCurrency == '') return ''
        if (baseCurrency === 'XBT') {
            baseCurrency = 'BTC'
        }
        return baseCurrency;
    }

    const myOrdersDiv = <div className="">
        <FuturesMyOrders myProfile={myProfile} trades={trades} getBaseAndTargetCurrency={getBaseAndTargetCurrency} cancelOrder={cancelOrder} addTpSl={addTpSl} closePosition={closePosition} setCurrentPage_tradeHis={setCurrentPage_tradeHis} currentPage_tradeHis={currentPage_tradeHis} decimalValue={decimalValue} pairsLoader={pairsLoader} />
    </div>
    const orderBooksDiv = <div className="col-12 col-lg-4 col-xxl-3 h-100">
        <div className="pt-2">
            <div className="d-flex flex-column trading-page-top-section-2 align-items-center top-heading-total-height">
                <div className="me-auto ps-2 menu-option-part-order w-100" style={{ gap: '10px' }}>
                    <Typography component='h1' className={`mb-0 pt-1 pb-2 menu-option-part-order-p  ${tradesShow == 0 ? 'text-active-order' : ''}`} onClick={() => setTradesShow(() => 0)}>Order Book</Typography>
                    <Typography component='h1' className={`mb-0 pt-1 pb-2 menu-option-part-order-p  ${tradesShow == 1 ? 'text-active-order' : ''}`} onClick={() => setTradesShow(() => 1)}>Real-Time Trades</Typography>
                </div>
            </div>
            {tradesShow == 0 ?
                <div className='p-0 futures-trading-table-total-height'>
                    <div className="text-end d-flex justify-content-end align-items-center orderbook-grid-tabs mt-1">
                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation" onClick={() => setOrderListType('buy')}>
                                <button className="nav-link active" id="pills-grid1-tab" data-bs-toggle="pill" data-bs-target="#pills-grid1" type="button" role="tab" aria-controls="pills-grid1" aria-selected="true">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12">
                                        <g id="Rectangle_9" data-name="Rectangle 9" fill="none" stroke="#20b958" strokeLinecap="round" strokeWidth="1">
                                            <rect width="5" height="12" rx="0.5" stroke="none" />
                                            <rect x="0.5" y="0.5" width="4" height="11" fill="none" />
                                        </g>
                                        <g id="Rectangle_10" data-name="Rectangle 10" transform="translate(8 5) rotate(-90)" fill="none" stroke="#b3b7c2" strokeLinecap="round" strokeWidth="1">
                                            <rect width="5" height="12" rx="0.5" stroke="none" />
                                            <rect x="0.5" y="0.5" width="4" height="11" fill="none" />
                                        </g>
                                        <g id="Rectangle_11" data-name="Rectangle 11" transform="translate(8 12) rotate(-90)" fill="none" stroke="#b3b7c2" strokeLinecap="round" strokeWidth="1">
                                            <rect width="5" height="12" rx="0.5" stroke="none" />
                                            <rect x="0.5" y="0.5" width="4" height="11" fill="none" />
                                        </g>
                                    </svg>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation" onClick={() => setOrderListType('sell')}>
                                <button className="nav-link" id="pills-grid2-tab" data-bs-toggle="pill" data-bs-target="#pills-grid2" type="button" role="tab" aria-controls="pills-grid2" aria-selected="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12">
                                        <g id="Rectangle_9" data-name="Rectangle 9" fill="none" stroke="#ff5a5a" strokeLinecap="round" strokeWidth="1">
                                            <rect width="5" height="12" rx="0.5" stroke="none" />
                                            <rect x="0.5" y="0.5" width="4" height="11" fill="none" />
                                        </g>
                                        <g id="Rectangle_10" data-name="Rectangle 10" transform="translate(8 5) rotate(-90)" fill="none" stroke="#b3b7c2" strokeLinecap="round" strokeWidth="1">
                                            <rect width="5" height="12" rx="0.5" stroke="none" />
                                            <rect x="0.5" y="0.5" width="4" height="11" fill="none" />
                                        </g>
                                        <g id="Rectangle_11" data-name="Rectangle 11" transform="translate(8 12) rotate(-90)" fill="none" stroke="#b3b7c2" strokeLinecap="round" strokeWidth="1">
                                            <rect width="5" height="12" rx="0.5" stroke="none" />
                                            <rect x="0.5" y="0.5" width="4" height="11" fill="none" />
                                        </g>
                                    </svg>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation" onClick={() => setOrderListType('all')}>
                                <button className="nav-link" id="pills-grid3-tab" data-bs-toggle="pill" data-bs-target="#pills-grid3" type="button" role="tab" aria-controls="pills-grid3" aria-selected="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12">
                                        <g id="Rectangle_5" data-name="Rectangle 5" transform="translate(8)" fill="none" stroke="#b3b7c2" strokeLinecap="round" strokeWidth="1">
                                            <rect width="12" height="5" rx="0.5" stroke="none" />
                                            <rect x="0.5" y="0.5" width="11" height="4" fill="none" />
                                        </g>
                                        <g id="Rectangle_7" data-name="Rectangle 7" transform="translate(8 7)" fill="none" stroke="#b3b7c2" strokeLinecap="round" strokeWidth="1">
                                            <rect width="12" height="5" rx="0.5" stroke="none" />
                                            <rect x="0.5" y="0.5" width="11" height="4" fill="none" />
                                        </g>
                                        <g id="Rectangle_6" data-name="Rectangle 6" transform="translate(0 7)" fill="none" stroke="#20b958" strokeLinecap="round" strokeWidth="1">
                                            <rect width="5" height="5" rx="0.5" stroke="none" />
                                            <rect x="0.5" y="0.5" width="4" height="4" fill="none" />
                                        </g>
                                        <g id="Rectangle_8" data-name="Rectangle 8" fill="none" stroke="#ff5a5a" strokeLinecap="round" strokeWidth="1">
                                            <rect width="5" height="5" rx="0.5" stroke="none" />
                                            <rect x="0.5" y="0.5" width="4" height="4" fill="none" />
                                        </g>
                                    </svg>

                                </button>
                            </li>
                        </ul>
                    </div>
                    {
                        pairsLoader ? <div className='trading-page-top-section-3 '>
                            <div className='' style={{ height: '480px' }}>
                                <div className="d-flex flex-row mb-1 mobile-justify-content orderbook-table-heading-section border-top-0">
                                    <div className="progress-table-1"><span className='orderbook-text-1'>Price(USDT)</span></div>
                                    <div className="progress-table-2"><span className='orderbook-text-1'>Amount</span></div>
                                    <div className="progress-table-3"><span className='orderbook-text-1'>Total</span></div>
                                </div>
                                <div className="h-100" style={{ marginTop: '-25px' }}><LoaderSpin /></div>

                            </div>
                        </div> :
                            <div>
                                <div className='trading-page-top-section-3 '>
                                    <div className='trading-table-left-section progress-bar-bg-color'>
                                        <div className="d-flex flex-row mb-1 mobile-justify-content orderbook-table-heading-section border-top-0">
                                            <div className="progress-table-1"><span className='orderbook-text-1'>Price(USDT)</span></div>
                                            <div className="progress-table-2"><span className='orderbook-text-1'>Amount</span></div>
                                            <div className="progress-table-3"><span className='orderbook-text-1'>Total</span></div>
                                        </div>
                                        <FuturesOrderBook setPriceStatic={setPriceStatic} viewOrderList={orderBookData
                                            ?
                                            (orderListType === "all" || orderListType === "sell")
                                                ?
                                                orderBookData?.asks ? orderBookData?.asks?.slice(0, orderListType === "all" ? 8 : 16).reverse() : []
                                                :
                                                orderBookData?.bids ? orderBookData?.bids : []
                                            :
                                            []} orderListType={(orderListType === "all" || orderListType === "sell") ? "sell" : "buy"}
                                            viewLimit={orderListType === "all" ? 8 : 16}
                                            pairDetails={null}
                                            reverse={"yes"}
                                        />

                                    </div>
                                </div>
                                {orderListType == "all" &&
                                    <div className="d-flex flex-row orderbook-table-heading-section">
                                        {pairDetails ? <div className="ps-2">
                                            <span className={`${pairDetails?.data?.lastTradePrice >= pairDetails?.prevPrice ? 'color-green-futures' : 'color-red-futures'}`}>
                                                {pairDetails?.data?.lastTradePrice}
                                            </span>
                                        </div> : ""}
                                    </div>
                                }
                                {orderListType == "all" &&
                                    <div className='trading-table-left-section progress-bar-bg-color '>
                                        <FuturesOrderBook
                                            orderListType={"buy"}
                                            viewOrderList={orderBookData?.bids}
                                            viewLimit={8}
                                            pairDetails={null}
                                            reverse={"no"}
                                            setPriceStatic={setPriceStatic}
                                        />
                                    </div>
                                }
                            </div>
                    }
                </div>
                :
                <FuturesRecent
                    tradeHistory={recentTradesData}
                    pairsLoader={pairsLoader}
                />
            }
        </div>
    </div>
    const createSubAccount = async (email) => {
        const params = {
            url: `${Config.V1_API_URL}user/create-sub-account`,
            method: "POST",
            body: { email: email }
        };
        const response = await makeRequestWithErrorMessage(params);
        if (response.status) {
            toast({ type: "success", message: response.message });
            setUserProfile();
            setFuturesBalance()
        }
        else {
            toast({ type: "error", message: response.message });
        }
    }
    return (
        <div className='bg-default text-white pb-0 overflow-hidden min-vh-100'>
            <NavbarOne setTheme={props.setTheme} theme={props.theme} />
            <Helmet>
                <link rel="canonical" href="https://uniticexchange.com/futures/trade/xbtusdtm" />
            </Helmet>
            <div className='container-fluid pt-2 setFontRoboto'>
                <div className="first-panel-futures">
                    <div className="first-div-futures">
                        <div className="marquee-container">

                            <div className={`futures-trade-top-section d-flex justify-content-start align-items-center overflow-auto ${isOverflowingSecond ? '' : ''}`} style={{ gap: '15px', whiteSpace: 'nowrap' }} ref={containerSecondRef}>
                                <div>
                                    <FuturesMenu handleNavigation={handleNavigation} pairsData={pairsData} pairsLoader={pairsLoader} pairName={pairName} setOrderBookData={setOrderBookData} updateFavPairs={updateFavPairs} formatSymbolName={formatSymbolName} pairDetails={pairDetails} favs={favs} />
                                </div>
                                <div>
                                    <p className={`${pairDetails?.data?.lastTradePrice >= pairDetails?.prevPrice ? 'color-green-futures' : 'color-red-futures'} fw-bold`} style={{ fontSize: '1rem' }}>{pairDetails.data?.lastTradePrice}</p>
                                    {/* <p className={`color-green-futures fw-bold`} style={{ fontSize: '1rem' }}>{pair?.last}</p> */}
                                </div>
                                <div className="rate-part">
                                    <p>Mark Price</p>
                                    <p>{pairDetails?.data?.markPrice}</p>
                                </div>
                                <div className="rate-part">
                                    <p>24h Volume</p>
                                    <p className="futures-warning">{pairDetails?.data?.volumeOf24h} {removeUSDTM(pairName)}</p>
                                </div>
                                <div className="rate-part">
                                    <p>Open Interest</p>
                                    <p>{formatNumber(pairDetails?.data?.openInterest)} lot</p>
                                </div>
                                <div className="rate-part">
                                    <p>Funding Rate</p>
                                    <p className={(pairDetails?.data?.fundingFeeRate * 100) >= 0 ? 'color-green-futures' : 'color-red-futures'}>{((pairDetails?.data?.fundingFeeRate || 0) * 100).toFixed(4)}%</p>
                                </div>
                                <div className="rate-part">
                                    <p>Funding Settlement</p>
                                    <p>{formattedTime}</p>
                                </div>

                            </div>
                            <div className={`futures-trade-top-section futures-first-part ${isOverflowing ? 'marquee-content' : ''}`} ref={containerRef} style={{ display: (!favs) ? 'none' : '' }}>
                                {
                                    favs && favs.map((dataSingle, index) =>
                                        <p key={index}><span onClick={() => handleNavigation(dataSingle?.symbol)} className="cursor-pointer">{formatSymbolName(dataSingle?.baseCurrency, dataSingle?.quoteCurrency)}</span> <span className={`${dataSingle?.lastTradePrice <= dataSingle?.markPrice ? 'color-green-futures' : 'color-red-futures'} `}>{dataSingle?.lastTradePrice}</span></p>
                                    )

                                }
                            </div>
                            {/* <div className="futures-trade-top-section option-part">

                                <div onClick={() => setFirstOptionPart(0)}>
                                    <h6 className={`${firstOptionPart !== 0 ? 'futures-secondary' : ''} cursor-pointer`}>Chart</h6>
                                </div>
                                <div onClick={() => setFirstOptionPart(1)}>
                                    <h6 className={`${firstOptionPart !== 1 ? 'futures-secondary' : ''} cursor-pointer`}>Info</h6>
                                </div>
                                <div onClick={() => setFirstOptionPart(2)}>
                                    <h6 className={`${firstOptionPart !== 2 ? 'futures-secondary' : ''} cursor-pointer`}>Trading Data</h6>
                                </div>
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-8 col-xxl-9">
                                <div >
                                    {firstOptionPart === 0 &&
                                        <div id="tradingviewwidgetFutures">
                                            {(pairName && !pairsLoader) ? <TradingViewFutures
                                                symbol={pairName}
                                                theme={props.theme}
                                                dec={pairDetails?.tickSize}
                                            /> : <LoaderUnitic />
                                            }
                                        </div>
                                    }
                                    {firstOptionPart === 1 ? <Info /> : firstOptionPart === 2 ? <TradingData /> : null}
                                </div>
                                {/* {window.innerWidth > 991 ? myOrdersDiv : orderBooksDiv} */}
                            </div>
                            {orderBooksDiv}
                        </div>

                    </div>
                    <div className="second-div-futures" style={{}}>
                        <div style={{ height: '42px' }}></div>
                        <FuturesTradeForm myProfile={myProfile} createSubAccount={createSubAccount} pairDetails={pairDetails} priceStatic={priceStatic} setPriceStatic={setPriceStatic} walletData={walletData} submitTrade={submitTradeWithAPI} handleClickOpenMarginMode={handleClickOpenMarginMode} handleClickOpenAdjust={handleClickOpenAdjust} crossMenu={crossMenu} xMenu={xMenu}
                            tpPx={tpPx}
                            setTpPx={setTpPx}
                            slPx={slPx}
                            setSlPx={setSlPx}
                            futuresBalance={futuresBalance}
                            fromSymbolName={fromSymbolName}
                            handleClickOpenOrderConfirmation={handleClickOpenOrderConfirmation}
                            trades={trades} getBaseAndTargetCurrency={getBaseAndTargetCurrency}
                            amount={amount || 0} setAmount={setAmount}
                        />
                        {/* <FuturesMarginRatio /> */}
                    </div>
                </div>
                <div>
                    {myOrdersDiv}
                </div>
            </div>
            {openOrderConfirmation && <OrderConfirmation open={openOrderConfirmation} handleClickOpen={handleClickOpenOrderConfirmation} handleClose={handleCloseOrderConfirmation} tradeParams={tradeParams} formatSymbolName={formatSymbolName} submitTrade={submitTradeWithAPI} amount={amount} setAmount={setAmount} />}
            {openModifyOrder && <ModifyOrder open={openModifyOrder} handleClickOpen={handleClickOpenModifyOrder} handleClose={handleCloseModifyOrder} />}
            {openModifyMargin && <ModifyMargin open={openModifyMargin} handleClickOpen={handleClickOpenModifyMargin} handleClose={handleCloseModifyMargin} />}
            {openAdjust && <AdjustLeverage open={openAdjust} handleClickOpen={handleClickOpenAdjust} handleClose={handleCloseAdjust} xMenu={xMenu} setXMenu={setXMenu} pairDetails={pairDetails} pairName={pairName} formatSymbolName={formatSymbolName} />}
            {openMarginMode && <MarginMode open={openMarginMode} handleClickOpen={handleClickOpenMarginMode} handleClose={handleCloseMarginMode} crossMenu={crossMenu} setCrossMenu={setCrossMenu} />}
        </div>
    );
};

export default FuturesTrade;