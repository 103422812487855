import React from "react";
import NavbarOne from "./separate/NavbarOne";
import Registration from "../PagesV2/UserAuthentication/Registration/Registration";
import { Helmet } from "react-helmet-async";

export default function SignUp(props) {

  return (
    <div>
      <NavbarOne setTheme={props.setTheme} theme={props.theme} />
      <Helmet>
        <link rel="canonical" href="https://uniticexchange.com/register" />
        <link rel="canonical" href="https://www.uniticexchange.com/register" />
        <meta name="description" content='btc price' />
<meta name="description" content='ethereum price' />
<meta name="description" content='market capitalization in cryptocurrency' />
<meta name="description" content='dogecoin price' />
<meta name="description" content='btc price prediction' />
<meta name="description" content='FIU Registered Crypto Exchange' />
<meta name="description" content='Indian best crypto exchange' />
<meta name="description" content='Indian crypto exchange' />
<meta name="description" content='Best crypto exchange in India for beginners' />
<meta name="description" content='Top Indian cryptocurrency exchanges' />
            </Helmet>
      <div className="bg-register d-flex justify-content-center align-items-center" style={{ minHeight: '91vh' }}>
        <Registration props={props} />
      </div>
    </div>
  );
}
