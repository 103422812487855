import React, { useState, useEffect } from "react";
import './Portfolio.css';
import {
    useNavigate
} from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { Modal,Form } from "react-bootstrap";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Legend,
} from "chart.js";
import {
    Box,
    Tab,
    Tabs,
} from '@mui/material'
import * as yup from 'yup';
import { Formik } from 'formik';

import { useContextData } from '../../core/context/index'
import { dateFormat, showEmail } from '../../core/helper/date-format';
import Config from "../../core/config/";
import { makeRequest, makeRequestWihActualStatus } from "../../core/services/v1/request";
import { toast } from "../../core/lib/toastAlert";
import { getCookie, setCookie } from "../../core/helper/cookie";

import WalletListV2 from "./WalletListV2";
import WalletListFutures from "./WalletListFutures";
import OrderDetails from "../../Pages/separate/futuresTrading/dialogs.js/OrderDetails";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Legend
);


const validationSchema = yup.object({
    username: yup
        .string('Please enter the username')
        .required('Username is required'),
});

const Portfolio = () => {
    const navigate = useNavigate();
    const { myProfile, setUserProfile, coindcxDecimal, futuresBalance } = useContextData();
    const initialValues = () => {
        return {
            username: myProfile && myProfile.username
        }
    }

    const [walletData, setWalletData] = useState([]);
    const [spotWalletData, setSpotWalletData] = useState([]);
    const [estimatedValue, setEstimatedValue] = useState({});
    const [isLoading, setisLoading] = useState(false);
    const [nicknameformOpen, setnicknameformOpen] = useState(false);
    const [balShow, setBalShow] = useState(getCookie("userBalShow") ? getCookie("userBalShow") : false);
    const [pendingOrderAmount, setPendingOrderAmount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [fundLoading, setFundLoading] = useState(false);
    const [openOrderDetails, setOpenOrderDetails] = useState(false);

    const handleClickOpenOrderDetails = () => {
        setOpenOrderDetails(true);
    };
    const handleCloseOrderDetails = () => {
        setOpenOrderDetails(false);
    };
    useEffect(() => {
        getWalletCurrency();
        getSpotWalletCurrency();
    }, []);

    async function getWalletCurrency() {
        // setLoading(true);
        const params = {
            url: `${Config.V2_API_URL}wallet/getWalletCurrency`,
            method: 'GET'
        };
        const response = await makeRequest(params);


        if (response) {
            const {
                status = false,
                data = [],
                estimateINR = 0,
                estimateUSD = 0,
            } = response;

            setWalletData(data);
        }
        else {
        }
    }

    async function getSpotWalletCurrency() {
        setLoading(true);
        const params = {
            url: `${Config.V2_API_URL}wallet/getSpotHoldings`,
            method: 'GET'
        };
        const response = await makeRequest(params);

        if (response) {
            const {
                status = false,
                data = [],
                estimateINR = 0,
                estimateUSD = 0,
            } = response;
            setSpotWalletData(data);
            setEstimatedValue({
                estimateINR,
                estimateUSD
            });
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
        else {
            setSpotWalletData([]);
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    }

    const onSubmit = async (values) => {
        setisLoading(true);
        const params = {
            url: `${Config.V1_API_URL}user/updateMyProfile`,
            method: 'POST',
            body: values
        }
        const response = (await makeRequest(params));
        setisLoading(false);
        let type = "error";
        if (response.status) {
            type = 'success';
            setnicknameformOpen(false);
            toast({ type, message: "Username Updated Successfully!" });
        } else {
            toast({ type, message: response.message });
        }
        setUserProfile();
        setisLoading(false);
    }

    const balShowHideCall = () => {

        if (balShow) {
            setCookie('userBalShow', false);
            setBalShow(false);
        }
        else {
            setCookie('userBalShow', true);
            setBalShow(true);
        }
    }

    const toNavigateFirstCurrency = (type = "") => {
        if (walletData && walletData.length > 0 && (type === "deposit" || type === "withdraw")) {
            let firstData = walletData.filter((e) => e.curnType == "Crypto");
            if (firstData && firstData.length > 0) {
                navigate("/" + type + "/crypto/" + firstData[0]._id);
            }
        }
    }

    const getDetails = async () => {
        setLoading(true);
        const payload = {
            userID: myProfile && myProfile?._id
        }
        const params2 = {
            url: `${Config.V1_API_URL}buycrypto/Userbuycryptoodrders`,
            method: 'POST',
            body: payload
        };
        const response2 = await makeRequest(params2);
        if (response2) {
            const {
                data2 = [],
            } = response2;


            const orderpending = response2.data.filter(element => element.status == 2);
            const newArray = []; 

            orderpending.forEach(obj => newArray.push(obj.amount));

            let sum = 0;

            for (let i = 0; i < newArray.length; i++) {
                sum += newArray[i];
            }
            setPendingOrderAmount(sum);
        }
        else {
        }

    }
    useEffect(() => {
        getDetails();
    }, [myProfile])
    
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }
    const [value, setValue] = React.useState(0)
    const handleChangeV2 = (event, newValue) => {
        setValue(() => newValue)
        //   setMarketCurrency(() => event.target?.firstChild?.data)
    }
    function TabPanel(props) {
        const { children, value, index, ...other } = props

        return (
            <div
                role='tabpanel'
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        )
    }
    const fundMove = async (amount, toAccount, currencyId = '640f648499ad61d2ff936aa2') => {
        setFundLoading(() => true)
        const from = toAccount === 'spot' ? 'futures' : 'spot'
        // console.log(toAccount)
        const params = {
            url: `${Config.V1_API_URL}futures-fund/create-fund?from=${from}&to=${toAccount}`,
            method: 'POST',
            body: { amount: amount, currencyId: currencyId }
        }
        const response = (await makeRequestWihActualStatus(params));
        let type = "error";
        if (response.status) {
            // type = 'success';
            // toast({ type, message: response.message });
            handleClickOpenOrderDetails()
        } else {
            toast({ type, message: response.message });
        }
        setFundLoading(() => false)
        setUserProfile();
        getSpotWalletCurrency()
    }
    return (
        <section className=' text-light pb-5'>
            <Helmet>
                <link rel="canonical" href="https://uniticexchange.com/portfolio" />
            </Helmet>
            <div className=' pb-5 pt-4'>

                <Box>
                    <Tabs
                        value={value}
                        onChange={handleChangeV2}
                        aria-label='basic tabs example'
                        sx={{
                            '& .MuiTabs-indicator': {
                                display: 'none',
                            },
                            '& .MuiTab-root': {
                                color: '#fff',
                            },
                            '& .MuiTab-root.Mui-selected': {
                                borderBottom: '3px solid #21CA50',
                                color: '#fff',
                                fontWeight: 'bold',
                            },
                        }}
                    >
                        <Tab label='Spot' {...a11yProps(0)} />
                        {(myProfile?.futuresSubAccount?.isActive && myProfile?.futuresSubAccount?.uid) ? <Tab label='Futures' {...a11yProps(1)} /> : null}
                    </Tabs>
                </Box>

                {
                    value === 0 ? <>
                        <div className="pb-3">
                            {(() => {
                                if (myProfile && myProfile.kycstatus == 3) {
                                    return (
                                        <div id="applicant_name_Enable_hide" className="mt-3">

                                            <div className="col-lg container pb-2">

                                                {" "}
                                                {/* <img
                src={(myProfile && myProfile.profileimage != "") ?  myProfile.profileimage : "https://cdn-icons-png.flaticon.com/512/149/149071.png"}
                alt="logo"
                className="img-user"
              /> */}
                                                {
                                                    myProfile && myProfile.username && myProfile && myProfile.username.charAt(0)
                                                        ?
                                                        <p className="custom-letterAvatar">{myProfile.username.charAt(0)}</p>
                                                        :
                                                        <img
                                                            src={(myProfile && myProfile.profileimage != "") ? myProfile.profileimage : "https://cdn-icons-png.flaticon.com/512/149/149071.png"}
                                                            alt="logo"
                                                            className="img-user"
                                                        />
                                                }

                                            </div>

                                            <div className="col-lg" >
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <p className="password-text-44">
                                                                {" "}

                                                                <b>Name : {(myProfile && myProfile.username) ? myProfile.username : "---"}</b>{" "}
                                                                
                                                                <span className="btn-muted">Level {(myProfile && myProfile.level) ? myProfile.level : "1"}</span>
                                                                
                                                            </p>
                                                        </div>
                                                        {
                                                            myProfile &&
                                                            <div className="col-lg">
                                                                <small className="d-block tittle-text">Two-Factor Authentication</small>
                                                                <small className={"d-block data-info " + (myProfile.tfaenablekey ? "color-green" : "color-red")}>{myProfile.tfaenablekey ? "Enabled" : "Disabled"}</small>
                                                            </div>}
                                                        <div className="col-lg">
                                                            <small className="d-block tittle-text">Identity Verification</small>
                                                            {myProfile && myProfile.kycstatus != undefined ?
                                                                <>
                                                                    {myProfile.kycstatus == 0 ? <small className="color-yellow d-block data-info">Waiting for KYC approval</small> :
                                                                        myProfile.kycstatus == 1 ? <small className="color-green d-block data-info">Verified</small> :
                                                                            myProfile.kycstatus == 2 ? <small className="color-red d-block data-info">Rejected</small> :
                                                                                myProfile.kycstatus == 3 && <small className="color-red d-block data-info">Not Verified</small>}
                                                                </> : <small className="color-green d-block data-info"></small>}
                                                        </div>
                                                        {myProfile && myProfile.lastLoginBy && myProfile.lastLoginBy.from && <div className="col-lg ">
                                                            <small className="d-block tittle-text">Last login by</small>
                                                            <small className="d-block data-info">
                                                                {myProfile.lastLoginBy.from} - {myProfile.lastLoginBy.from.toLowerCase() === "email" ? showEmail(myProfile.lastLoginBy.val) : myProfile.lastLoginBy.val}
                                                            </small>
                                                        </div>}
                                                        <div className="col-lg ">
                                                            <small className="d-block tittle-text">Last login time</small>
                                                            <small className="d-block data-info">
                                                                {dateFormat(myProfile && myProfile?.lastLoginTime)}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    )
                                } else {
                                    return (
                                        <p>{""}</p>
                                    )
                                }

                            })()}
                        </div>
                        <WalletListV2
                            tabName={"spot"}
                            balShow={balShow}
                            walletData={spotWalletData}
                            loading={loading}
                            coindcxDecimal={coindcxDecimal}
                            pendingOrderAmount={pendingOrderAmount}
                            setEstimatedValue={setEstimatedValue}
                            estimatedValue={estimatedValue}
                            balShowHideCall={balShowHideCall}
                            toNavigateFirstCurrency={toNavigateFirstCurrency}
                        />
                    </>
                        :
                        <>
                            <WalletListFutures
                                tabName={"futures"}
                                futuresBalance={futuresBalance}
                                balShow={balShow}
                                walletData={spotWalletData}
                                loading={loading}
                                coindcxDecimal={coindcxDecimal}
                                pendingOrderAmount={pendingOrderAmount}
                                setEstimatedValue={setEstimatedValue}
                                estimatedValue={estimatedValue}
                                balShowHideCall={balShowHideCall}
                                fundMove={fundMove}
                                fundLoading={fundLoading}
                                toNavigateFirstCurrency={toNavigateFirstCurrency} />
                        </>
                }


                <Modal show={nicknameformOpen} onHide={() => setnicknameformOpen(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Set Username</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={initialValues()}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                                onSubmit(values)
                            }}
                        >
                            {(formikProps) => {
                                const { values, touched, errors, handleChange, handleBlur, handleSubmit } = formikProps;

                                return (
                                    <Form >
                                        <div className="row  ">
                                            <div className="col-lg-12 ">
                                                <div className='mt-4'>
                                                    <input
                                                        class="form-control w-100 mt-1"
                                                        type="text"
                                                        label="Username"
                                                        id="username"
                                                        name='username'
                                                        autoComplete='off'
                                                        value={values.username != null ? values.username : myProfile?.username}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={touched.username && Boolean(errors.username)}
                                                        helperText={touched.username && errors.username}
                                                    />
                                                    {errors.username ? <small className="invalid-username error">{errors.username}</small> : null}
                                                    {myProfile && myProfile.username && <p className='color-white f-14 mt-4 mb-0'>Last edit time was {dateFormat(myProfile?.updatedOn)}.</p>}
                                                </div>
                                            </div>
                                            <div className='row mt-5'>
                                                <div className='col'>
                                                    <button
                                                        type="button"
                                                        className="btn text-white btn-col w-100 mt-4"
                                                        onClick={() => handleSubmit(values)}
                                                        disabled={isLoading}
                                                    >
                                                        Ok
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Modal.Body>
                </Modal>
                {openOrderDetails && <OrderDetails open={openOrderDetails} handleClickOpen={handleClickOpenOrderDetails} handleClose={handleCloseOrderDetails} />}
            </div>
        </section>
    );
};

export default Portfolio;