import React, { useEffect, useState } from "react";
import './Portfolio.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/system';
import { Form } from 'react-bootstrap';
import { ThemeProvider, createTheme } from '@mui/material';
import {
    useParams,
    useNavigate,
} from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';
import $ from "jquery";

import Config from "../../core/config/";
import { makeRequest } from "../../core/services/v1/request";

import NavbarOne from "../../Pages/separate/NavbarOne";
import Footer from "../../Pages/separate/Footer";

import { toast } from "../../core/lib/toastAlert";

import { dateFormat, shortAdrress } from '../../core/helper/date-format';
import { useContextData } from '../../core/context/index'
import { TbUpload } from 'react-icons/tb';
import { useFileUpload } from "use-file-upload";
import * as yup from 'yup';
import { useFormik } from 'formik';

import "../../assets/style.css";

const validationSchema = yup.object({
    amount: yup
        .number('Enter enter the amount')
        .required('Amount is required'),
    transactionNumber: yup
        .string('Please enter the transaction number')
        .required('Transaction number is required'),
    myfile1: yup
        .mixed().required('Attachment is required')
});

const StyledTableHeadRow = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontSize: "22px",
    fontWeight: "600",
    fontStyle: "normal",
    padding: "5px",
    paddingBottom: "16px",
    width: "auto !important",
    color: "white",
    textAlign: 'center'
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "27px",
    padding: "5px",
    width: "auto !important",
    color: "#C8C8C8",
    textAlign: 'center'
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontSize: "32px",
    fontWeight: "600",
    padding: "5px",
    fontStyle: "normal",
    lineHeight: "38px",
    width: "auto !important",
    color: "white",
    textAlign: 'center'
}));


const DepositFlat = (props) => {
    const navigate = useNavigate();
    let { currencyId } = useParams();
    const { siteSettings, myProfile, setUserProfile } = useContextData();
    const [files, selectFiles] = useFileUpload();

    const [particularCurrencyList, setparticularCurrencyList] = useState({});
    const [isLoading, setisLoading] = useState(false);
    const [depositfiatlist, setdepositfiatList] = useState({});
    const [adminBankDetails, setadminBankDetails] = useState([]);
    const [attachment, setattachment] = useState("");
    const [expanded, setExpanded] = React.useState(false);
    const [expanded2, setExpanded2] = React.useState(false);

    const handleExpansion = () => {
        setExpanded((prevExpanded) => !prevExpanded);
        setExpanded2(() => false)
    };

    const handleExpansion2 = () => {
        setExpanded2((prevExpanded) => !prevExpanded);
        setExpanded(() => false)
    };

    useEffect(() => {
        $(".crypto-workflow-section-close-button").click(function () {
            $(".deposit-second-section").hide();
        });
    }, []);

    useEffect(() => {
        getparticularCurrency_func();
        getAdminBankDetails();
        getHistory();
    }, []);
    useEffect(() => {
        if (myProfile && myProfile.email == "") {
            toast({ type: "error", message: "Please enable email verification" });
            navigate('/my/email-authenticator');
        }
        if (myProfile && myProfile.tfaenablekey == "") {
            toast({ type: "error", message: "Please completed 2FA to process withdraw." });
            navigate('/google-authenticator');
        }
        if (myProfile && (myProfile.kycstatus != 1 || myProfile?.kycOffline?.aml?.status != 1)) {
            toast({ type: "error", message: "Please complete the kyc details" });
            navigate('/my/identification');
        }
    }, [myProfile]);

    async function getparticularCurrency_func() {
        try {
            const params = {
                url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
                method: "POST",
                body: { currencySymbol: "BNB" }
            };
            const response = await makeRequest(params);
            if (response.status && response.data) {
                setparticularCurrencyList(response.data);
            }
        } catch (err) { }
    }

    const formik = useFormik({
        initialValues: {
            amount: '',
            transactionNumber: '',
            myfile1: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setisLoading(true);
            const formData = new FormData();
            let sizeFile = 1;
            let fileToUpload = values.myfile1;
            let fileName = 'attachment';
            let fileExtension = fileToUpload.name.split('?')[0].split('.').pop();
            formData.append('images[]', fileToUpload, fileName + '.' + fileExtension);
            const params = {
                url: `${Config.V1_API_URL}admin/fileUpload?sizeFile=${sizeFile}&&type="attachment"`,
                method: 'POST',
                body: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            const response = (await makeRequest(params));
            if (response.status) {
                const data = {
                    currencyId: currencyId,
                    amount: values.amount,
                    transactionNumber: values.transactionNumber,
                    attachment: response.message[0].location
                }
                const params = {
                    url: `${Config.V1_API_URL}wallet/depositFiat`,
                    method: 'POST',
                    body: data
                }
                const result = (await makeRequest(params));
                let type = 'error';
                if (result.status) {
                    type = 'success';
                    toast({ type, message: result.message });
                    formik.resetForm();
                    formik.values.myfile1 = "";
                    setattachment("")
                    getHistory();
                    setUserProfile();
                    setisLoading(false);
                } else {
                    setisLoading(false)
                    type = 'error';
                    const errmsg = result.message.split(' ');
                    if (errmsg[0] == 'KYC') {
                        navigate('/my/identification')
                    } else if (errmsg[0] == 'Bank') {
                        navigate('/my/payment')
                    }
                    setisLoading(false)
                    toast({ type, message: result.message });
                }
            }
            setisLoading(false)
        },
    });
    async function getAdminBankDetails() {
        try {
            const params = {
                url: `${Config.V1_API_URL}admin/getBankDetails`,
                method: 'GET'
            }
            const response = (await makeRequest(params));
            if (response.status) {
                setadminBankDetails(response.message)
            }
        } catch (err) { }
    }
    async function getHistory() {
        try {
            const value = { type: "Deposit", currencyType: "Fiat" }
            const params = {
                url: `${Config.V1_API_URL}wallet/getHistory`,
                method: 'POST',
                body: value
            }
            const response = (await makeRequest(params));
            if (response.status) {
                setdepositfiatList(response.data)
            }
        } catch (err) { }
    }
    function bonusAmount(trxn) {
        try {
            if (typeof trxn.bonusData == 'object' && trxn.bonusData.bonusGiven > 0) {
                return trxn.bonusData.bonusGiven.toFixed(trxn.currencyId.siteDecimal);
            } else {
                if (trxn.depositType == 'Pre Booking') {
                    return '-';
                } else {
                    return 'NA';
                }
            }
        } catch (err) { }
    }
    const theme = createTheme({
        palette: {
            mode: 'dark',
        },
    });
    return (
        <section >
            <NavbarOne
                setTheme={props.setTheme}
                theme={props.theme}
            />
            <div className='min-vh-100 bg-default text-light pb-5 pt-3'>
                <div className='container pb-5 pt-2'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h4 className='mb-0 controlFont' style={{ cursor: 'pointer' }} onClick={() => navigate("/my/dashboard")}><strong><ArrowBackIcon style={{ cursor: "pointer" }} /> Deposit Fiat</strong></h4>
                        <h5 className='mb-0 controlFont' style={{ cursor: 'pointer' }} onClick={() => navigate("/deposit/crypto/" + particularCurrencyList?._id)}><strong>Deposit Crypto <ArrowForwardIcon style={{ cursor: "pointer" }} /></strong></h5>

                    </div>
                    <div className='d-flex justify-content-between align-items-start mt-5 flex-column flex-md-row'>
                        <div className='withdraw-flat-box pb-5'>
                            <h5 className='text-center py-4 fw-bold'>
                                Fiat Deposit
                            </h5>
                            <div>
                                <form onSubmit={formik.handleSubmit}>
                                    <Form className='w-75  mx-auto'>
                                        <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                            <Form.Label >Enter Amount</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Amount"
                                                id="amount"
                                                name='amount'
                                                autoComplete='off'
                                                value={formik.values.amount}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.amount && Boolean(formik.errors.amount)}
                                                helperText={formik.touched.amount && formik.errors.amount} className='payment-withdraw' />
                                        </Form.Group>
                                        {formik.errors.amount ? <small className="invalid-amount error">{formik.errors.amount}</small> : null}
                                        <Form.Group className="mb-1 mt-2" controlId="exampleForm.ControlInput1">
                                            <Form.Label >Transaction number</Form.Label>
                                            <Form.Control type="text" placeholder="Transaction Number"
                                                id="transactionNumber"
                                                name='transactionNumber'
                                                autoComplete='off'
                                                value={formik.values.transactionNumber}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.transactionNumber && Boolean(formik.errors.transactionNumber)}
                                                helperText={formik.touched.transactionNumber && formik.errors.transactionNumber} className='payment-withdraw' />
                                        </Form.Group>
                                        {formik.errors.transactionNumber ? <small className="invalid-transactionNumber error">{formik.errors.transactionNumber}</small> : null}
                                        <Form.Group className="mb-1 mt-2" controlId="exampleForm.ControlInput1">
                                            <Form.Label >Upload Your transaction</Form.Label>
                                            <p>
                                                <img className='payment-qrcode-optional-image' src={files?.source || "/assets/cloud+upload+file.png"} alt="preview" /></p>
                                            <button type="button" className='payment-qrcode-optional-button mt-3' name="myfile1"
                                                onClick={() => selectFiles({ accept: "image/*" }, ({ name, size, source, file }) => {
                                                    // console.log("Files Selected", { name, size, source, file });
                                                    setattachment(file);
                                                    formik.values.myfile1 = file;
                                                    formik.errors.myfile1 = ''
                                                })}
                                            >
                                                <TbUpload />Upload
                                            </button>
                                        </Form.Group>
                                        {formik.errors.myfile1 ? <small className="invalid-myfile1 error">{formik.errors.myfile1}</small> : null}
                                        <p className='text-warning'>Upload  screenshot</p>

                                    </Form>

                                    <div className='mx-auto text-center mt-3'>
                                        <button className='btn-profile shadow px-3' type="submit" disabled={isLoading} style={{ width: 'auto' }} >Submit Deposit</button>
                                    </div>

                                </form>
                            </div>
                        </div>

                        <div className='width-text mt-5 mt-md-0'>
                            <h3>The amount would be transferred to</h3>
                            <p style={{
                                color: '#C8C8C8',
                                fontSize: '16px',
                                fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"'
                            }}>Fiat Withdraw can only be done to the default Account. Whereas
                                deposits can be done from any of the linked bank accounts</p>
                            <div className="my-3">
                                <ThemeProvider theme={theme}>
                                    <Accordion
                                        expanded={expanded}
                                        onChange={handleExpansion}
                                        slots={{ transition: Fade }}
                                        slotProps={{ transition: { timeout: 400 } }}
                                        className="mb-3"
                                        sx={{
                                            '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
                                            '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
                                            '&.MuiAccordion-root': { backgroundColor: '#535151', borderRadius: '16px' }
                                        }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <Typography>Deposit INR Less Than 5 Lakhs</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{
                                            '&.MuiAccordionDetails-root': {
                                                marginTop: '-15px'
                                            }
                                        }}>
                                            <Typography sx={{ lineHeight: '2rem' }}>Deposit Type : <span className="text-custom-high">{adminBankDetails[0]?.type}</span></Typography>
                                            <Typography sx={{ lineHeight: '2rem' }}>Minimum Deposit Amount: <span className="text-custom-high">{siteSettings?.minDeposit} INR</span></Typography>
                                            <Typography sx={{ lineHeight: '2rem' }}>Account Name: <span className="text-custom-high">{adminBankDetails[0]?.accountName}</span></Typography>
                                            <Typography sx={{ lineHeight: '2rem' }}>Account Number: <span className="text-custom-high">{adminBankDetails[0]?.accountNumber}</span></Typography>
                                            <Typography sx={{ lineHeight: '2rem' }}>Swift / IFSC Code : <span className="text-custom-high">{adminBankDetails[0]?.swiftIFSCCode}</span></Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion
                                        expanded={expanded2}
                                        onChange={handleExpansion2}
                                        slots={{ transition: Fade }}
                                        slotProps={{ transition: { timeout: 400 } }}
                                        sx={{
                                            '& .MuiAccordion-region': { height: expanded2 ? 'auto' : 0 },
                                            '& .MuiAccordionDetails-root': { display: expanded2 ? 'block' : 'none' },
                                            '&.MuiAccordion-root': { backgroundColor: '#535151', borderRadius: '16px' }
                                        }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2-content"
                                            id="panel2-header"
                                        >
                                            <Typography>Deposit INR More Than 5 Lakhs</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{
                                            '&.MuiAccordionDetails-root': {
                                                marginTop: '-15px'
                                            }
                                        }}>
                                            <Typography sx={{ lineHeight: '2rem' }}>Deposit Type : <span className="text-custom-high">{adminBankDetails[1]?.type}</span></Typography>
                                            <Typography sx={{ lineHeight: '2rem' }}>Minimum Deposit Amount: <span className="text-custom-high">5,00,000 INR</span></Typography>
                                            <Typography sx={{ lineHeight: '2rem' }}>Account Name: <span className="text-custom-high">{adminBankDetails[1]?.accountName}</span></Typography>
                                            <Typography sx={{ lineHeight: '2rem' }}>Account Number: <span className="text-custom-high">{adminBankDetails[1]?.accountNumber}</span></Typography>
                                            <Typography sx={{ lineHeight: '2rem' }}>Swift / IFSC Code : <span className="text-custom-high">{adminBankDetails[1]?.swiftIFSCCode}</span></Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </ThemeProvider>
                            </div>
                            <li style={{
                                color: '#C8C8C8',
                                fontSize: '16px',
                                fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"'
                            }}>UPI bank transfers, IMPS, NEFT & RTGS are allowed for this account.</li>
                            <li style={{
                                color: '#C8C8C8',
                                fontSize: '16px',
                                fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"'
                            }}>UPI Apps like gPay, PhonePe, Paytm are available.</li>
                            <li style={{
                                color: '#C8C8C8',
                                fontSize: '16px',
                                fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"'
                            }}>INR Deposit and Withdrawal take upto 24 hours to Process.</li>
                        </div>

                    </div>

                    <div className='mt-5 pt-4'>
                        <h4 className='pb-4 ps-3 ps-md-5 fw-bold'>Recent Withdraw <span className='px-3 py-2 ms-1' style={{ color: '#21CA50', fontWeight: '700', fontSize: '16px', background: '#646363', borderRadius: '10px' }}>Claim Withdraw</span></h4>
                        <TableContainer>
                            <Table sx={{ minWidth: window.innerWidth < 700 ? 1000 : 100, justifyContent: 'center' }} aria-label="simple table">
                                <StyledTableHead>
                                    <TableRow>
                                        <StyledTableHeadRow>Time</StyledTableHeadRow>
                                        <StyledTableHeadRow>Type</StyledTableHeadRow>
                                        <StyledTableHeadRow>Asset</StyledTableHeadRow>
                                        <StyledTableHeadRow>Amount</StyledTableHeadRow>
                                        <StyledTableHeadRow>Destination</StyledTableHeadRow>
                                        <StyledTableHeadRow>Txn.id</StyledTableHeadRow>
                                        <StyledTableHeadRow>Status</StyledTableHeadRow>
                                    </TableRow>
                                </StyledTableHead>
                                <TableBody>
                                    {depositfiatlist?.length > 0 && depositfiatlist.map((row, i) => (
                                        <TableRow key={i}>
                                            <StyledTableCell component="th" scope="row">{dateFormat(row.createdDate)}</StyledTableCell>
                                            <StyledTableCell>{row.type}</StyledTableCell>
                                            <StyledTableCell><span className='tb-img'><img src={row.currencyId.image} alt="Unitic" style={{ width: "20px", height: "20px" }} className="img-fluid img-res" /> </span>&nbsp;{row.currencyId.currencySymbol}</StyledTableCell>
                                            <StyledTableCell>{row.amount}</StyledTableCell>
                                            <StyledTableCell>{bonusAmount(row)}</StyledTableCell>
                                            <StyledTableCell>{shortAdrress(row.txnId != '' ? row.txnId : '-')}</StyledTableCell>
                                            <StyledTableCell>{row.status == 0 ? 'Pending' : row.status == 1 ? "Completed" : row.status == 2 ? 'Rejected' : 'Processing'}</StyledTableCell>
                                        </TableRow>
                                    ))}
                                    {depositfiatlist?.length == 0 && <TableRow >
                                        <StyledTableCell component="th" scope="row"></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell>   <img
                                            className="no-record-image"
                                            src="/assets/no-re.png"
                                            alt="no-record"
                                        />
                                            <br />
                                            <span className="text-center">No Records Found</span></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                    </TableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                </div>
            </div>
            <Footer />
        </section>
    );
};

export default DepositFlat;