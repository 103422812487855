import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Form, InputGroup } from 'react-bootstrap';
import './Identification.css';
import Badge from '@mui/material/Badge';
import CloseIcon from '@mui/icons-material/Close';

import { FileUploader } from "react-drag-drop-files";
import '../Profile/Profile.css';
import KycStatusComp from "../../../Pages/separate/kycStatusComp";
import { isEmpty } from "../../../core/helper/common";
import {
  useNavigate
} from "react-router-dom";

import { useContextData } from '../../../core/context/';
import { toast } from "../../../core/lib/toastAlert";
import Config from "../../../core/config/";
import { makeRequest } from "../../../core/services/v1/request";

const fileTypes = ["JPEG", "PNG", "GIF", "JPG"];

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `none`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '& .Mui-expanded': {

    borderRadius: '17px 17px 0px 0px',
  },
  marginBottom: '24px',
  backgroundColor: 'transparent'
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? '#535151'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  height: '65px',
  borderRadius: '17px',

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },

  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: 'none',
  backgroundColor: '#535151',
  borderRadius: '0px 0px 17px 17px',
}));

const Identification = (props) => {
  const [expanded, setExpanded] = React.useState('');
  const [previewImage, setPreviewImage] = useState();
  const [CurrentFile, setCurrentFile] = useState();
  const navigate = useNavigate();
  const handleChange = (panel) => (event, newExpanded) => {

    if (panel === "panel1") {
      if (
        (myProfile.kycstatus === 2 || myProfile.kycstatus === 3)
        &&
        (
          myProfile.kycStatusDetail.pan.status === 2 || myProfile.kycStatusDetail.pan.status === 3
        ) && (
          !clickAccCol.collapseOfPV
        )
      ) {
        setExpanded(newExpanded ? panel : false);
        setClickAccCol({ collapseOfPV: true })
      }
    }
    else if (panel === "panel2") {
      if (
        (myProfile.kycstatus === 2 || myProfile.kycstatus === 3)
        &&
        // myProfile.kycStatusDetail.pan.status === 1 &&
        (myProfile.kycStatusDetail.aadhaar.status === 2 || myProfile.kycStatusDetail.aadhaar.status === 3)
      ) {

        setExpanded(newExpanded ? panel : false);
        setClickAccCol({ collapseOfAV: true })
      }
    }
    else if (panel === "panel3") {
      if ((myProfile.kycstatus === 2 || myProfile.kycstatus === 3) && (myProfile.kycStatusDetail.selfie.status === 2 || myProfile.kycStatusDetail.selfie.status === 3) && !clickAccCol.collapseOfSV) {
        setExpanded(newExpanded ? panel : false);
        setClickAccCol({ collapseOfSV: true })
      }
    }
    else if (panel === "panel4") {
      if ((myProfile.kycStatusDetail.aml.status === 2 || myProfile.kycStatusDetail.aml.status === 3)) {
        setExpanded(newExpanded ? panel : false);
        setClickAccCol({ collapseOfSV: true })
      }
    }
  };

  const theme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const selectFile = (event) => {
    setCurrentFile(event.target.files[0]);
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
  };

  const {  myProfile, setUserProfile } = useContextData();

  const [onlineKYCPan, setonlineKYCPan] = useState({
    pan_number: "",
    step: "getNumber"
  });
  const [offlineKYCPan, setofflineKYCPan] = useState({
    pan_number: "",
    pan_name: "",
    step: "getNumber"
  });

  const [onlineKYCAadhaar, setonlineKYCAadhaar] = useState({
    aadhaar_number: "",
    otp: "",
    step: "getNumber"
  });
  const [onlineKYCAml, setonlineKYCAml] = useState({
    aml_number: "",
    otp: "",
    step: "getNumber"
  });
  const [offlineKYCAadhaar, setofflineKYCAadhaar] = useState({
    aadhaar_number: "",
    aadhaar_name: "",
    aadhaar_address: "",
    aadhaar_pincode: "",
    step: "getNumber"
  });
  const [offlineKYCAml, setofflineKYCAml] = useState({
    occupation: "",
    sourceOfIncome: "",
    annualIncome: "",
    tradingExperience: "",
    purpose: "",
    checked: true,
    step: "getNumber"
  });

  const [isLoading, setisLoading] = useState(false);
  const [isLoading_sec, setisLoading_sec] = useState(false);
  const [onlineKYCSelfieUpl, setOnlineKYCSelfieUpl] = useState(false);

  const [tabnameNewSave, settabnameNewSave] = useState("");
  const [KycMode, setKycMode] = useState("Offline");

  const [filesOnlineSelfie, setfilesOnlineSelfie] = useState({});
  const [kycDocsOnlineSelfie, setkycDocsOnlineSelfie] = useState("");

  const [filesOffline, setfilesOffline] = useState({});

  const [kycDocsOfflinePan, setkycDocsOfflinePan] = useState("");
  const [kycDocsOfflineAadhaar, setkycDocsOfflineAadhaar] = useState("");
  const [kycDocsOfflineAadhaarBack, setkycDocsOfflineAadhaarBack] = useState("");
  const [kycDocsOfflineSelfie, setkycDocsOfflineSelfie] = useState("");

  const [clickAccCol, setClickAccCol] = useState({ no: false });

  const [files1, setfiles1] = useState({});
  const [kycUserType, setkycUserType] = useState("");

  const [KYCSteps, setKYCSteps] = useState({
    offline: {
      "IND": {
        step1: {
          header: "Pan verification",
          field1: {
            label: "Enter pan number",
            errMsg: {
              required: "Please enter your pan number"
            }
          },
          field2: {
            label: "Enter pan name",
            errMsg: {
              required: "Please enter your pan name"
            }
          },
          field3: {
            label: "Select pan image",
            errMsg: {
              required: "Please select pan image"
            }
          }
        },
        step2: {
          header: "Aadhaar verification",
          field1: {
            label: "Enter aadhaar number",
            errMsg: {
              required: "Please enter your aadhaar number"
            }
          },
          field2: {
            label: "Enter aadhaar name",
            errMsg: {
              required: "Please enter your aadhaar name"
            }
          },
          field3: {
            label: "Enter your address",
            errMsg: {
              required: "Please enter your address"
            }
          },
          field4: {
            label: "Enter your pincode",
            errMsg: {
              required: "Please enter your pincode"
            }
          },
          field5: {
            label: "Aadhaar Front side",
            errMsg: {
              required: "Please select front side image"
            }
          },
          field6: {
            label: "Aadhaar Back side",
            errMsg: {
              required: "Please select back side image"
            }
          },
        },
        step3: {
          header: "Selfie verification",
          errMsg: {
            required: "Please select your selfie image"
          }
        },
        step4: {
          header: "AML verification",
          field1: {
            label: "Occupation *",
            errMsg: {
              required: "Please select occupation"
            }
          },
          field2: {
            label: "Source of Income *",
            errMsg: {
              required: "Please select source of income"
            }
          },
          field3: {
            label: "Annual Income *",
            errMsg: {
              required: "Please select annual income"
            }
          },
          field4: {
            label: "Trading Experience *",
            errMsg: {
              required: "Please select trading experience"
            }
          },
          field5: {
            label: "Purpose *",
            errMsg: {
              required: "Please select purpose"
            }
          },
          field6: {
            label: "Acknowledgment *",
            errMsg: {
              required: "Please check acknowledgment"
            }
          },
        },
      },
      "International": {
        step1: {
          header: "Passport verification",
          field1: {
            label: "Enter verification number",
            errMsg: {
              required: "Please enter verification number"
            }
          },
          field2: {
            label: "Enter verification name",
            errMsg: {
              required: "Please enter verification name"
            }
          }
        },
        step2: {
          header: "Identification verification",
          field1: {
            label: "Enter identification number",
            errMsg: {
              required: "Please enter identification name"
            }
          },
          field2: {
            label: "Enter identification name",
            errMsg: {
              required: "Please enter identification name"
            }
          },
          field3: {
            label: "Enter your address",
            errMsg: {
              required: "Please enter address"
            }
          },
          field4: {
            label: "Enter your pincode",
            errMsg: {
              required: "Please enter pincode"
            }
          },
          field5: {
            label: "Identification Front side",
            errMsg: {
              required: "Please select front side identification image"
            }
          },
          field6: {
            label: "Identification Back side",
            errMsg: {
              required: "Please select back side identification image"
            }
          },
        },
        step3: {
          header: "Selfie verification",
          errMsg: {
            required: "Please select selfie image"
          }
        },
        step4: {
          header: "AML verification",
          field1: {
            label: "Occupation *",
            errMsg: {
              required: "Please select occupation"
            }
          },
          field2: {
            label: "Source of Income *",
            errMsg: {
              required: "Please select source of income"
            }
          },
          field3: {
            label: "Annual Income *",
            errMsg: {
              required: "Please select annual income"
            }
          },
          field4: {
            label: "Trading Experience *",
            errMsg: {
              required: "Please select trading experience"
            }
          },
          field5: {
            label: "Purpose *",
            errMsg: {
              required: "Please select purpose"
            }
          },
          field6: {
            label: "Acknowledgment *",
            errMsg: {
              required: "Please check acknowledgment"
            }
          },
        },
      },
    }
  });

  function handleChangesOffline(event, name = "") {
    const file = event[0];
    if (file) {
      let files1_copy = Object.assign({}, filesOffline);
      files1_copy[name] = file;
      setfilesOffline(files1_copy)

      var reader = new FileReader();
      reader.onload = function (e) {
        if (name == "pan") {
          setkycDocsOfflinePan(e.target.result);
        }
        else if (name == "aadhaar") {
          setkycDocsOfflineAadhaar(e.target.result);
        }
        else if (name == "aadhaarBack") {
          setkycDocsOfflineAadhaarBack(e.target.result);
        }
        else if (name == "selfie") {
          setkycDocsOfflineSelfie(e.target.result);
        }
      }
      reader.readAsDataURL(file);
    }
  }

  function handleChangesOnlineSelfie(event, name) {
    const file = event[0];
    if (file) {
      let files1_copy = Object.assign({}, files1);
      files1_copy[name] = file;
      setfilesOnlineSelfie(files1_copy);

      setOnlineKYCSelfieUpl(false);

      var reader = new FileReader();
      reader.onload = function (e) {
        setkycDocsOnlineSelfie(e.target.result);
      }
      reader.readAsDataURL(file);
    }
  }

  function onlineKYCAadhaar_handleChange(event) {
    const {
      name = "",
      value = ""
    } = event.target;

    if (name == 'aadhaar_number' || name == 'otp') {
      let onlineKYCAadhaarCopy = Object.assign({}, onlineKYCAadhaar);
      onlineKYCAadhaarCopy[name] = value;
      setonlineKYCAadhaar(onlineKYCAadhaarCopy);
    }
  }

  function offlineKYCAadhaar_handleChange(event) {
    const {
      name = "",
      value = ""
    } = event.target;

    if (name == 'aadhaar_number' || name == 'aadhaar_name' || name == 'aadhaar_address' || name == 'aadhaar_pincode') {
      let offlineKYCAadhaarCopy = Object.assign({}, offlineKYCAadhaar);
      offlineKYCAadhaarCopy[name] = value;
      setofflineKYCAadhaar(offlineKYCAadhaarCopy);
    }
  }

  function onlineKYCPan_handleChange(event) {
    const {
      name = "",
      value = ""
    } = event.target;

    if (name == 'pan_number') {
      let onlineKYCPanCopy = Object.assign({}, onlineKYCPan);
      onlineKYCPanCopy[name] = value;
      setonlineKYCPan(onlineKYCPanCopy);
    }
  }

  function offlineKYCPan_handleChange(event) {
    const {
      name = "",
      value = ""
    } = event.target;

    if (name == 'pan_number' || name == 'pan_name') {
      let offlineKYCPanCopy = Object.assign({}, offlineKYCPan);
      offlineKYCPanCopy[name] = value;
      setofflineKYCPan(offlineKYCPanCopy);
    }
  }

  const offlineKYCPanSubmit = async (type = "submit") => {
    if (KYCSteps.offline[kycUserType].step1.field1) {
      if (type == "submit") {
        const step1 = KYCSteps.offline[kycUserType].step1;
        // if(isEmpty(offlineKYCPan.pan_number)) {
        //   toast({ type, message: step1.field1.errMsg.required });
        // }
        // else if(isEmpty(offlineKYCPan.pan_name)) {
        //   toast({ type, message: step1.field2.errMsg.required });
        // }
        // else {
        if (offlineKYCPan.step == "getNumber") {
          let formData = new FormData();
          for (var key in filesOffline) {
            if (key == "pan") {
              let fileToUpload = filesOffline[key];
              let fileName = key;
              let fileExtension = fileToUpload.name.split('?')[0].split('.').pop();
              formData.append('images[]', fileToUpload, fileName + '.' + fileExtension);
            }
          }

          formData.append('type', "panVerify");
          formData.append('pan_number', offlineKYCPan.pan_number);
          formData.append('pan_name', offlineKYCPan.pan_name);

          const params = {
            url: `${Config.V2_API_URL}kyc/offline/verify/pan`,
            method: 'POST',
            body: formData,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }

          setisLoading(true);
          const response = await makeRequest(params);
          setisLoading(false);

          let type = 'error';
          if (response.status) {
            type = 'success';
            setUserProfile();
          }
          toast({ type, message: response.message });
        }
        // }
      }
    }
  }

  const onlineKYCPanSubmit = async (type = "submit") => {
    if (type == "submit") {
      if (onlineKYCPan.pan_number == "") {
        toast({ type, message: "Please enter your pan number" });
      }
      else {
        if (onlineKYCPan.step == "getNumber") {
          const payload = {
            type: "panVerify",
            pan_number: onlineKYCPan.pan_number
          }
          const params = {
            url: `${Config.V2_API_URL}kyc/online/verify/pan`,
            method: 'POST',
            body: payload,
          }
          setisLoading(true);
          const response = await makeRequest(params);
          setisLoading(false);
          if (response.status) {
            const type = 'success';
            toast({ type, message: response.message });
            setUserProfile();
          } else {
            const type = 'error';
            toast({ type, message: response.message });
          }
        }
      }
    }
  }

  const offlineKYCAadhaarSubmit = async (type = "submit") => {
    if (type == "submit") {
      const step2 = KYCSteps.offline[kycUserType].step2;
      if (isEmpty(offlineKYCAadhaar.aadhaar_number)) {
        toast({ type, message: step2.field1.errMsg.required });
      }
      else if (isEmpty(offlineKYCAadhaar.aadhaar_name)) {
        toast({ type, message: step2.field2.errMsg.required });
      }
      else if (isEmpty(offlineKYCAadhaar.aadhaar_address)) {
        toast({ type, message: step2.field3.errMsg.required });
      }
      else if (isEmpty(offlineKYCAadhaar.aadhaar_pincode)) {
        toast({ type, message: step2.field4.errMsg.required });
      }
      else {
        if (offlineKYCAadhaar.step == "getNumber") {
          let formData = new FormData();
          for (var key in filesOffline) {
            if (key == "aadhaar" || key == "aadhaarBack") {
              let fileToUpload = filesOffline[key];
              let fileName = key;
              let fileExtension = fileToUpload.name.split('?')[0].split('.').pop();
              formData.append('images[]', fileToUpload, fileName + '.' + fileExtension);
            }
          }

          formData.append('type', "aadhaarVerify");
          formData.append('aadhaar_number', offlineKYCAadhaar.aadhaar_number);
          formData.append('aadhaar_name', offlineKYCAadhaar.aadhaar_name);
          formData.append('aadhaar_address', offlineKYCAadhaar.aadhaar_address);
          formData.append('aadhaar_pincode', offlineKYCAadhaar.aadhaar_pincode);

          const params = {
            url: `${Config.V2_API_URL}kyc/offline/verify/aadhaar?type=aadhaarVerify`,
            method: 'POST',
            body: formData,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
          setisLoading(true);
          const response = await makeRequest(params);
          // console.log("response--", response)
          setisLoading(false);
          let type = 'error';
          if (response.status) {
            type = 'success';
            setUserProfile();
          }
          toast({ type, message: response.message });
        }
      }
    }
  }

  const onlineKYCAadhaarSubmit = async (type = "submit") => {
    if (type == "submit") {
      if (onlineKYCAadhaar.aadhaar_number == "") {
        toast({ type, message: "Please enter your aadhaar number" });
      }
      else {
        if (onlineKYCAadhaar.step == "getNumber") {
          const payload = {
            type: "generateOtp",
            aadhaar_number: onlineKYCAadhaar.aadhaar_number
          }
          const params = {
            url: `${Config.V2_API_URL}kyc/online/verify/aadhaar`,
            method: 'POST',
            body: payload,
          }
          setisLoading(true);
          const response = await makeRequest(params);
          setisLoading(false);
          // console.log({ response });
          if (response.status) {
            const type = 'success';
            toast({ type, message: response.message });
            let onlineKYCAadhaarCopy = Object.assign({}, onlineKYCAadhaar);
            onlineKYCAadhaarCopy["step"] = "generateOtp";
            setonlineKYCAadhaar(onlineKYCAadhaarCopy);
          } else {
            const type = 'error';
            toast({ type, message: response.message });
          }
        }
        else if (onlineKYCAadhaar.step == "generateOtp") {
          if (onlineKYCAadhaar.otp == "") {
            toast({ type, message: "Please enter valid OTP" });
          }
          else {
            const payload = {
              type: "submitOtp",
              aadhaarOtp: onlineKYCAadhaar.otp
            }
            const params = {
              url: `${Config.V2_API_URL}kyc/online/verify/aadhaar`,
              method: 'POST',
              body: payload
            }
            setisLoading(true);
            const response = await makeRequest(params);
            setisLoading(false);
            // console.log({ response });
            if (response.status) {
              const type = 'success';
              toast({ type, message: response.message });
              setUserProfile();
              let onlineKYCAadhaarCopy = Object.assign({}, onlineKYCAadhaar);
              onlineKYCAadhaarCopy["step"] = "submitOtp";
              setonlineKYCAadhaar(onlineKYCAadhaarCopy);
            } else {
              const type = 'error';
              toast({ type, message: response.message });
            }
          }
        }
      }
    }
    else {
      let onlineKYCAadhaarCopy = Object.assign({}, onlineKYCAadhaar);
      onlineKYCAadhaarCopy["step"] = "getNumber";
      setonlineKYCAadhaar(onlineKYCAadhaarCopy);
    }
  }
  const offlineKYCAmlSubmit = async (type = "submit") => {
    if (type == "submit") {
      const step4 = KYCSteps.offline[kycUserType].step4;
      if (isEmpty(offlineKYCAml.occupation)) {
        toast({ type, message: step4.field1.errMsg.required });
      }
      else if (isEmpty(offlineKYCAml.sourceOfIncome)) {
        toast({ type, message: step4.field2.errMsg.required });
      }
      else if (isEmpty(offlineKYCAml.annualIncome)) {
        toast({ type, message: step4.field3.errMsg.required });
      }
      else if (isEmpty(offlineKYCAml.tradingExperience)) {
        toast({ type, message: step4.field4.errMsg.required });
      }
      else if (isEmpty(offlineKYCAml.purpose)) {
        toast({ type, message: step4.field5.errMsg.required });
      }
      else if (isEmpty(offlineKYCAml.checked)) {
        toast({ type, message: step4.field6.errMsg.required });
      }
      else {
        if (offlineKYCAadhaar.step == "getNumber") {
          let formData = new FormData();
          formData.append('type', "amlVerify");
          formData.append('occupation', offlineKYCAml.occupation);
          formData.append('sourceOfIncome', offlineKYCAml.sourceOfIncome);
          formData.append('annualIncome', offlineKYCAml.annualIncome);
          formData.append('tradingExperience', offlineKYCAml.tradingExperience);
          formData.append('purpose', offlineKYCAml.purpose);
          formData.append('checked', offlineKYCAml.checked);

          const params = {
            url: `${Config.V2_API_URL}kyc/offline/verify/aml?type=amlVerify`,
            method: 'POST',
            body: formData,
            headers: {
              // 'Content-Type': 'multipart/form-data'
            }
          }
          setisLoading(true);
          const response = await makeRequest(params);
          // console.log("response--", response)
          setisLoading(false);
          let type = 'error';
          if (response.status) {
            type = 'success';
            setUserProfile();
          }
          toast({ type, message: response.message });
        }
      }
    }
  }
  const offlineKYCSelfieSubmit = async (target = "submit") => {
    let formData = new FormData();
    for (var key in filesOffline) {
      if (key == "selfie") {
        let fileToUpload = filesOffline[key];
        let fileName = key;
        let fileExtension = fileToUpload.name.split('?')[0].split('.').pop();
        formData.append('images[]', fileToUpload, fileName + '.' + fileExtension);
      }
    }

    const params = {
      url: `${Config.V2_API_URL}kyc/offline/verify/selfie?type=selfieVerify`,
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    setisLoading(true);
    const response = await makeRequest(params);
    setisLoading(false);
    let type = 'error';
    setUserProfile();
    if (response.status) {
      type = 'success';
    }
    toast({ type, message: response.message });
  }

  const onlineKYCSelfieSubmit = async (subType = "selfieSubmit") => {
    if (kycDocsOnlineSelfie) {
      let formData = new FormData();
      for (var key in filesOnlineSelfie) {
        let fileToUpload = filesOnlineSelfie[key];
        let fileName = key;
        let fileExtension = fileToUpload.name.split('?')[0].split('.').pop();
        formData.append('images[]', fileToUpload, fileName + '.' + fileExtension);
      }
      alert(subType, "subType");
      const params = {
        url: `${Config.V2_API_URL}kyc/online/verify/selfie?type=` + subType,
        method: 'POST',
        body: formData,
        headers: {
          Accept: "application/json",
          'Content-Type': 'multipart/form-data'
        }
      }
      setisLoading(true);
      const response = await makeRequest(params);
      setisLoading(false);
      // if(subType == "checkSelfieVerify") {|
      if (response.uploadedChk) {
        setOnlineKYCSelfieUpl(true);
      }
      else {
        setOnlineKYCSelfieUpl(false);
      }
      let type = 'error';
      if (response.status) {
        type = 'success';
        setUserProfile();
      }
      toast({ type, message: response.message });
    }
    else {
      toast({ type: "error", message: "Please choose your selfie image to submit for selfie verification" });
      setisLoading(false);
    }
  }

  const onlineKYCSelfieSubmitToAdmin = async () => {
    const params = {
      url: `${Config.V2_API_URL}kyc/online/verify/selfie/fromAdmin`,
      method: 'GET'
    }
    setisLoading_sec(true);
    const response = await makeRequest(params);
    setisLoading_sec(false);
    let type = 'error';
    if (response.status) {
      type = 'success';
      setUserProfile();
    }
    toast({ type, message: response.message });
  }

  useEffect(() => {
    if (props && props.tabnameNew == "identification" && tabnameNewSave != props.tabnameNew) {
      settabnameNewSave(props.tabnameNew)
      setUserProfile();
    }
  }, [props]);

  useEffect(() => {
    if (props && props.tabnameNew == "identification") {
      if (myProfile && myProfile._id) {
        if (isEmpty(myProfile.email) === false && isEmpty(myProfile.email) === false) {
          if (myProfile && myProfile.kycMode && myProfile.kycMode != KycMode) {
            setKycMode(myProfile.kycMode);
          }
        }
        setkycUserType(myProfile.country == "IND" ? myProfile.country : "International");
      }
    }
    // console.log('myProfile----', myProfile)
  }, [myProfile]);
  return (

    <section>
      <div className=' py-5'>
        <div className='mb-3'>
          <h4><strong><ArrowBackIcon onClick={() => navigate(-1)} style={{ cursor: "pointer" }} /> Identification</strong></h4>
          <p className='pb-2 pt-2 fs-4' style={{ color: "#10EDB6", fontWeight: 600 }}>KYC Verification</p>
        </div>
        {KYCSteps && kycUserType != "" ?
          <div>
            {myProfile && myProfile.kycOffline && <>
              <ThemeProvider theme={theme}>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <div className='d-flex justify-content-between align-items-center w-100'>
                      <Typography sx={{ fontSize: { xs: '0.85rem', sm: '1rem' } }}>{KYCSteps.offline[kycUserType].step1.header}</Typography>
                      <Typography className='text-danger' sx={{ fontWeight: 700, fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"', color: '#EB5757', fontSize: { xs: '12px', sm: '14px' } }}> {
                        myProfile.kycOnline.pan.status === myProfile.kycOffline.pan.status
                          ||
                          myProfile.kycOnline.pan.status > myProfile.kycOffline.pan.status
                          ?
                          <span>
                            <KycStatusComp
                              status={myProfile.kycOffline.pan.status}
                            />
                          </span>
                          :
                          <span>Online -
                            <KycStatusComp
                              status={myProfile.kycOnline.pan.status}
                            />
                          </span>}
                        {
                          (
                            (myProfile.kycstatus === 2 || myProfile.kycstatus === 3)
                            &&
                            (
                              myProfile.kycStatusDetail.pan.status === 2 || myProfile.kycStatusDetail.pan.status === 3
                            )
                          ) ?
                            myProfile.kycOffline.pan.reject_reason ? <span><small className="text-danger">Reason : </small>{myProfile.kycOffline.pan.reject_reason}</span> : ""
                            : ""}
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {(
                      (myProfile.kycstatus === 2 || myProfile.kycstatus === 3)
                      &&
                      (
                        myProfile.kycStatusDetail.pan.status === 2 || myProfile.kycStatusDetail.pan.status === 3
                      )
                    ) &&
                      <Typography>

                        <div className='row mx-0 mx-md-5'>
                          <div className="col12 col-sm-12 col-md-12 col-lg-4 mx-auto"> <p className='label-text-identification mb-2'>{KYCSteps.offline[kycUserType].step1.field1.label}</p>
                            <InputGroup className="mb-3" size='sm'>
                              <Form.Control
                                placeholder=""
                                type='text'
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                className='form-identificaiton'
                                name="pan_number"
                                value={offlineKYCPan.pan_number}
                                autoComplete="off"
                                onChange={offlineKYCPan_handleChange}
                              />
                              {/* <InputGroup.Text id="basic-addon2" className='text-light ' style={{ background: "#7B7B7B", border: 'none' }}></InputGroup.Text> */}
                            </InputGroup></div>
                          <div className="col12 col-sm-12 col-md-12 col-lg-4 mx-auto"> <p className='label-text-identification mb-2'>{KYCSteps.offline[kycUserType].step1.field2.label}</p>
                            <InputGroup className="mb-3" size='sm'>
                              <Form.Control
                                placeholder=""
                                type='text'
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                className='form-identificaiton'
                                name="pan_name"
                                value={offlineKYCPan.pan_name}
                                autoComplete="off"
                                onChange={offlineKYCPan_handleChange}
                              />
                              {/* <InputGroup.Text id="basic-addon2" className='text-light ' style={{ background: "#7B7B7B", border: 'none' }}></InputGroup.Text> */}
                            </InputGroup></div>
                          <div className="col12 col-sm-12 col-md-12 col-lg-4 mx-auto"> <p className='label-text-identification mb-2'>Upload your Pan card</p>
                            <InputGroup className="mb-3" size='sm'>
                              {/* {console.log(kycDocsOfflinePan)} */}
                              {kycDocsOfflinePan ?
                                <div className='py-2'>
                                  <Badge badgeContent={<CloseIcon fontSize='sm' />} color="error" onClick={() => setkycDocsOfflinePan(null)} sx={{ cursor: 'pointer' }}>

                                    <img
                                      src={kycDocsOfflinePan}
                                      alt="kycImg"
                                      className='img-fluid'
                                    />
                                  </Badge>
                                </div> :
                                <FileUploader className='form-identificaiton'

                                  multiple={true}
                                  handleChange={(e) => handleChangesOffline(e, "pan")}
                                  name={"pan"}
                                  types={fileTypes}
                                />
                              }
                              {/* <InputGroup.Text id="basic-addon2" className='text-light ' style={{ background: "#7B7B7B", border: 'none' }}>Image size less than 5 MB only</InputGroup.Text> */}
                            </InputGroup>
                            <p className='label-text-identification' style={{ marginTop: '-10px' }}>Image size less than 3 MB only</p>
                          </div>
                        </div>
                        <div className='mx-auto text-center pt-3'>
                          <button className='btn-profile shadow px-5 fs-6 py-2 mb-3' style={{ width: 'auto', height: "auto" }} disabled={isLoading} onClick={() => { offlineKYCPanSubmit("submit"); setExpanded(false) }}>{isLoading ? "Loading..." : "Submit"}</button>
                        </div>
                      </Typography>}
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                  <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <div className='d-flex justify-content-between align-items-center w-100'>
                      <Typography sx={{ fontSize: { xs: '0.85rem', sm: '1rem' } }}>{KYCSteps.offline[kycUserType].step2.header}</Typography>
                      <Typography className='text-danger' sx={{ fontWeight: 700, fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"', color: '#EB5757', fontSize: { xs: '12px', sm: '14px' } }}>
                        {
                          myProfile.kycOnline.aadhaar.status === myProfile.kycOffline.aadhaar.status
                            ||
                            myProfile.kycOnline.aadhaar.status > myProfile.kycOffline.aadhaar.status
                            ?
                            <span>
                              <KycStatusComp
                                status={myProfile.kycOffline.aadhaar.status}
                              />
                            </span>
                            :
                            <span>Online -
                              <KycStatusComp
                                status={myProfile.kycOnline.aadhaar.status}
                              />
                            </span>}
                        {
                          (
                            (myProfile.kycstatus === 2 || myProfile.kycstatus === 3)
                            &&
                            myProfile.kycStatusDetail.pan.status === 1 &&
                            (myProfile.kycStatusDetail.aadhaar.status === 2 || myProfile.kycStatusDetail.aadhaar.status === 3)
                          ) ?
                            myProfile.kycOffline.aadhaar.reject_reason ? <span><small className="text-danger">Reason : </small>{myProfile.kycOffline.aadhaar.reject_reason}</span> : ""
                            : ""}
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {((myProfile.kycstatus === 2 || myProfile.kycstatus === 3) && (myProfile.kycStatusDetail.aadhaar.status === 2 || myProfile.kycStatusDetail.aadhaar.status === 3) && offlineKYCAadhaar.step == "getNumber") ?
                      <Typography>
                        <div className='row mx-0 mx-md-1'>
                          <div className="col12 col-sm-12 col-md-12 col-lg-6 mx-auto"> <p className='label-text-identification mb-2'>{KYCSteps.offline[kycUserType].step2.field1.label}</p>
                            <InputGroup className="mb-3" size='sm'>
                              <Form.Control
                                placeholder=""
                                type='number'
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                className='form-identificaiton'
                                name="aadhaar_number"
                                value={offlineKYCAadhaar.aadhaar_number}
                                autoComplete="off"
                                onChange={offlineKYCAadhaar_handleChange}
                              />
                              {/* <InputGroup.Text id="basic-addon2" className='text-light ' style={{ background: "#7B7B7B", border: 'none' }}></InputGroup.Text> */}
                            </InputGroup></div>
                          <div className="col12 col-sm-12 col-md-12 col-lg-6 mx-auto"> <p className='label-text-identification mb-2'>{KYCSteps.offline[kycUserType].step2.field2.label}</p>
                            <InputGroup className="mb-3" size='sm'>
                              <Form.Control
                                placeholder=""
                                type='text'
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                className='form-identificaiton'
                                name="aadhaar_name"
                                value={offlineKYCAadhaar.aadhaar_name}
                                autoComplete="off"
                                onChange={offlineKYCAadhaar_handleChange}
                              />
                              {/* <InputGroup.Text id="basic-addon2" className='text-light ' style={{ background: "#7B7B7B", border: 'none' }}></InputGroup.Text> */}
                            </InputGroup></div>
                          <div className="col12 col-sm-12 col-md-12 col-lg-6 mx-auto"> <p className='label-text-identification mb-2'>{KYCSteps.offline[kycUserType].step2.field3.label}</p>
                            <InputGroup className="mb-3" size='sm'>
                              <Form.Control
                                placeholder=""
                                type='text'
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                className='form-identificaiton'
                                name="aadhaar_address"
                                value={offlineKYCAadhaar.aadhaar_address}
                                autoComplete="off"
                                onChange={offlineKYCAadhaar_handleChange}
                              />
                              {/* <InputGroup.Text id="basic-addon2" className='text-light ' style={{ background: "#7B7B7B", border: 'none' }}></InputGroup.Text> */}
                            </InputGroup></div>
                          <div className="col12 col-sm-12 col-md-12 col-lg-6 mx-auto"> <p className='label-text-identification mb-2'>{KYCSteps.offline[kycUserType].step2.field4.label}</p>
                            <InputGroup className="mb-3" size='sm'>
                              <Form.Control
                                placeholder=""
                                type='text'
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                className='form-identificaiton'
                                name="aadhaar_pincode"
                                value={offlineKYCAadhaar.aadhaar_pincode}
                                autoComplete="off"
                                onChange={offlineKYCAadhaar_handleChange}
                              />
                              {/* <InputGroup.Text id="basic-addon2" className='text-light ' style={{ background: "#7B7B7B", border: 'none' }}></InputGroup.Text> */}
                            </InputGroup></div>
                        </div>
                        <div className='row row mx-0 mx-md-1'>
                          <div className="col12 col-sm-12 col-md-12 col-lg-6"> <p className='label-text-identification mb-2'>Upload your Aadhar card </p>
                            <div className='d-flex justify-content-start align-items-center flex-column flex-lg-row'>
                              <div className='me-0 me-lg-3'> <p className='label-text-identification mb-2' >Front Aadhar</p>
                                <InputGroup className="mb-3" size='sm'>
                                  {kycDocsOfflineAadhaar ?
                                    <div className='py-2'>
                                      <Badge badgeContent={<CloseIcon fontSize='sm' />} color="error" onClick={() => setkycDocsOfflineAadhaar(null)} sx={{ cursor: 'pointer' }}>
                                        <img
                                          src={kycDocsOfflineAadhaar}
                                          alt="kycImg"
                                          className='img-fluid'
                                        />
                                      </Badge>
                                    </div> :
                                    <FileUploader className='form-identificaiton'

                                      multiple={true}
                                      handleChange={(e) => handleChangesOffline(e, "aadhaar")}
                                      name={"aadhaar"}
                                      types={fileTypes}
                                    />
                                  }
                                  {/* <InputGroup.Text id="basic-addon2" className='text-light ' style={{ background: "#7B7B7B", border: 'none' }}>Image size less than 5 MB only</InputGroup.Text> */}

                                </InputGroup></div>
                              <div>
                                <p className='label-text-identification mb-2' >Back Aadhar</p>
                                <InputGroup className="mb-3" size='sm'>
                                  {kycDocsOfflineAadhaarBack ?
                                    <div className='py-2'>
                                      <Badge badgeContent={<CloseIcon fontSize='sm' />} color="error" onClick={() => setkycDocsOfflineAadhaarBack(null)} sx={{ cursor: 'pointer' }}>
                                        <img
                                          src={kycDocsOfflineAadhaarBack}
                                          alt="kycImg"
                                          className='img-fluid'
                                        />
                                      </Badge>
                                    </div> :
                                    <FileUploader className='form-identificaiton'

                                      multiple={true}
                                      handleChange={(e) => handleChangesOffline(e, "aadhaarBack")}
                                      name={"aadhaarBack"}
                                      types={fileTypes}
                                    />
                                  }
                                  {/* <InputGroup.Text id="basic-addon2" className='text-light ' style={{ background: "#7B7B7B", border: 'none' }}>Image size less than 5 MB only</InputGroup.Text> */}
                                </InputGroup>
                              </div>
                            </div>
                            <p className='label-text-identification text-warning' style={{ marginTop: '-10px' }}>Upload Each Image Less Than 3 MB</p>
                          </div>
                        </div>
                        <div className='mx-auto text-center pt-3'>
                          <button className='btn-profile shadow px-5 fs-6 py-2 mb-3' style={{ width: 'auto', height: "auto" }} onClick={() => {
                            offlineKYCAadhaarSubmit("submit");
                            setExpanded(false);
                          }} disabled={isLoading}>{isLoading ? "Loading..." : "Submit"}</button>
                        </div>
                      </Typography>
                      : ""}
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                  <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <div className='d-flex justify-content-between align-items-center w-100'>
                      <Typography sx={{ fontSize: { xs: '0.85rem', sm: '1rem' } }}>{KYCSteps.offline[kycUserType].step3.header}</Typography>
                      <Typography className='text-danger' sx={{ fontWeight: 700, fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"', color: '#EB5757', fontSize: { xs: '12px', sm: '14px' } }}>
                        {
                          myProfile.kycOnline.selfie.status === myProfile.kycOffline.selfie.status
                            ||
                            myProfile.kycOnline.selfie.status > myProfile.kycOffline.selfie.status
                            ?
                            <span>
                              <KycStatusComp
                                status={myProfile.kycOffline.selfie.status}
                              />
                            </span>
                            :
                            <span>Online -
                              <KycStatusComp
                                status={myProfile.kycOnline.selfie.status}
                              />
                            </span>}
                        {((myProfile.kycstatus === 2 || myProfile.kycstatus === 3) && (myProfile.kycStatusDetail.pan.status == 1 && myProfile.kycStatusDetail.aadhaar.status === 1) && (myProfile.kycStatusDetail.selfie.status === 2 || myProfile.kycStatusDetail.selfie.status === 3)) ?
                          myProfile.kycOffline.selfie.reject_reason ? <span><small className="text-danger">Reason : </small>{myProfile.kycOffline.selfie.reject_reason}</span> : ""
                          : ""}
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {(
                      (myProfile.kycstatus === 2 || myProfile.kycstatus === 3)

                      &&
                      (myProfile.kycStatusDetail.selfie.status === 2 || myProfile.kycStatusDetail.selfie.status === 3)
                    ) &&
                      <Typography>

                        <div className='row mx-0 mx-md-5'>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-4 mx-auto"> <p className='label-text-identification mb-2'>Upload your selfie</p>
                            <InputGroup className="mb-3" size='sm'>
                              {
                                kycDocsOfflineSelfie ?
                                  <div className='py-2'>
                                    <Badge badgeContent={<CloseIcon fontSize='sm' />} color="error" onClick={() => setkycDocsOfflineSelfie(null)} sx={{ cursor: 'pointer' }}>
                                      <img
                                        src={kycDocsOfflineSelfie}
                                        alt="kycImg"
                                        className='img-fluid'
                                      />
                                    </Badge>
                                  </div> :
                                  <FileUploader className='form-identificaiton'

                                    multiple={true}
                                    handleChange={(e) => handleChangesOffline(e, "selfie")}
                                    name={"selfie"}
                                    types={fileTypes}
                                  />
                              }
                              {/* <InputGroup.Text id="basic-addon2" className='text-light ' style={{ background: "#7B7B7B", border: 'none' }}>Image size less than 5 MB only</InputGroup.Text> */}
                            </InputGroup>
                            {kycDocsOnlineSelfie ? <>
                              <img src={kycDocsOnlineSelfie} alt="kycImg" className='img-fluid' />
                            </> :
                              myProfile.kycOnline.selfie.status != 0 && myProfile.kycOnline.selfie.image &&
                              <>
                                <img src={myProfile.kycOnline.selfie.image} alt="kycImg" className='img-fluid' />
                              </>}
                            <p className='label-text-identification' style={{ marginTop: '-10px' }}>Image size less than 3 MB only</p></div>
                        </div>
                        <div className='mx-auto text-center pt-3'>
                          <button className='btn-profile shadow px-5 fs-6 py-2 mb-3' style={{ width: 'auto', height: "auto" }} disabled={isLoading} onClick={() => {
                            offlineKYCSelfieSubmit("submit");
                            setExpanded(false);
                          }}>{isLoading ? "Loading..." : "Submit"}</button>
                        </div>
                      </Typography>
                    }
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                  <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <div className='d-flex justify-content-between align-items-center w-100'>
                      <Typography sx={{ fontSize: { xs: '0.85rem', sm: '1rem' } }}>{KYCSteps.offline[kycUserType].step4.header}</Typography>
                      <Typography className='text-danger' sx={{ fontWeight: 700, fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"', color: '#EB5757', fontSize: { xs: '12px', sm: '14px' } }}>
                        {
                          myProfile.kycOnline.aml.status === myProfile.kycOffline.aml.status
                            ||
                            myProfile.kycOnline.aml.status > myProfile.kycOffline.aml.status
                            ?
                            <span>
                              <KycStatusComp
                                status={myProfile.kycOffline.aml.status}
                              />
                            </span>
                            :
                            <span>Online -
                              <KycStatusComp
                                status={myProfile.kycOnline.aml.status}
                              />
                            </span>}
                        {
                          (
                            (myProfile.kycStatusDetail.aml.status === 2 || myProfile.kycStatusDetail.aml.status === 3)
                          ) ?
                            myProfile.kycOffline.aml.reject_reason ? <span><small className="text-danger">Reason : </small>{myProfile.kycOffline.aml.reject_reason}</span> : ""
                            : ""}
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {( (myProfile.kycStatusDetail.aml.status === 2 || myProfile.kycStatusDetail.aml.status === 3) && offlineKYCAadhaar.step == "getNumber") ?
                      <Typography>
                        <div className='row mx-0 mx-md-1'>
                          <div className="col12 col-sm-12 col-md-12 col-lg-6 mx-auto"> <p className='label-text-identification mb-2'>{KYCSteps.offline[kycUserType].step4.field1.label}</p>
                            <InputGroup className="mb-3" size='sm'>
                              <Form.Select aria-label="Default select example" placeholder=""
                                aria-describedby="basic-addon2"
                                className='form-identificaiton'
                                name="occupation"
                                required
                                autoComplete="off"
                                value={offlineKYCAml.occupation}
                                onChange={(e) => {
                                  setofflineKYCAml((data) => { return { ...data, occupation: e.target.value } })
                                }}
                              >
                                <option className='d-none'>Click To Select</option>
                                <option value="Govt Salaried">Govt Salaried</option>
                                <option value="Public Salaried">Public Salaried</option>
                                <option value="Business/Selfemployed">Business/Selfemployed</option>
                                <option value="Student">Student</option>
                                <option value="HouseWife">HouseWife</option>
                              </Form.Select>
                            </InputGroup>
                          </div>
                          <div className="col12 col-sm-12 col-md-12 col-lg-6 mx-auto"> <p className='label-text-identification mb-2'>{KYCSteps.offline[kycUserType].step4.field2.label}</p>
                            <InputGroup className="mb-3" size='sm'>
                              <Form.Select aria-label="Default select example" placeholder=""
                                aria-describedby="basic-addon2"
                                className='form-identificaiton'
                                name="sourceOfIncome"
                                autoComplete="off"
                                required
                                value={offlineKYCAml.sourceOfIncome}
                                onChange={(e) => {
                                  setofflineKYCAml((data) => { return { ...data, sourceOfIncome: e.target.value } })
                                }}
                              >
                                <option className='d-none'>Click To Select</option>
                                <option value="Employment">Employment</option>
                                <option value="Business/Entrepreneurship">Business/Entrepreneurship</option>
                                <option value="Investments">Investments</option>
                                <option value="Retirement/Pension">Retirement/Pension</option>
                                <option value="Rental Income">Rental Income</option>
                              </Form.Select>
                            </InputGroup>
                          </div>
                          <div className="col12 col-sm-12 col-md-12 col-lg-6 mx-auto"> <p className='label-text-identification mb-2'>{KYCSteps.offline[kycUserType].step4.field3.label}</p>
                            <InputGroup className="mb-3" size='sm'>
                              <Form.Select aria-label="Default select example" placeholder=""
                                aria-describedby="basic-addon2"
                                className='form-identificaiton'
                                name="annualIncome"
                                autoComplete="off"
                                required
                                value={offlineKYCAml.annualIncome}
                                onChange={(e) => {
                                  setofflineKYCAml((data) => { return { ...data, annualIncome: e.target.value } })
                                }}
                              >
                                <option className='d-none'>Click To Select</option>
                                <option value="0 Rupees -1 Lakh">0 Rupees -1 Lakh</option>
                                <option value="1 Lakh - 5 Lakh">1 Lakh - 5 Lakh</option>
                                <option value="5 Lakh - 10 Lakh">5 Lakh - 10 Lakh</option>
                                <option value="10 Lakh - 50 Lakh">10 Lakh - 50 Lakh</option>
                                <option value="50 Lakh - 100 Lakh">50 Lakh - 100 Lakh</option>
                              </Form.Select>
                            </InputGroup>
                          </div>
                          <div className="col12 col-sm-12 col-md-12 col-lg-6 mx-auto"> <p className='label-text-identification mb-2'>{KYCSteps.offline[kycUserType].step4.field4.label}</p>
                            <InputGroup className="mb-3" size='sm'>
                              <Form.Select aria-label="Default select example" placeholder=""
                                aria-describedby="basic-addon2"
                                className='form-identificaiton'
                                name="tradingExperience"
                                autoComplete="off"
                                required
                                value={offlineKYCAml.tradingExperience}
                                onChange={(e) => {
                                  setofflineKYCAml((data) => { return { ...data, tradingExperience: e.target.value } })
                                }}
                              >
                                <option className='d-none'>Click To Select</option>
                                <option value="Beginner">Beginner</option>
                                <option value="Intermediate">Intermediate</option>
                                <option value="Advanced">Advanced</option>
                                <option value="Expert">Expert</option>
                                <option value="Professional">Professional</option>
                              </Form.Select>
                            </InputGroup>
                          </div>
                          <div className="col12 col-sm-12 col-md-12 col-lg-6"> <p className='label-text-identification mb-2'>{KYCSteps.offline[kycUserType].step4.field5.label}</p>
                            <InputGroup className="mb-3" size='sm'>
                              <Form.Select aria-label="Default select example" placeholder=""
                                aria-describedby="basic-addon2"
                                className='form-identificaiton'
                                name="purpose"
                                autoComplete="off"
                                required
                                value={offlineKYCAml.purpose}
                                onChange={(e) => {
                                  setofflineKYCAml((data) => { return { ...data, purpose: e.target.value } })
                                }}
                              >
                                <option className='d-none'>Click To Select</option>
                                <option value="Investment">Investment</option>
                                <option value="Trading">Trading</option>
                                <option value="Arbitrage">Arbitrage</option>
                              </Form.Select>
                            </InputGroup>
                          </div>

                        </div>

                        <div className='row mx-0'>
                          <div className="col-12">
                            <InputGroup className="mb-2 mt-2" size='sm'>
                              <Form.Check
                                inline
                                type={'checkbox'}
                                id={`default-checkbox`}
                                className='defaultCheckboxCSS'
                                required
                                checked={offlineKYCAml.checked}
                                onChange={(e) => {
                                  setofflineKYCAml((data) => { return { ...data, checked: e.target.checked } })
                                }}
                                label={KYCSteps.offline[kycUserType].step4.field6.label}
                              />
                            </InputGroup>
                            <p className='label-text-identification mb-2'>I confirm that the information provided is correct and complete. I acknowledge that providing false information may lead to legal repercussions and actions by Unitic Exchange.</p>
                          </div>
                        </div>
                        <div className='mx-auto text-center pt-3'>
                          <button className='btn-profile shadow px-5 fs-6 py-2 mb-3' style={{ width: 'auto', height: "auto" }} onClick={() => {
                            offlineKYCAmlSubmit("submit");
                            setExpanded(false);
                          }} disabled={isLoading || (isEmpty(offlineKYCAml.occupation)
                            && isEmpty(offlineKYCAml.sourceOfIncome)
                            && isEmpty(offlineKYCAml.annualIncome)
                            && isEmpty(offlineKYCAml.tradingExperience)
                            && isEmpty(offlineKYCAml.purpose))
                          }>{isLoading ? "Loading..." : "Submit"}</button>
                        </div>
                      </Typography>
                      : ""}
                  </AccordionDetails>
                </Accordion>

              </ThemeProvider>
            </>}
          </div>
          : <></>}
      </div>
    </section >
  );
};

export default Identification;