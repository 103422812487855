import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InputGroup from 'react-bootstrap/InputGroup';
import '../../../assets/style.css';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FuturesTPSL from './FuturesTPSL';
import { toast } from '../../../core/lib/toastAlert';
import Spinner from 'react-bootstrap/Spinner';
import FuturesBelowInfo from './FuturesBelowInfo';
import { useNavigate } from 'react-router-dom';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        flexShrink: 0,
        fontSize: '1rem',
        textAlign: 'center',
        backgroundColor: '#535151',
        color: '#C8C8C8',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: '1rem',
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 0,
    width: 14,
    height: 14,
    boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
    border: '1px solid #D9D9D9',
    backgroundColor: 'transparent',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: 'transparent',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(57,75,89,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: 'transparent',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
        display: 'block',
        width: 13,
        height: 13,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: 'transparent',
    },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
    return (
        <FormControlLabel sx={{
            '&.MuiFormControlLabel-root .MuiFormControlLabel-label': {
                fontFamily: "Open Sans",
                color: '#C8C8C8',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: 'normal',
            }
        }} control={<Checkbox
            sx={{
                '&:hover': { bgcolor: 'transparent' },

            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            size='small'
            inputProps={{ 'aria-label': 'Checkbox demo' }}
            {...props}
        />} label={props?.label} />

    );
}
function valuetext(value) {
    return `${value}%`;
}

function valueLabelFormat(value) {
    return value + '%';
}
function generateRandom4DigitNumber() {
    return Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
}
function removeSwapSuffix(str) {

    if (str.endsWith("-SWAP")) {

        return str.slice(0, -5);
    } else {

        return str;
    }
}
function countDecimals(num) {
    if (!num) return 0;
    const numString = num.toString();
    const decimalIndex = numString.indexOf('.');
    if (decimalIndex === -1) {
        return 0;
    } else {
        return +(numString.length - decimalIndex - 1);
    }
}

const FuturesTradeForm = ({ pairDetails, priceStatic, setPriceStatic, handleClickOpenOrderConfirmation, amount, setAmount, walletData, submitTrade, handleClickOpenMarginMode, handleClickOpenAdjust, crossMenu, xMenu, tpPx, setTpPx, slPx, setSlPx, myProfile, createSubAccount, futuresBalance, fromSymbolName, trades, getBaseAndTargetCurrency }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedItem, setSelectedItem] = useState();
    const [tabMenu, setTabMenu] = useState('Limit')
    const [value, setValue] = useState(0);
    const [warningText, setWarningText] = useState(null)
    const [marks, setMarks] = useState([]);
    const [checkedLong, setCheckedLong] = useState(false);
    const [checkedShort, setCheckedShort] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [quantity, setQuantity] = useState(0);
    const [decimal, setDecimal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [toggle, setToggle] = useState(false);
    const open = Boolean(anchorEl);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [priceCopy, setPriceCopy] = useState(0);
    const navigate = useNavigate()
    // const handleSelect = () => {
    //     if (!pair) return
    //     if (selectedItem === pair?.instId.split('-')[0]) setSelectedItem(() => pair?.instId.split('-')[1])
    //     else setSelectedItem(() => pair?.instId.split('-')[0])
    // };
    useEffect(() => {
        setPriceStatic((data) => data)
    }, [pairDetails?.data?.lastTradePrice])
    useEffect(() => {
        const coinPrice = +pairDetails.data.lastTradePrice || 0
        const total = (coinPrice * (+amount || 0));
        setQuantity(() => total.toFixed(2))
    }, [])
    useEffect(() => {
        // setSelectedItem(() => pair?.instId ? pair?.instId.split('-')[1] : '')
        setDecimal(() => countDecimals(pairDetails?.data?.multiplier * pairDetails?.data?.lotSize))
    }, [pairDetails?.data?.multiplier, pairDetails?.data?.lotSize])
    // console.log('decimal: ', decimal, typeof decimal)
    // useEffect(() => {
    //     setAmount(() => 0.00)
    //     setQuantity(() => 0.00)
    //     setValue(() => 0)
    // }, [xMenu])
    // useEffect(() => {
    //     setWarningText(() => null)
    //     setValue(() => 0)
    // }, [amount, quantity, priceStatic])
    // useEffect(() => {
    //     setPriceCopy(() => priceStatic)
    // }, [pair?.instId])

    const handleClick = (event) => {
        if (event) {
            setAnchorEl(event?.currentTarget);
        }
    };

    const open2 = Boolean(anchorEl2);

    const handleClick2 = (event) => {
        if (event) {

            setAnchorEl2(event?.currentTarget);
        }
    };
    const handleSliderChange = (event, newValue) => {
        setQuantity(() => 0)
        setAmount(() => 0)
        setValue(() => newValue);
        const coinPrice = +priceStatic
        // If you have 100USDT and click 25%. It displays the 100x25%=25
        // order size = (USDTamount x leverage) / (CurrentPrice x multiplier)
        // = (1.5375 x 25) / (66918.8 x 0.001)
        // =  0.57

        const balance = (+futuresBalance?.availableBalance) || 0
        const usdtAmount = (+(balance.toFixed(2)) * (newValue / 100))
        const totalCoin = (usdtAmount * xMenu) / (coinPrice * pairDetails?.data?.multiplier)

        const amountChanged = totalCoin / coinPrice
        // console.log(amountChanged,totalCoin,coinPrice,usdtAmount,xMenu,pairDetails?.data?.multiplier,+(balance.toFixed(2)),newValue)
        // console.log(totalCoin, amountChanged)
        if ((amountChanged < pairDetails?.data?.multiplier * pairDetails?.data?.lotSize) || (amountChanged > pairDetails?.data?.maxOrderQty)) {
            return setWarningText(() => <span style={{ color: 'rgb(255, 75, 94)', fontWeight: 700 }}>The order quantity must be greater than {pairDetails?.data?.multiplier * pairDetails?.data?.lotSize} and no more than {pairDetails?.data?.maxOrderQty}.</span>)
        }
        setWarningText(() => null)
        setQuantity(() => (totalCoin.toFixed(2)))
        setAmount(() => parseFloat(amountChanged.toFixed(decimal)))
        return;
    };

    useEffect(() => {
        const marksValue = [
            { value: 0, label: value >= 0 ? '0' : '' },
            { value: 25, label: value >= 25 ? '25' : '', },
            { value: 50, label: value >= 50 ? '50' : '', },
            { value: 75, label: value >= 75 ? '75' : '', },
            { value: 100, label: value >= 100 ? '100%' : '100%', },
        ];
        setMarks(() => marksValue);
    }, [value])


    const handleChangeLong = (event) => {
        setCheckedShort(() => false);
        setCheckedLong(event.target.checked);
    };
    const handleChangeShort = (event) => {
        setCheckedLong(() => false);
        setCheckedShort(event.target.checked);
    };
    const handleChange2 = (event) => {
        setChecked2(event.target.checked);
    };

    const tradePreliminary = async (side, longShort) => {
        // const pairName = removeSwapSuffix(pair?.instId)
        // instId: pair?.instId,
        // tdMode: crossMenu.toLowerCase(),
        // let finalSz = (1 * parseFloat(amount)) / parseFloat(0.001) // pairDetails?.data?.lotSize
        // sz: (finalSz)?.toFixed(decimal),
        if ((!amount || (tabMenu !== 'Market' && !priceStatic))) return toast({ type: "error", message: "Please fill up" });
        const minSize = pairDetails?.data?.multiplier * pairDetails?.data?.lotSize
        // const lot = pairDetails?.data?.lotSize
        let tradeParams = {
            reduceOnly: checked2,
            side: side.toUpperCase(), //buy sell
            symbol: pairDetails.data?.symbol,
            type: tabMenu.toLowerCase(),
            size: ((1 * parseFloat(amount)) / parseFloat(minSize)).toString(),
            leverage: xMenu,
            baseCurrency: pairDetails.data?.baseCurrency,
            quoteCurrency: pairDetails.data?.quoteCurrency,
            quantity: quantity,
            cost: (+futuresBalance?.availableBalance) - quantity
        }
        if (tabMenu === 'Limit') {
            tradeParams.price = priceStatic.toString()
        }
        if ((checkedLong || checkedShort) && (tpPx || slPx)) {
            tradeParams.stopPrice = tpPx ? tpPx.toString() : slPx.toString();
            // tradeParams.stopPriceType = tpPx ? "TP" : "SL";

        }
        const isPermit = sessionStorage.getItem('confirm');
        if (isPermit == 'true') {

            const finalSub = await submitTrade(tradeParams)
            if (finalSub) {
                setAmount(() => '')
            }
        }
        else {

            handleClickOpenOrderConfirmation(tradeParams)
        }
    }
    const handleAmountChange = (e) => {
        const value = e.target.value;
        // const regex = new RegExp(`^[0-9]*\\.?[0-9]{0,5}$`);
        const regex = new RegExp(`^[0-9]*\\.?[0-9]{0,3}$`);
        if (regex.test(value) || value === '') {
            setAmount(() => value);
            const coinPrice = +priceStatic
            const total = (coinPrice * value);
            setQuantity(() => total.toFixed(2))
            setWarningText(() => null)
            setValue(() => 0)
            // if ((coinPrice * value) >futuresBalance?.availableBalance) {
            //     toast({ type: 'error', message: "Insufficient Funds" })
            // }
        }
    };
    const checkOnBlur = (e) => {
        const minValue = pairDetails?.data?.multiplier * pairDetails?.data?.lotSize
        if (e.target.value < Number(minValue)) {
            // toast({ type: 'error', message: `Min ${minValue}` })
        }
    }
    const OpenFuturesAccount = <div><div className='w-100'><button disabled={loading} onClick={async () => {
        setLoading(() => true);
        await createSubAccount(myProfile?.email)
        setLoading(() => false)
    }} >
        {loading ? <>
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />
            <span className='ps-2'>Loading...</span></> : 'Futures Account Create'}</button></div></div>
    return (
        <div style={{ background: '#2E2C2C' }} className='heightTradeForm px-3 py-2 '>
            <div className='top-button'>
                <div className='w-100'>
                    <Button
                        id="demo-customized-button1"
                        variant="contained"
                        disableElevation
                    // onClick={() => handleClickOpenMarginMode()}
                    >
                        {crossMenu}
                    </Button>
                </div>
                <div className='w-100'>
                    <Button
                        id="demo-customized-button"
                        disableElevation
                        onClick={() => handleClickOpenAdjust()}
                    >
                        {xMenu + 'x'} <ArrowDropDownIcon />
                    </Button>
                </div>
            </div>
            <div className='menu-option-part pt-3'>
                <p className={`${tabMenu === 'Limit' ? 'text-active' : ''} cursor-pointer pb-1`} onClick={() => { setTabMenu(() => 'Limit') }}>Limit</p>
                <p className={`${tabMenu === 'Market' ? 'text-active' : ''} cursor-pointer pb-1`} onClick={() => { setTabMenu(() => 'Market') }}>Market</p>
                {/* <p className={`${tabMenu === 'TP' ? 'text-active' : ''} cursor-pointer pb-1`} onClick={() => setTabMenu(() => 'TP')}>TP/SL</p> */}
            </div>
            {tabMenu === 'Market' ?
                <div className='pt-2'>
                    <div>
                        <Form.Label htmlFor="basic-url" style={{
                            color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', display: 'none'
                        }}>Price({pairDetails?.data?.quoteCurrency}): </Form.Label>
                        <InputGroup className="mb-3 selectOfTrade" style={{ zIndex: toggle ? 5 : 0, display: 'none' }}>
                            <Form.Control aria-label="Text input with dropdown button" type='number' className='inputOfTrade' value={priceStatic} onChange={(e) => setPriceStatic(e.target.value)} />
                        </InputGroup>
                        <Form.Label htmlFor="basic-url" style={{
                            color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
                        }}>Amount({fromSymbolName(pairDetails?.data?.baseCurrency)}): </Form.Label>
                        <InputGroup className="mb-2 selectOfTrade" style={{ zIndex: toggle ? 5 : 0, border: warningText ? '1px solid rgb(255, 75, 94)' : '0', borderRadius: '0.375rem' }}>
                            <Form.Control aria-label="Text input with text2" type='number' className='inputOfTrade' placeholder={`Min ${pairDetails?.data?.multiplier * pairDetails?.data?.lotSize}`} value={amount} onChange={handleAmountChange} onBlur={checkOnBlur} />
                            <InputGroup.Text id="Text input with text2" className='sideBtn'>{fromSymbolName(pairDetails?.data?.baseCurrency)}</InputGroup.Text>
                        </InputGroup>
                        <div className='handleCostMaxText my-0'><p>{warningText}</p></div>
                        <div className='handleCostMaxText my-0'><p>{`Min ${(pairDetails?.data?.multiplier || 0) * (pairDetails?.data?.lotSize || 0)} ${fromSymbolName(pairDetails?.data?.baseCurrency)}`}</p></div>
                        <div className='handleCostMaxText my-2'><p>Quantity {pairDetails?.data?.quoteCurrency}: {
                            quantity
                        }</p></div>
                        <Box className='px-2 mb-2'>
                            <Slider
                                aria-label="Default"
                                value={value}
                                onChange={handleSliderChange}
                                valueLabelFormat={valueLabelFormat}
                                getAriaValueText={valuetext}
                                step={1}
                                valueLabelDisplay="auto"
                                marks={marks}
                                sx={{
                                    '&.MuiSlider-root': {
                                        color: '#ffff',
                                        height: '2px',
                                        padding: '0.5rem 0'
                                    },
                                    '&.MuiSlider-root .MuiSlider-markLabel': {
                                        // color: '#ffff',
                                        color: '#ffff',
                                        fontFamily: 'Roboto',
                                        fontSize: '12px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        top: '25px'
                                    },
                                    '&.MuiSlider-root .MuiSlider-markLabelActive': {
                                        color: '#ffff',
                                        fontFamily: 'Roboto',
                                        fontSize: '12px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                    },
                                    '&.MuiSlider-root .MuiSlider-thumb': {
                                        width: '16px',
                                        height: '16px',
                                        flexShrink: 0,
                                        background: 'linear-gradient(180deg, #10EDB6 0%, #427AEC 100%)',
                                        strokeWidth: '1px',
                                        border: '1px solid #FFFF'
                                    },
                                    '&.MuiSlider-root .MuiSlider-rail': {
                                        color: '#ffff',
                                        backgroundColor: '#ffff',
                                        opacity: 1
                                    },
                                    '&.MuiSlider-root .MuiSlider-track': {
                                        color: '#ffff',
                                        background: 'linear-gradient(180deg, #10EDB6 0%, #427AEC 100%)',
                                        opacity: 1,
                                        border: 'none'
                                    },
                                    '&.MuiSlider-root .MuiSlider-mark': {
                                        color: '#ffff',
                                        backgroundColor: '#2E2C2C',
                                        width: '16px',
                                        height: '16px',
                                        flexShrink: 0,
                                        strokeWidth: '1px',
                                        border: '1px solid #FFFF',
                                        borderRadius: '50%',
                                        transform: 'translate(-7px, -50%)',
                                        webkitTransform: 'translate(-7px, -50%)',
                                        opacity: 1
                                    },
                                    '&.MuiSlider-root .MuiSlider-markActive': {
                                        width: '16px',
                                        height: '16px',
                                        flexShrink: 0,
                                        background: 'linear-gradient(180deg, #10EDB6 0%, #427AEC 100%)',
                                        strokeWidth: '1px',
                                        border: '1px solid #FFFF'
                                    },
                                    '&.MuiSlider-root .MuiSlider-valueLabel ': {

                                    }
                                }}
                            />
                        </Box>

                        <div className='mb-3'>
                            <div className='handleTextBS mb-2'>
                                <p>Available USDT: <span>{futuresBalance?.availableBalance ? (+futuresBalance?.availableBalance)?.toFixed(2) : 0.00}</span></p>
                            </div>
                            {/* <div className='d-flex justify-content-between align-items-center handleTextBS mb-2'>
                                <p>Buy: <span>0.0USDT</span></p>
                                <p>Sell: <span>0.0USDT</span></p>
                            </div> */}

                            <div >
                                {/* <BpCheckbox checked={checkedLong} onChange={handleChangeLong} label={'SL of Long'} />
                                <BpCheckbox checked={checkedShort} onChange={handleChangeShort} label={'SL of Short'} />
                                <br />
                                {(checkedLong) ? <FuturesTPSL tpPx={tpPx} setTpPx={setTpPx} slPx={slPx} setSlPx={setSlPx} mode={'long'} /> : null}
                                {(checkedShort) ? <FuturesTPSL tpPx={tpPx} setTpPx={setTpPx} slPx={slPx} setSlPx={setSlPx} mode={'short'} /> : null} */}
                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                    {/* <div><BpCheckbox checked={checked2} onChange={handleChange2} label={'Reduce Only'} /></div> */}
                                    {/* <div>
                                        <InputGroup className="selectOfTradeForm align-items-center">

                                            <p style={{
                                                fontFamily: "Open Sans",
                                                color: '#C8C8C8',
                                                fontSize: '12px',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                lineHeight: 'normal',
                                            }}>TIF: </p>
                                            <DropdownButton
                                                variant="outline-secondary"
                                                title="GTC"
                                                id="input-group-dropdown-2"
                                                align="end"
                                            >
                                                <Dropdown.Item href="#">GTC</Dropdown.Item>
                                                <Dropdown.Item href="#">GTC</Dropdown.Item>
                                            </DropdownButton>
                                        </InputGroup>

                                    </div> */}
                                </div>
                            </div>

                            <div className='lastPartButton mt-3'>
                                {(myProfile?.futuresSubAccount?.isActive && myProfile?.futuresSubAccount?.uid) ?
                                    <FuturesBelowInfo tradePreliminary={tradePreliminary} xMenu={xMenu} quantity={quantity} priceStatic={priceStatic} pairDetails={pairDetails} tabMenu={tabMenu} trades={trades} getBaseAndTargetCurrency={getBaseAndTargetCurrency} />
                                    : (!myProfile) ? <div className='trading-page-order-tabs-section h-auto'>
                                        <span className='trade-bottom-login-text-1'>
                                            <span className='fc-g cursor-pointer' onClick={() => navigate('/login')}>Login </span>(or)
                                            <span className='fc-g cursor-pointer' onClick={() => navigate('/register')}> Register Now</span> to trade
                                        </span>
                                    </div> : OpenFuturesAccount}
                                {/* <div className='handleCostMaxText text-end mt-2'><p>Fee Level</p></div> */}
                            </div>
                        </div>
                    </div>
                </div>
                : tabMenu === 'Limit' ? <div className='pt-2'>
                    <div>
                        <Form.Label htmlFor="basic-url" style={{
                            color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
                        }}>Price({pairDetails?.data?.quoteCurrency}): </Form.Label>
                        <InputGroup className="mb-3 selectOfTrade" style={{ zIndex: toggle ? 5 : 0 }}>
                            <Form.Control aria-label="Text input with dropdown button" type='number' className='inputOfTrade' value={priceStatic} onChange={(e) => setPriceStatic(e.target.value)} />
                        </InputGroup>

                        <Form.Label htmlFor="basic-url" style={{
                            color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
                        }}>Amount({fromSymbolName(pairDetails?.data?.baseCurrency)}): </Form.Label>
                        <InputGroup className="mb-2 selectOfTrade" style={{ zIndex: toggle ? 5 : 0, border: warningText ? '1px solid rgb(255, 75, 94)' : '0', borderRadius: '0.375rem' }}>
                            <Form.Control aria-label="Text input with text" type='number' className='inputOfTrade' placeholder={`Min ${pairDetails?.data?.multiplier * pairDetails?.data?.lotSize}`} value={amount} onChange={handleAmountChange} onBlur={checkOnBlur} />
                            <InputGroup.Text id="Text input with text" className='sideBtn'>{fromSymbolName(pairDetails?.data?.baseCurrency)} </InputGroup.Text>
                        </InputGroup>
                        <div className='handleCostMaxText my-0'><p>{warningText}</p></div>
                        <div className='handleCostMaxText my-0'><p>{`Min ${(pairDetails?.data?.multiplier || 0) * (pairDetails?.data?.lotSize || 0)} ${fromSymbolName(pairDetails?.data?.baseCurrency)}`}</p></div>
                        <div className='handleCostMaxText my-2'><p>Quantity {pairDetails?.data?.quoteCurrency}: {quantity}</p></div>
                        <Box className='px-2 mb-2'>
                            <Slider
                                aria-label="Default"
                                value={value}
                                onChange={handleSliderChange}
                                valueLabelFormat={valueLabelFormat}
                                getAriaValueText={valuetext}
                                step={1}
                                valueLabelDisplay="auto"
                                marks={marks}
                                sx={{
                                    '&.MuiSlider-root': {
                                        color: '#ffff',
                                        height: '2px',
                                        padding: '0.5rem 0'
                                    },
                                    '&.MuiSlider-root .MuiSlider-markLabel': {
                                        // color: '#ffff',
                                        color: '#ffff',
                                        fontFamily: 'Roboto',
                                        fontSize: '12px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        top: '25px'
                                    },
                                    '&.MuiSlider-root .MuiSlider-markLabelActive': {
                                        color: '#ffff',
                                        fontFamily: 'Roboto',
                                        fontSize: '12px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                    },
                                    '&.MuiSlider-root .MuiSlider-thumb': {
                                        width: '16px',
                                        height: '16px',
                                        flexShrink: 0,
                                        background: 'linear-gradient(180deg, #10EDB6 0%, #427AEC 100%)',
                                        strokeWidth: '1px',
                                        border: '1px solid #FFFF'
                                    },
                                    '&.MuiSlider-root .MuiSlider-rail': {
                                        color: '#ffff',
                                        backgroundColor: '#ffff',
                                        opacity: 1
                                    },
                                    '&.MuiSlider-root .MuiSlider-track': {
                                        color: '#ffff',
                                        background: 'linear-gradient(180deg, #10EDB6 0%, #427AEC 100%)',
                                        opacity: 1,
                                        border: 'none'
                                    },
                                    '&.MuiSlider-root .MuiSlider-mark': {
                                        color: '#ffff',
                                        backgroundColor: '#2E2C2C',
                                        width: '16px',
                                        height: '16px',
                                        flexShrink: 0,
                                        strokeWidth: '1px',
                                        border: '1px solid #FFFF',
                                        borderRadius: '50%',
                                        transform: 'translate(-7px, -50%)',
                                        webkitTransform: 'translate(-7px, -50%)',
                                        opacity: 1
                                    },
                                    '&.MuiSlider-root .MuiSlider-markActive': {
                                        width: '16px',
                                        height: '16px',
                                        flexShrink: 0,
                                        background: 'linear-gradient(180deg, #10EDB6 0%, #427AEC 100%)',
                                        strokeWidth: '1px',
                                        border: '1px solid #FFFF'
                                    },
                                    '&.MuiSlider-root .MuiSlider-valueLabel ': {

                                    }
                                }}
                            />
                        </Box>
                        {/* <div className='mb-2 w-100' style={{ borderRadius: '6px', background: '#535151', display: 'flex', padding: '6px 5px 10px 5px', justifyContent: 'center', alignItems: 'center' }}>
                            <p style={{
                                color: '#FFF',
                                fontFamily: 'Roboto',
                                fontSize: '0.775rem',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                // lineHeight: '15px',
                            }}>Set take- profit/Stop loss Prices in advance. Depending on your trading strategy, you can choose to set limit or market take profit or stop loss orders. you can also set your order to trigger based on the “Last price” or “Mark Price”.</p>
                        </div> */}
                        <div className='handleTextBS mb-1'>
                            <p>Available USDT: <span>
                                {futuresBalance?.availableBalance ? (+futuresBalance?.availableBalance)?.toFixed(2) : 0.00}
                            </span></p>
                        </div>

                        <div className='mb-3' >
                            {/* <div >
                                <BpCheckbox checked={checkedLong} onChange={handleChangeLong} label={'SL of Long'} />
                                <BpCheckbox checked={checkedShort} onChange={handleChangeShort} label={'SL of Short'} />
                            </div>
                            {checkedLong ? <FuturesTPSL tpPx={tpPx} setTpPx={setTpPx} slPx={slPx} setSlPx={setSlPx} mode={'long'} /> : null}
                            {checkedShort ? <FuturesTPSL tpPx={tpPx} setTpPx={setTpPx} slPx={slPx} setSlPx={setSlPx} mode={'short'} /> : null} */}
                            <div className='d-flex justify-content-between align-items-center mb-3'>
                                {/* <div><BpCheckbox checked={checked2} onChange={handleChange2} label={'Reduce Only'} /></div> */}
                                {/* <div>
                                        <InputGroup className="selectOfTradeForm align-items-center">

                                            <p style={{
                                                fontFamily: "Open Sans",
                                                color: '#C8C8C8',
                                                fontSize: '12px',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                lineHeight: 'normal',
                                            }}>TIF: </p>
                                            <DropdownButton
                                                variant="outline-secondary"
                                                title="GTC"
                                                id="input-group-dropdown-2"
                                                align="end"
                                            >
                                                <Dropdown.Item href="#">GTC</Dropdown.Item>
                                                <Dropdown.Item href="#">GTC</Dropdown.Item>
                                            </DropdownButton>
                                        </InputGroup>

                                    </div> */}
                            </div>
                            <div className='lastPartButton mt-2'>
                                {(myProfile?.futuresSubAccount?.isActive && myProfile?.futuresSubAccount.uid) ?
                                    <FuturesBelowInfo tradePreliminary={tradePreliminary} xMenu={xMenu} quantity={quantity} priceStatic={priceStatic} pairDetails={pairDetails} tabMenu={tabMenu} trades={trades} getBaseAndTargetCurrency={getBaseAndTargetCurrency} />
                                    : (!myProfile) ? <div className='trading-page-order-tabs-section h-auto'>
                                        <span className='trade-bottom-login-text-1'>
                                            <span className='fc-g cursor-pointer' onClick={() => navigate('/login')}>Login </span>(or)
                                            <span className='fc-g cursor-pointer' onClick={() => navigate('/register')}> Register Now</span> to trade
                                        </span>
                                    </div> : OpenFuturesAccount}
                                {/* <div className='handleCostMaxText text-end mt-2'><p>Fee Level</p></div> */}
                            </div>
                        </div>
                    </div>
                </div> : tabMenu === 'TP' ? <div className='pt-3'>

                    <div className='mb-2  gy-2 row TPTopProps'>
                        <div className='col-4'>
                            <p>For Position</p>
                            <p>BTC USDT</p>
                        </div>
                        <div className='col-4'>
                            <p>Side</p>
                            <p>LONG . 5X</p>
                        </div>
                        <div className='col-4'>
                            <p>LAST PRICE</p>
                            <p>42,843.4</p>
                        </div>
                        <div className='col-4'>
                            <p>AVG PRICE</p>
                            <p>42,843.4</p>
                        </div>
                        <div className='col-4'>
                            <p>HARD LIQ.</p>
                            <p>34,802.6</p>
                        </div>
                    </div>
                    <div className="mb-2" style={{ zIndex: toggle ? 5 : 0 }}>
                        <Form.Label htmlFor="basic-url" style={{
                            color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
                        }}>Take Profit</Form.Label>

                        <Form.Control aria-label="Text input with dropdown button" type='number' className='inputOfTP' placeholder='Trigger Price' />
                        <Form.Text className='textTP pt-1'>
                            Est.PNL : <span style={{ color: '#10EDB6' }}>₹ 0.00</span> <span className='ps-2'>+ 0.00</span>
                        </Form.Text>
                    </div>
                    <div className="mb-2" style={{ zIndex: toggle ? 5 : 0 }}>
                        <Form.Label htmlFor="basic-url" style={{
                            color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
                        }}>Stop Loss</Form.Label>

                        <Form.Control aria-label="Text input with dropdown button" type='number' className='inputOfTP' placeholder='Trigger Price' />
                        <Form.Text className='textTP pt-1'>
                            Est.PNL : <span style={{ color: '#FF0141' }}>₹ 0.00</span> <span className='ps-2'>+ 0.00</span>
                        </Form.Text>
                    </div>

                    <div className='mb-3'>
                        <h6 className='mb-0'>Notes</h6>
                        <p style={{
                            color: '#C8C8C8',
                            fontFamily: 'Roboto',
                            fontSize: '0.75rem',
                            fontStyle: 'normal',
                            fontWeight: '400',
                        }}>When last traded price reaches target or stop loss price, a market order is triggered to completely exit your position.</p>
                    </div>
                    <div><button className='btn-confirm w-100 fw-bold'>Confirm</button></div>

                </div> : null
            }
        </div>

    );
};

export default FuturesTradeForm;