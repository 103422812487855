import React, { useEffect } from 'react';
import './ApiDocumentation.css';
import NavbarOne from '../separate/NavbarOne';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useContextData } from '../../core/context';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GetDoc from './EachDataComponent/GetDoc';
import { Helmet } from 'react-helmet-async';

const drawerWidth = 240;
const ApiDocumentation = () => {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const { siteSettings, myProfile, setUserProfile } = useContextData();
    const navigate = useNavigate();
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const dataTab = [{
        tab: 'Get Order Book Data', des: "This endpoint retrieves a specific pair data with orderbook.", meth: 'Post', url: 'https://api.uniticexchange.com/api/v1/trade/checkPair', code: `const data = async () => {
const response = await fetch('https://api.uniticexchange.com/api/v1/trade/checkPair',{
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: "POST",
    body: JSON.stringify({pair: "TRX_INR"})
});
const output = await response.json(); //extract JSON from the http response
}`, json: `{
    "status": true,
    "Message": {
        "marketPrice": 8.54,
        "changePercentage": 0,
        "marketStatus": 1,
        "enableBuySell": 1,
        "enableTradeHistory": 1,
        "enableVolumeStatus": 1,
        "lastVolume": 0,
        "decimalValue": 8,
        "amountDecimal": 4,
        "priceDecimal": 2,
        "status": 1,
        "minTrade": 100,
        "price": 9.09,
        "lastPrice": 8.96,
        "usdPrice": 0,
        "change": 0.11012113324656615,
        "changeValue": 0.009921999999999542,
        "volume": 432518.89124212024,
        "volume_fromCur": 47951.09659003551,
        "high": 9.16,
        "low": 8.94,
        "makerFee": 0.4,
        "takerFee": 0.4,
        "makerFeeWithKYC": 0.4,
        "takerFeeWithKYC": 0.4,
        "orderDataMin": 0.01,
        "orderDataMax": 0.03,
        "ohlcUpdated": 0,
        "autoOrderExecute": 1,
        "getOldPrice": 0,
        "autoTradeOrder": 1,
        "exchangeType": "SPOT",
        "_id": "6411b1d923bcfcea568c0870",
        "pair": "TRX_INR",
        "created": "2023-03-15T11:54:01.919Z",
        "__v": 0,
        "fromCurrency": {
            "_id": "64101bc4c406fcdd920476dc",
            "currencyId": "64101bc4c406fcdd920476da",
            "currencyName": "Tron",
            "currencySymbol": "TRX",
            "curnType": "Crypto",
            "USDvalue": 0.103494,
            "image": "https://assets.coingecko.com/coins/images/1094/small/tron-logo.png",
            "decimal": 6,
            "siteDecimal": 4
        },
        "toCurrency": {
            "_id": "640f6cb9a85aacd35e842705",
            "currencyId": "640f6cb9a85aacd35e842703",
            "currencyName": "Indian Rupee",
            "currencySymbol": "INR",
            "curnType": "Fiat",
            "USDvalue": 0.011518083390923752,
            "image": "https://res.cloudinary.com/dkqixjgxa/image/upload/v1678950708/Images/j9vohulgvuh4germpmvn.jpg",
            "decimal": 2,
            "siteDecimal": 2
        },
        "buyOrders": [
            {
                "_id": 8.96,
                "amount": 4617.51,
                "filledAmount": 0,
                "status": 0
            },
            {
              // rest others....  
            }
        ],
        "tradeHistory": [
            {
                "price": 8.96,
                "open": 8.96,
                "high": 8.96,
                "low": 8.96,
                "close": 8.96,
                "volume": 67.05,
                "total": 600.768,
                "type": "sell",
                "chartType": "Wazirx",
                "_id": "656ffe0496ab2aa414b4d3c8",
                "pair": "6411b1d923bcfcea568c0870",
                "pairName": "TRX_INR",
                "time": "2023-12-06T04:52:20.538Z",
                "__v": 0
            },
            {// rest others...}
        ],
        "type": 1
    }
}`
    }, {
        tab: 'Get Markets Data', des: "This endpoint retrieves all market data.", meth: 'Get', url: 'https://api.uniticexchange.com/api/v1/trade/getMarkets', code: `const data = async () => {
const response = await fetch('https://api.uniticexchange.com/api/v1/trade/getMarkets');
const output = await response.json(); //extract JSON from the http response
}`, json: `{
            "status": true,
            "data": [
                {
                    "decimalValue": 8,
                    "minTrade": 100,
                    "price": 8.97,
                    "lastPrice": 8.97,
                    "change": -0.44104616548689274,
                    "volume": 482592.29874008027,
                    "high": 9.16,
                    "low": 8.94,
                    "makerFee": 0.4,
                    "takerFee": 0.4,
                    "makerFeeWithKYC": 0.4,
                    "takerFeeWithKYC": 0.4,
                    "_id": "6411b1d923bcfcea568c0870",
                    "fromCurrency": {
                        "image": "https://assets.coingecko.com/coins/images/1094/small/tron-logo.png",
                        "siteDecimal": 4,
                        "_id": "64101bc4c406fcdd920476dc",
                        "currencySymbol": "TRX",
                        "currencyId": "64101bc4c406fcdd920476da"
                    },
                    "toCurrency": {
                        "image": "https://res.cloudinary.com/dkqixjgxa/image/upload/v1678950708/Images/j9vohulgvuh4germpmvn.jpg",
                        "siteDecimal": 2,
                        "_id": "640f6cb9a85aacd35e842705",
                        "currencySymbol": "INR",
                        "currencyId": "640f6cb9a85aacd35e842703"
                    },
                    "pair": "TRX_INR"
                },
                {// rest others...}
            ]
        }`
    }, {
        tab: 'Get Markets Spot', des: "This endpoint retrieves all spot market.", meth: 'Get', url: 'https://api.uniticexchange.com/api/v1/trade/getMarketsTab?exchangeType=SPOT', code: `const data = async () => {
const response = await fetch('https://api.uniticexchange.com/api/v1/trade/getMarketsTab?exchangeType=SPOT');
const output = await response.json(); //extract JSON from the http response
}`, json: `{
    "status": true,
    "data": [
        {
            "currency": "UTI",
            "pairs": [
                {
                    "decimalValue": 8,
                    "minTrade": 10,
                    "price": 0,
                    "lastPrice": 0,
                    "change": 0,
                    "volume": 0,
                    "high": 0,
                    "low": 0,
                    "makerFee": 0.2,
                    "takerFee": 0.2,
                    "_id": "6411aef623bcfcea568c0629",
                    "fromCurrency": {
                        "image": "https://assets.coingecko.com/coins/images/1094/small/tron-logo.png",
                        "siteDecimal": 4,
                        "_id": "64101bc4c406fcdd920476dc",
                        "currencySymbol": "TRX",
                        "currencyId": "64101bc4c406fcdd920476da"
                    },
                    "toCurrency": {
                        "image": "https://res.cloudinary.com/dkqixjgxa/image/upload/v1678948506/Images/ekv8xysop4wzfmwprdoe.png",
                        "siteDecimal": 5,
                        "_id": "64101d6ac406fcdd920477a2",
                        "currencySymbol": "UTI",
                        "currencyId": "64101d6ac406fcdd920477a0"
                    },
                    "pair": "TRX_UTI"
                },
                {// rest others....  }
        ],
        "fbtPairs": []
    }
}`
    }, {
        tab: 'Get History Chart', des: "This endpoint retrieves chart history data.", meth: 'Get', url: 'https://api.uniticexchange.com/api/v1/trade/historyChart', code: `const data = async () => {
const response = await fetch('https://api.uniticexchange.com/api/v1/trade/historyChart');
const output = await response.json(); //extract JSON from the http response
}`, json: `{
    "status": true,
    "data": []
}` }, {
        tab: 'Get Trade Chart', des: "This endpoint retrieves trade chart data.", meth: 'Get', url: 'https://api.uniticexchange.com/api/v1/trade/tradeChart', code: `const data = async () => {
const response = await fetch('https://api.uniticexchange.com/api/v1/trade/tradeChart');
const output = await response.json(); //extract JSON from the http response
}`, json: `{
    "data": []
}` }, {
        tab: 'Get Market Data', des: "This endpoint retrieves trade market data.", meth: 'Post', url: 'https://api.uniticexchange.com/api/v1/trade/market/data', code: `const data = async () => {
const response = await fetch('https://api.uniticexchange.com/api/v1/trade/market/data',{
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify({pair: "TRX_INR"})
            });
const output = await response.json(); //extract JSON from the http response
}`, json: `{
    "status": true,
    "data": {
        "marketPrice": 9.08
    }
}`
    }, {
        tab: 'Get Market candles', des: "This endpoint retrieves market data with candles.", meth: 'Post', url: 'https://api.uniticexchange.com/api/v1/trade/market_data/candles', code: `const data = async () => {
const response = await fetch('https://api.uniticexchange.com/api/v1/trade/market_data/candles',{
                        headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json'
                        },
                        method: "POST",
                        body: JSON.stringify({
                            pair: "I-TRX_INR", 
                            interval: "1d", 
                            startTime: 1666483200000, 
                            endTime: 1674086400000
                        })
                    });
const output = await response.json(); //extract JSON from the http response
        }`, json: `[
            {
                "time": 1674086400000,
                "close": 5.13,
                "open": 5.11,
                "high": 5.24,
                "low": 4.99,
                "volume": 38355.878978,
                "dateTime": "2023-01-19T00:00:00.000Z"
            },
            {// rest others}
        ]` }, {
        tab: 'Get Crypto Price', des: "This endpoint retrieves a specific crypto price.", meth: 'Post', url: 'https://api.uniticexchange.com/api/v1/buycrypto/buycryptoprice', code: `const data = async () => {
const response = await fetch('https://api.uniticexchange.com/api/v1/buycrypto/buycryptoprice',{
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify({symbol: "tron"})
            });
const output = await response.json(); //extract JSON from the http response
}`, json: `{
    "status": true,
    "message": 8.6
}` }];

    const indexBased = dataTab.map(item => item?.tab.toLowerCase().replace(/\s+/g, '_'));
    // State to keep track of active hash
    const [activeHash, setActiveHash] = useState('');

    // Update activeHash when URL changes
    useEffect(() => {
        const handleHashChange = () => {
            setActiveHash(window.location.hash.substr(1)); // Remove leading #
        };

        window.addEventListener('hashchange', handleHashChange);
        handleHashChange(); // Set active hash on initial load

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);
    useEffect(() => {
        const handleScroll = () => {
            const sectionOffsets = indexBased.map(hash => {
                const section = document.getElementById(hash);
                if (section) {
                    return {
                        hash,
                        offsetTop: section.offsetTop,
                        offsetHeight: section.offsetHeight
                    };
                }
                return null;
            }).filter(Boolean);

            const scrollPosition = window.scrollY;

            for (let i = sectionOffsets.length - 1; i >= 0; i--) {
                const { hash, offsetTop, offsetHeight } = sectionOffsets[i];
                if (scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight) {
                    setActiveHash(hash);
                    window.history.replaceState(null, null, `#${hash}`);
                    break;
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [indexBased]);
    const drawer = (
        <div className='bg-default text-light'>
            <Toolbar>
                {(siteSettings) && (< img src={siteSettings.siteLogo} alt="logo" className='img-fluid' id="navbar-img" style={{ cursor: 'pointer' }} onClick={() => navigate('/')} />)}
            </Toolbar>
            <Divider />
            <List>
                {dataTab.map((text, index) => (
                    <ListItem key={index} disablePadding >
                        <ListItemButton href={`#${indexBased[index]}`} selected={indexBased[index] === activeHash} className={indexBased[index] === activeHash ? 'bg-black-own' : ''}>
                            {/* <ListItemIcon>
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon> */}
                            <ListItemText primary={text?.tab} sx={{
                                '& .MuiTypography-root': {
                                    fontWeight: indexBased[index] === activeHash ? 700 : 400
                                }
                            }} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </div>
    );

    useEffect(() => {
        window.scrollTo({
            top: 100,
            left: 100,
            behavior: "smooth",
        });
    }, [])
    return (
        <section className='bg-default min-vh-100 text-light'>
            <Helmet>
                <link rel="canonical" href="https://www.uniticexchange.com/api-docs" />
            </Helmet>
            <div className='container-fluid'>
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <AppBar
                        position="fixed"
                        sx={{
                            width: { sm: `calc(100% - ${drawerWidth}px)` },
                            ml: { sm: `${drawerWidth}px` },
                            '& .MuiToolbar-root': { backgroundColor: '#282525' },
                        }}
                    >
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 2, display: { sm: 'none' } }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" noWrap component="div">
                                API Documentation
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Box
                        component="nav"
                        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                        aria-label="mailbox folders"
                    >
                        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                        <Drawer
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            sx={{
                                display: { xs: 'block', sm: 'none' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#282525', borderRight: '1px solid white' },
                            }}
                        >
                            {drawer}
                        </Drawer>
                        <Drawer
                            variant="permanent"
                            sx={{
                                display: { xs: 'none', sm: 'block' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#282525', borderRight: '1px solid white' },
                            }}
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Box>
                    <Box
                        component="main"
                        sx={{ flexGrow: 1, p: { sm: 3 }, width: `calc(100% - ${drawerWidth}px)`, fontFamily: "'system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important'" }}
                    >
                        {dataTab.map((data, index) => <GetDoc id={indexBased[index]} data={data} />)}
                    </Box>
                </Box>
            </div>
        </section >
    );
};
export default ApiDocumentation;