import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import StarIcon from '@mui/icons-material/Star';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import socketIOClient from "socket.io-client";

import { getCookie } from '../../core/helper/cookie';
import { makeRequest } from '../../core/services/v1/request';
import Config from '../../core/config/';
import './MarketPage.css';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/system';
import { Button } from 'react-bootstrap';
import Loader from '../../Component/Loader/Loader';
import { useContextData } from '../../core/context';
import LoaderUnitic from '../../Component/Loader/LoaderUnitic';


const StyledTableHeadRow = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontSize: "18px",
    fontWeight: "500",
    fontStyle: "normal",
    padding: "5px",
    paddingBottom: "16px",
    width: "auto !important",
    color: "white",
    textAlign: 'left'
    // '@media (min-width: 300px)': {
    //     // adjust the width at 600px and above
    //     width: '50px !important',
    // },
    // '@media (min-width: 960px)': {
    //     // adjust the width at 960px and above
    //     width: "50px !important",
    // },
    // lineHeight: "27px"

}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "27px",
    padding: "5px",
    width: "auto !important",
    color: "white",
    textAlign: 'left'
    // '@media (min-width: 300px)': {
    //     // adjust the width at 600px and above
    //     width: '50px !important',
    // },
    // '@media (min-width: 960px)': {
    //     // adjust the width at 960px and above
    //     width: "50px !important",
    // },

}));
const StyledTableCellFirst = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "27px",
    padding: "5px",
    color: "white",
    width: "230px !important",
    // '@media (min-width: 300px)': {
    //     // adjust the width at 600px and above
    //     width: '50px !important',
    // },
    // '@media (min-width: 960px)': {
    //     // adjust the width at 960px and above
    //     width: "50px !important",
    // },

}));
const StyledTableHead = styled(TableHead)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontSize: "32px",
    fontWeight: "600",
    padding: "5px",
    fontStyle: "normal",
    lineHeight: "38px",
    width: "auto !important",
    color: "white",
    textAlign: 'left'

    // '@media (min-width: 300px)': {
    //     // adjust the width at 600px and above
    //     width: '50px !important',
    // },
    // '@media (min-width: 960px)': {
    //     // adjust the width at 960px and above
    //     width: "50px !important",
    // },

}));

const UTI = ({ marketCurrency }) => {
    const navigate = useNavigate();
    const { coindcxDecimal } = useContextData();
    const [markets, setMarkets] = useState({});
    const [topMarkets, settopMarkets] = useState({});
    const [socketConnection, setSocketConnection] = useState(null);

    useEffect(() => {
        let socket = socketIOClient(Config.SOCKET_URL, {
            // transports: ["polling"],
            transports: ['websocket'],
            // rejectUnauthorized: false
        });
        let socketUnsubscribe;
        getMarkets();
        getTopMarkets();
        if (socket) {
            socket.on('connect', function () {
                setSocketConnection(socket);
                socketUnsubscribe = socket;
            });
            socket.on('connect_error', (err) => {
                console.log('socket connect_error', err)
            })
            socket.on('disconnect', function () {
                // console.log('socket disconnected')
            });
        }
        return () => {
            if (socketUnsubscribe) {
                socketUnsubscribe.disconnect();
            }
        };
    }, []);

    useEffect(() => {
        if (socketConnection != null) {
            socketConnection.on("pairResponse", data => {
                // getMarkets();
                markets.length > 0 && markets.map((market) => {
                    if (data.pair == market.pair) {
                        let newPrice = data.price.toFixed(data.decimalValue);
                        let oldData = markets.filter((market) => market.pair == data.pair)[0];
                        let oldIndex = markets.findIndex((market) => market.pair == data.pair);
                        let oldPrice = oldData.price.toFixed(oldData.decimalValue);
                        if (newPrice != oldPrice) {
                            markets[oldIndex].price = data.price;
                            markets[oldIndex].lastPrice = data.lastPrice;
                            markets[oldIndex].change = data.change;
                            markets[oldIndex].oldPrice = oldData.price;
                        }
                    }
                })
            });
            setMarkets(markets);
        }
    }, [socketConnection]);

    useEffect(() => {
        if (socketConnection != null) {
            getTopMarkets();
            socketConnection.on("pairResponse", data => {
                topMarkets.length > 0 && topMarkets.map((topmarket) => {
                    if (data.pair == topmarket.pair) {
                        let newPrice = data.price.toFixed(data.decimalValue);
                        let oldData = topMarkets.filter((topmarket) => topmarket.pair == data.pair)[0];
                        let oldIndex = topMarkets.findIndex((topmarket) => topmarket.pair == data.pair);
                        let oldPrice = oldData.price.toFixed(oldData.decimalValue);
                        if (newPrice != oldPrice) {
                            topMarkets[oldIndex].price = data.price;
                            topMarkets[oldIndex].lastPrice = data.lastPrice;
                            topMarkets[oldIndex].change = data.change;
                            topMarkets[oldIndex].oldPrice = oldData.price;
                        }
                    }
                })
                settopMarkets(topMarkets);
            })
        }
    }, [socketConnection])

    async function getMarkets() {
        try {
            const params = {
                url: `${Config.V1_API_URL}trade/getMarkets`,
                method: 'GET',
            }
            const response = (await makeRequest(params));
            if (response.status) {
                // console.log('markets response : ', response?.data);
                const filterData = (response?.data).filter(data => (data?.pair).includes(marketCurrency));
                setMarkets(() => filterData);
                // markets = response.data;;
                // if(socketConnection != null) {
                //   socketConnection && socketConnection.on("pairResponse", data => {
                //     markets.map((market)=>{
                //       if (data.pair == market.pair){
                //         let newPrice = data.price.toFixed(data.decimalValue);
                //         let oldData = markets.filter((market) => market.pair == data.pair)[0];
                //         let oldIndex = markets.findIndex((market) => market.pair == data.pair);
                //         let oldPrice = oldData.price.toFixed(oldData.decimalValue);
                //         if(newPrice != oldPrice) {
                //           markets[oldIndex].price = data.price;
                //           markets[oldIndex].lastPrice = data.lastPrice;
                //           markets[oldIndex].change = data.change;
                //           markets[oldIndex].oldPrice = oldData.price;
                //         }
                //       }
                //     })
                //     setMarkets(markets);
                //   });
                // }
            }
        } catch (err) { }
    }

    async function getTopMarkets() {
        try {
            const params = {
                url: `${Config.V1_API_URL}trade/getHomeMarkets`,
                method: 'GET'
            }
            const response = (await makeRequest(params));
            if (response.status) {
                const topGainers = response.data.topGainers;
                topMarkets = topGainers;
                socketConnection.on("pairResponse", data => {
                    topGainers.map((market) => {
                        if (data.pair == market.pair) {
                            let newPrice = data.price.toFixed(data.decimalValue);
                            let oldData = topMarkets.filter((market) => market.pair == data.pair)[0];
                            let oldIndex = topMarkets.findIndex((market) => market.pair == data.pair);
                            let oldPrice = oldData.price.toFixed(oldData.decimalValue);
                            if (newPrice != oldPrice) {
                                topMarkets[oldIndex].price = data.price;
                                topMarkets[oldIndex].lastPrice = data.lastPrice;
                                topMarkets[oldIndex].change = data.change;
                                topMarkets[oldIndex].oldPrice = oldData.price;
                            }
                        }
                    })
                    settopMarkets(topMarkets);
                })
            }
        } catch (err) { }
    }
    return (
        <div className='my-2'>
            {markets.length > 0 ?
                <TableContainer>
                    <Table sx={{ minWidth: window.innerWidth < 700 ? 1000 : 100, justifyContent: 'center' }} aria-label="simple table">
                        <StyledTableHead>
                            <TableRow>
                                <StyledTableHeadRow><h2 style={{ fontSize: '18px' }}>Pair/Token</h2></StyledTableHeadRow>
                                <StyledTableHeadRow>Last Price</StyledTableHeadRow>
                                <StyledTableHeadRow>24 Change</StyledTableHeadRow>
                                <StyledTableHeadRow>24 High</StyledTableHeadRow>
                                <StyledTableHeadRow>24 Low</StyledTableHeadRow>
                                <StyledTableHeadRow>24 Volume</StyledTableHeadRow>
                                <StyledTableHeadRow><h3 style={{ fontSize: '18px' }} onClick={() => window.open('https://utistaking.com/', '_blank', 'noopener,noreferrer')}>Trade</h3></StyledTableHeadRow>
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            {markets.map((data, i) => {
                                const coindcxDecimalSingle = coindcxDecimal[data?.pair.split('_').join('')]
                                return <TableRow key={i}>
                                    <StyledTableCellFirst component="th" scope="row" onClick={() => { navigate('/spot/' + data?.pair); }} style={{ cursor: "pointer" }}>
                                        {data.fromCurrency && data.fromCurrency.image && <img src={data.fromCurrency.image} alt="logo" className="market-table-data-logo me-2" />} {data.pair && data.pair.split('_').join('/')}
                                    </StyledTableCellFirst>
                                    <StyledTableCell className={data.lastPrice > 0 ? ((data.lastPrice < data.price) ? 'blinkGreenColor' : 'blinkRedColor') : ''}>
                                        {(data.price).toFixed(coindcxDecimalSingle?.base_currency_precision || 5)}
                                        {/* {(data.price).toFixed(data.decimalValue)} */}
                                    </StyledTableCell>
                                    <StyledTableCell className={data.change >= 0 ? 'color-green' : 'color-red'}>{data.change >= 0 ? "+" + (data.change).toFixed(2) : (data.change).toFixed(2)} %</StyledTableCell>
                                    <StyledTableCell>
                                        {data.high.toFixed(coindcxDecimalSingle?.base_currency_precision || 5)}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {data.low.toFixed(coindcxDecimalSingle?.base_currency_precision || 5)}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {data.volume.toFixed(2)}
                                        {/* {data.volume.toFixed(data.decimalValue)} */}
                                    </StyledTableCell>
                                    <StyledTableCell><Button variant="light" className='border text-primary' onClick={() => navigate("/spot/" + data.pair)}>Trade</Button></StyledTableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <div style={{height:'55vh'}}>
                    <LoaderUnitic width={60}/>
                </div>
                
            }
        </div>
    );
};

export default UTI;