import React, { useState, useEffect } from "react";
import {
    useNavigate
} from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import {
    alpha, Box, TableCell, TableHead,
    TableRow, TableSortLabel, Toolbar, Typography, IconButton, Tooltip,
    Grid,
    Button
} from "@mui/material";
import { Delete, FilterList } from "@mui/icons-material";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

import { makeRequest } from "../../core/services/v1/request";
import { toast } from "../../core/lib/toastAlert";
import Config from "../../core/config/";
import * as yup from 'yup';
import { useFormik} from 'formik';
import './Portfolio.css';
import { useContextData } from '../../core/context/index';

const validationSchema = yup.object({
    amount: yup
        .number('Enter your Amount')
        .required('Amount is required'),
});

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function createData(name, balance, fat, carbs, protein) {
    return {
        name,
        balance,
        fat,
        carbs,
        protein,
    };
}

const headCells = [
    {
        id: "Name",
        numeric: false,
        disablePadding: true,
        label: "Name",
    },
    {
        id: "MainWalletBalance",
        numeric: true,
        disablePadding: false,
        label: "Main Wallet Balance",
    },
    {
        id: "P2PBalance",
        numeric: true,
        disablePadding: false,
        label: "P2P Balance",
    },
    {
        id: "Price",
        numeric: true,
        disablePadding: false,
        label: "Price",
    },
    // {
    //   id: "24H Change",
    //   numeric: true,
    //   disablePadding: false,
    //   label: "24hchange",
    // },
    {
        id: "Action",
        numeric: true,
        disablePadding: false,
        label: "Action",
    },
];

function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    // console.log('props------', props)
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
              
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {" "} &nbsp;{headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(
                            theme.palette.primary.main,
                            theme.palette.action.activatedOpacity
                        ),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: "1 1 100%" }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: "1 1 100%" }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Wallet
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <Delete />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterList />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const data = {
    labels: [1, 2, 3, 4, 5, 6],
    datasets: [
        {
            label: "First dataset",
            data: [33, 53, 85, 41, 44, 65],
            fill: true,
            backgroundColor: "rgba(75,192,192,0.2)",
            borderColor: "rgba(75,192,192,1)",
        },
        {
            label: "Second dataset",
            data: [33, 25, 35, 51, 54, 76],
            fill: false,
            borderColor: "#742774",
        },
    ],
};


const WalletListV2 = (props) => {
    const {
        walletData: propswalletData = [],
        balShow = false,
        tabName = "wallet",
        coindcxDecimal = {},
        setEstimatedValue, estimatedValue = 0, balShowHideCall, loading, pendingOrderAmount

    } = props;
    const { myProfile} = useContextData();


    const navigate = useNavigate();
    // var userId = myProfile && myProfile?.length && myProfile._id;
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("balance");
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [open, setOpen] = useState(false);
    const [currency, setCurrency] = useState({});
    const [fromAccount, setFromAccount] = useState('Main Wallet');
    const [toAccount, setToAccount] = useState('P2P Wallet');
    const [isLoading, setisLoading] = useState(false);
    const [walletData, setWalletData] = useState([]);
    const handleClose = () => {
        setOpen(false);
        formik.values.amount = "";
        formik.values.toWallet = "";
    };

    useEffect(() => {
        if (props && props.walletData) {
            setWalletData(props.walletData);
            // setEstimatedValue(props.estimatedValue)

        }
    }, [props]);

    useEffect(() => {
        getWalletCurrency();
    }, []);

    async function getWalletCurrency() {
        if (tabName == "wallet") {
            const params = {
                url: `${Config.V2_API_URL}wallet/getWalletCurrency`,
                method: 'GET'
            }
            const response = (await makeRequest(params));
            if (response.status) {
                // const { estimateINR = 0,
                //     estimateUSD = 0}=response
                const crypto = response.data.filter(coin => coin.curnType === 'Crypto');
                const fiat = response.data.filter(fiat => fiat.curnType === 'Fiat');
                setWalletData(crypto);
                // setEstimatedValue({
                //     estimateINR,
                //     estimateUSD
                // });
                // setCryptoWallet(crypto);
                // setCryptoWalletClone(crypto);
                // setFiatWallet(fiat);
                // setestimateINR(response.estimateINR)
            }
        }
        else {
            const params = {
                url: `${Config.V2_API_URL}wallet/getSpotHoldings`,
                method: 'GET'
            };
            const response = await makeRequest(params);
            if (response) {
                const {
                    status = false,
                    data = [],
                    estimateINR = 0,
                    estimateUSD = 0,
                } = response;
                setWalletData(data);
                setEstimatedValue({
                    estimateINR,
                    estimateUSD
                });

            }
        }
    }

    const transferFund = (data) => {
        setOpen(true);
        setCurrency(data)
    };

    const fromWallet = (e) => {
        setFromAccount(e.target.value);
        setToAccount(e.target.value == 'Main Wallet' ? 'P2P Wallet' : 'Main Wallet');
    }
    const toWallet = (e) => {
        setToAccount(e.target.value);
        // console.log(e.target.value);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = walletData.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const formik = useFormik({
        initialValues: {
            amount: '',
            toWallet: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setisLoading(true);
            let balance = fromAccount == 'Main Wallet' ? currency.balance : currency.p2pAmount;
            if (balance >= values.amount) {
                const body = {
                    amount: values.amount,
                    currencyId: currency.currencyId,
                    fromAccount: fromAccount,
                    toAccount: toAccount
                }
                const params = {
                    url: `${Config.V1_API_URL}wallet/submitTransfer`,
                    method: 'POST',
                    body
                }
                const response = (await makeRequest(params));
                let type = 'error';
                setisLoading(false);
                if (response.status) {
                    type = 'success';
                    handleClose();
                    getWalletCurrency();
                }
                toast({ type, message: response.message });
            } else {
                toast({ type: 'error', message: 'Insufficient Balance On ' + fromAccount });
            }
        },
    });
    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - walletData.length) : 0;

    // console.log("props.pendingOrderAmount==============", propswalletData);
    return (
        <div className='mb-5'>

            <Box
                sx={
                    {
                        // bgcolor: '#282525',
                    }
                }
            >
                <Box
                    sx={{
                        bgcolor: '#3d3d3d',
                        display: { md: 'flex' },
                        p: 3,
                        borderRadius: 5,
                        mt: 1,
                        justifyContent: 'space-between',
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                textAlign: { xs: 'center', md: 'left' },
                            }}
                        >
                            <span onClick={() => window.open('https://utistaking.com/', '_blank', 'noopener,noreferrer')}>Estimated</span> Balance {balShow ? <AiFillEye
                                onClick={() => balShowHideCall()}
                                sx={{ color: "white", marginTop: "-5px", marginLeft: "5px", cursor: "pointer" }} /> : <AiFillEyeInvisible
                                onClick={() => balShowHideCall()}
                                sx={{ color: "white", marginTop: "-5px", marginLeft: "5px", cursor: "pointer" }} />}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 20,
                                textAlign: { xs: 'center', md: 'left' },
                                fontWeight: 'bold',
                                mt: 1.5,
                            }}
                        >
                            {balShow ? <>&#8377; {loading ? 'Loading...' : ((estimatedValue.estimateINR) - pendingOrderAmount)?.toFixed(2)}</> : <>***Balance hidden***</>}
                        </Typography>
                    </Box>
                    {myProfile &&
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 4,
                                alignItems: 'center',
                                justifyContent: { xs: 'space-between', md: '' },
                                mt: { xs: 2, md: 0 },
                            }}
                        >
                            <Box
                                sx={{
                                    border: '1px solid #fff',
                                    borderRadius: 2,
                                    padding: { xs: '6px 12px', sm: '10px 50px' },
                                    cursor: 'pointer',
                                    // display: propswalletData[0]?.withdrawEnable ==0 ? 'none' : ''
                                }}
                                onClick={() => props.toNavigateFirstCurrency('withdraw')}
                            >
                                Withdraw
                            </Box>
                            <Box
                                sx={{
                                    border: '1px solid #fff',
                                    borderRadius: 2,
                                    padding: { xs: '6px 12px', sm: '10px 50px' },
                                    cursor: 'pointer',
                                    // display: propswalletData[0]?.depositEnable ==0 ? 'none' : ''
                                }}
                                onClick={() => props.toNavigateFirstCurrency("deposit")}
                            >
                                Deposit
                            </Box>
                        </Box>}
                </Box>

                <Box
                    sx={{
                        mt: 8,
                    }}
                >
                    <Grid container spacing={3}>
                        {stableSort(
                            walletData,
                            getComparator(order, orderBy)
                        )
                            .map((row, index) => {
                                const { _id, BTCvalue = 0, ETHvalue = 0, INRvalue = 0, USDvalue = 0, apiid = "", autoWithdraw = 0, balance = 0, balanceV2 = 0, basecoin = "", cointype = 0, contractAddress = "", count = 0, curnType = "", currencyId = "", currencyName = "", currencySymbol = "", currencySymbolCode = "", depositEnable = 0, depositNotes = "", fees = 0, feetype = 0, hold = 0, image = "", inrValue = 0, lastPurchasedPrice = 0, mindepamt = 0, minwithamt = 0, p2pAmount = 0, p2pHold = 0, price = 0, siteDecimal = 2, stakingAmount = 0, stakingHold = 0, stakingTransferEnable = 0, status = 1, tagContent = "", totalTradePrice = 0, tradeEnable = 0, transferEnable = 0, withdrawEnable = 0, withdrawNotes = "", toCurrency = ""
                                } = row;
                                const isItemSelected = isSelected(
                                    currencyName
                                );
                                const labelId = `enhanced-table-checkbox-${index}`;
                                const firstCurrecny = currencySymbol;
                                const secondCurrecny = toCurrency;
                                const tradePairName = firstCurrecny + "_" + secondCurrecny;
                                const decimal = coindcxDecimal[firstCurrecny + secondCurrecny]
                                const baseDecimal = decimal?.base_currency_precision ? decimal?.base_currency_precision : 2
                                const targetDecimal = decimal?.target_currency_precision ? decimal?.target_currency_precision : 3
                                const avl = ((currencyName == 'Indian Rupee' && props.pendingOrderAmount) ? (
                                    <>

                                        {balShow ? ((Number(balanceV2) - (Number(props.pendingOrderAmount))).toFixed(targetDecimal)) : "******"}
                                    </>
                                ) : (
                                    <>
                                        {balShow ? (row?.balanceV2).toFixed(targetDecimal) : "******"}
                                    </>
                                ))
                                // console.log(currencyName,decimal)
                                const avlForAsset = (currencyName == 'Indian Rupee' && props.pendingOrderAmount) ? (Number(balanceV2) - Number(props.pendingOrderAmount)).toFixed(targetDecimal) : row?.balanceV2.toFixed(targetDecimal);
                                let one = 1;
                                const ltp = currencyName == 'Indian Rupee' ? one.toFixed(baseDecimal) : (price).toFixed(baseDecimal)

                                const totalAsset = (+avlForAsset * (+ltp)) ? (+avlForAsset * (+ltp)).toFixed(2) : 0;

                                const entryPrice = currencyName == 'Indian Rupee' ? 1.00 : (lastPurchasedPrice).toFixed(baseDecimal)
                                const profitNum = (avlForAsset === '0' || !avlForAsset || avlForAsset === 0) ? 0 : (((+ltp) - entryPrice) * (+avlForAsset)).toFixed(2);
                                const profitPercentage = (avlForAsset === '0' || !avlForAsset || avlForAsset === 0) ? 0 : ((ltp - entryPrice) / entryPrice * 100).toFixed(2)
                                // console.log(+avlForAsset)
                                return (
                                    <Grid
                                        sx={
                                            {
                                                //   border: '1px solid #fff',
                                            }
                                        }
                                        item
                                        lg={4}
                                        md={6}
                                        xs={12}
                                    >
                                        <Box
                                            sx={{
                                                bgcolor: '#3D3D3D',
                                                padding: 3,
                                                borderRadius: 3,
                                            }}
                                            hover
                                            onClick={(event) =>
                                                handleClick(event, currencyName)
                                            }
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={index}
                                            selected={isItemSelected}
                                        >
                                            {/* Line 1 */}
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        gap: 1,
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <img
                                                        height={40}
                                                        width={40}
                                                        className='rounded-pill'
                                                        src={image}
                                                        alt=''
                                                    />
                                                    <Box>
                                                        <Typography>{currencyName}</Typography>
                                                        <Typography
                                                            sx={{
                                                                fontSize: '12px',
                                                            }}
                                                        >
                                                            AVL: {props?.loading ? "Loading..." : avl}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ textAlign: 'end' }}>
                                                    <Typography>&#8377; {totalAsset}</Typography>
                                                    <Typography
                                                        sx={{
                                                            color: profitPercentage > 0 ? '#21CA50' : '#ff4b5e',
                                                        }}
                                                    >
                                                        {(profitPercentage != 'NaN' || !profitPercentage || profitPercentage == null || profitPercentage === Infinity) ? profitPercentage > 0 ? `+${profitPercentage}` : profitPercentage : 0}%
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            {/* Line 2 */}
                                            <Box
                                                sx={{
                                                    // display: 'flex',
                                                    // justifyContent: 'space-between',
                                                    // textAlign: 'center',
                                                    pt: 2,
                                                }}
                                                className='row align-items-center'
                                            >
                                                <Box
                                                    sx={{
                                                        paddingLeft: '10px',
                                                    }}
                                                    className='col-4 mx-auto text-center'
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: '17px',
                                                        }}
                                                    >
                                                        &#8377; {entryPrice}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        AVG Price
                                                    </Typography>
                                                </Box>
                                                <Box className='col-4 mx-auto text-center'>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '17px',
                                                        }}
                                                    >
                                                        &#8377; {ltp}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        LTP{' '}
                                                    </Typography>
                                                </Box>{' '}
                                                <Box className='col-4 mx-auto text-center'>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '17px',
                                                            color: profitNum > 0 ? ' #21CA50' : '#ff4b5e',
                                                        }}
                                                    >
                                                        {profitNum > 0 ? `+${profitNum}` : profitNum}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        PnL(₹)
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            {/* Line 3 */}
                                            <Box
                                                sx={{
                                                    // display: 'flex',
                                                    // alignItems: 'center',
                                                    // gap: 2,
                                                }}
                                                className='row align-items-center'
                                            >
                                                <Box className='col-4 mx-auto text-center'>
                                                    <Button
                                                        sx={{
                                                            bgcolor: '#C8C8C8',
                                                            padding: '2px 10px',
                                                            borderRadius: '20px',
                                                            color: '#000',

                                                            textAlign: 'center',
                                                            mt: 3,
                                                            cursor: 'pointer',
                                                            lineHeight: 1.8,
                                                            '&:disabled': {
                                                                cursor: 'not-allowed',
                                                                bgcolor: '#c8c8c8aa',
                                                                color: '#000'
                                                            },
                                                            '&:hover': {
                                                                color: 'white'
                                                            },
                                                            width: '100%'
                                                        }}
                                                        disabled={withdrawEnable === 1 ? false : true}
                                                        onClick={() => navigate("/withdraw/" + curnType.toLowerCase() + "/" + _id)}
                                                    >
                                                        Withdraw
                                                    </Button>
                                                </Box>
                                                <Box className='col-4 mx-auto text-center'>
                                                    <Button
                                                        sx={{
                                                            bgcolor: '#C8C8C8',
                                                            padding: '2px 10px',
                                                            borderRadius: '20px',
                                                            color: '#000',

                                                            textAlign: 'center',
                                                            mt: 3,
                                                            cursor: 'pointer',
                                                            lineHeight: 1.8,
                                                            '&:disabled': {
                                                                cursor: 'not-allowed',
                                                                bgcolor: '#c8c8c8aa',
                                                                color: '#000',
                                                            },
                                                            '&:hover': {
                                                                color: 'white'
                                                            },
                                                            width: '100%'
                                                        }}
                                                        disabled={depositEnable === 1 ? false : true} onClick={() => navigate("/deposit/" + curnType.toLowerCase() + "/" + _id)}
                                                    >
                                                        Deposit
                                                    </Button>
                                                </Box>
                                                <Box className='col-4 mx-auto text-center'>
                                                    <Button
                                                        sx={{
                                                            bgcolor: '#C8C8C8',
                                                            padding: '2px 10px',
                                                            borderRadius: '20px',
                                                            color: '#000',

                                                            textAlign: 'center',
                                                            mt: 3,
                                                            cursor: 'pointer',
                                                            lineHeight: 1.8,
                                                            '&:disabled': {
                                                                cursor: 'not-allowed',
                                                                bgcolor: '#c8c8c8aa',
                                                                color: '#000'
                                                            },
                                                            '&:hover': {
                                                                color: 'white'
                                                            },
                                                            width: '100%'
                                                        }}
                                                        onClick={() => navigate("/spot/" + tradePairName)}
                                                    >
                                                        Trade
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                )
                            })}
                    </Grid>
                </Box>
            </Box>
            {walletData && walletData.length == 0 && (
                <div className="text-center mx-auto mt-5">
                    <img
                        className="no-record-image"
                        src="/assets/no-re.png"
                        alt="no-record"
                    />
                    <br />
                    <span className="text-center">No Records Found</span>
                </div>
            )}
            <Modal show={open} onHide={() => setOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Transfer {currency.currencySymbol}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row justify-content-center align-items-center ">
                            <div className="row mt-4">
                                <span className="phonenumber-change-text-2">
                                    Internal Transfer are free on Unitic
                                </span>
                                <select class="f-control f-dropdown" placeholder="Select" value={fromAccount} onChange={fromWallet}>
                                    <option value="Main Wallet">Main Wallet</option>
                                    <option value="P2P Wallet">P2P Wallet</option>
                                </select>
                            </div>
                            <div className="row mt-4">
                                <select class="f-control f-dropdown" placeholder="Select" value={toAccount} onChange={toWallet}>
                                    <option value={fromAccount == 'Main Wallet' ? 'P2P Wallet' : 'Main Wallet'}>{fromAccount == 'Main Wallet' ? 'P2P Wallet' : 'Main Wallet'}</option>
                                    {/* {(fromAccount== 'Main Wallet') &&<option value={fromAccount == 'Main Wallet' ? 'P2P Wallet' : 'Main Wallet'}>{fromAccount == 'Main Wallet' ? 'P2P Wallet' : 'Main Wallet'}</option>} */}
                                </select>
                            </div>
                            <div className="row mt-4">
                                <span className="phonenumber-change-text-2">
                                    Amount
                                </span>
                                <div class="input-group">
                                    <input
                                        type="text"
                                        class="form-control"
                                        autoComplete='off'
                                        label="Amount"
                                        id="amount"
                                        name="amount"
                                        value={formik.values.amount}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.amount && Boolean(formik.errors.amount)}
                                        helperText={formik.touched.amount && formik.errors.amount}
                                    />
                                </div>
                                {formik.errors.amount ? <small className="invalid-amount error">{formik.errors.amount}</small> : null}
                            </div>
                        </div>
                        <div className='row mt-5'>
                            <span className="phonenumber-change-text-2">
                                Balance: {fromAccount == 'Main Wallet' ? currency.balanceV2 : currency.p2pAmount} {currency.currencySymbol}
                            </span>
                            <div className='col'>
                                <div class="d-grid">
                                    <button class="add-payment-method-confirm-button" type="submit" disabled={isLoading}>Transfer Amount</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default WalletListV2;