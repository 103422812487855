import React from 'react';
import FooterChild from './FooterChild';
import FooterHome from './FooterHome';

function Footer() {
    const currentUrl = window.location.href;
    const dynamicClass = (currentUrl === "http://localhost:3000/" || currentUrl === "https://uniticexchange.com/") ? true : false
    return (
        <>
            {dynamicClass ? <FooterHome /> : <FooterChild />}
        </>

    );
}

export default Footer;